import React from 'react';
import {ErrorMessage, FieldArray} from "formik";
import Field from "../form/field";
import {validateProfile} from "../profile/profileForm";
import ClientPerson from "./clientPerson";
import {isEmptyObject} from "../utils";
import SelectProvince from "../form/selectProvince";
import SelectTags from "../form/selectTag";
import ColorPicker from "../component/colorPicker";
import SelectClientStatus from '../form/selectClientStatus';
import SelectProjectType from '../form/selectProjectType';
import ClientAgreementForm from "./clientAgreementForm";

import Dump from "../component/dump";

export default function ClientForm(props) {

    const {
        values,
        touched,
        errors,
        dirty,
        isSubmitting,
        handleChange,
        handleBlur,
        handleSubmit,
        handleReset,
        setFieldValue,
        setFieldTouched
    } = props;

    const handleCopyAddress = (e) => {
        setFieldValue('fvName', values.name);
        setFieldValue('fvPhone', values.phone);
        setFieldValue('fvEmail', values.email);
        setFieldValue('fvCity', values.city);
        setFieldValue('fvStreet', values.street);
        setFieldValue('fvPostalCode', values.postalCode);
        setFieldValue('fvProvince', values.province);
    };

    return (
        <>
            <h3 className="top0 bottom32">Adres świadczenia usług</h3>
            <div className="row with four columns">
                <div className="formItem column">
                    <label htmlFor="name">Nazwa firmy</label>
                    <Field className="fullWidth" type="text" name="name"/>
                    <ErrorMessage component="div" className="errorMessage" name="name"/>
                </div>
                <div className="formItem column">
                    <label htmlFor="phone">Główny numer telefonu</label>
                    <Field className="fullWidth" type="tel" name="phone"/>
                    <ErrorMessage component="div" className="errorMessage" name="phone"/>
                </div>
                <div className="formItem column">
                    <label htmlFor="email">Główny adres e-mail</label>
                    <Field className="fullWidth" type="email" name="email"/>
                    <ErrorMessage component="div" className="errorMessage" name="email"/>
                </div>
            </div>

            <div className="row with four columns">
                <div className="formItem column">
                    <label htmlFor="city">Miasto</label>
                    <Field className="fullWidth" type="text" name="city"/>
                    <ErrorMessage component="div" className="errorMessage" name="city"/>
                </div>
                <div className="formItem column">
                    <label htmlFor="postalCode">Kod pocztowy</label>
                    <Field className="fullWidth" type="text" name="postalCode"/>
                    <ErrorMessage component="div" className="errorMessage" name="postalCode"/>
                </div>
                <div className="formItem column">
                    <label htmlFor="street">Ulica</label>
                    <Field className="fullWidth" type="text" name="street"/>
                    <ErrorMessage component="div" className="errorMessage" name="street"/>
                </div>
                <div className="formItem column">
                    <label htmlFor="province">Województwo</label>
                    <SelectProvince
                        className="fullWidth"
                        name="province"
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        value={values.province}
                    />
                    <ErrorMessage component="div" className="errorMessage" name="province"/>
                </div>
            </div>

            <div className="row with two columns top40">
                <div className="column">
                    <h3 className="top0 bottom32">Dane do faktury</h3>
                </div>
                <div className="column textRight font11">
                    <button className='button' type="button" onClick={handleCopyAddress}>Kopiuj adres świadczenia usług</button>
                </div>
            </div>
            <div className="row with four columns">
                <div className="formItem column">
                    <label htmlFor="fvName">Nazwa firmy</label>
                    <Field className="fullWidth" type="text" name="fvName"/>
                    <ErrorMessage component="div" className="errorMessage" name="fvName"/>
                </div>
                <div className="formItem column">
                    <label htmlFor="nip">NIP</label>
                    <Field className="fullWidth" type="tel" name="nip"/>
                    <ErrorMessage component="div" className="errorMessage" name="nip"/>
                </div>
                <div className="formItem column">
                    <label htmlFor="fvPhone">Numer telefonu</label>
                    <Field className="fullWidth" type="tel" name="fvPhone"/>
                    <ErrorMessage component="div" className="errorMessage" name="fvPhone"/>
                </div>
                <div className="formItem column">
                    <label htmlFor="fvEmail">Adres e-mail</label>
                    <Field className="fullWidth" type="email" name="fvEmail"/>
                    <ErrorMessage component="div" className="errorMessage" name="fvEmail"/>
                </div>
            </div>

            <div className="row with four columns">
                <div className="formItem column">
                    <label htmlFor="fvCity">Miasto</label>
                    <Field className="fullWidth" type="text" name="fvCity"/>
                    <ErrorMessage component="div" className="errorMessage" name="fvCity"/>
                </div>
                <div className="formItem column">
                    <label htmlFor="fvPostalCode">Kod pocztowy</label>
                    <Field className="fullWidth" type="text" name="fvPostalCode"/>
                    <ErrorMessage component="div" className="errorMessage" name="fvPostalCode"/>
                </div>
                <div className="formItem column">
                    <label htmlFor="fvStreet">Ulica</label>
                    <Field className="fullWidth" type="text" name="fvStreet"/>
                    <ErrorMessage component="div" className="errorMessage" name="fvStreet"/>
                </div>
                <div className="formItem column">
                    <label htmlFor="fvProvince">Województwo</label>
                    <SelectProvince
                        className="fullWidth"
                        name="fvProvince"
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        value={values.fvProvince}
                    />
                    <ErrorMessage component="div" className="errorMessage" name="fvProvince"/>
                </div>
            </div>

            <div className="row with two columns">
                <div className="formItem column">
                    <label htmlFor="tags">Tagi</label>
                    <SelectTags
                        name="tags"
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        value={values.tags}
                        className="fullWidth"
                    />
                    <ErrorMessage component="div" className="errorMessage" name="typeDisabled"/>
                </div>
            </div>

            <h3 className="top0 bottom32">Dodatkowe informacje</h3>
			<div className="row with two columns">
                <div className="formItem column">
                    <label htmlFor="status">Status</label>
                    <SelectClientStatus
                        name="status"
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        value={values.status}
                        className="fullWidth"
                    />
                    <ErrorMessage component="div" className="errorMessage" name="status"/>
                </div>
                <div className="formItem column">
                    <label htmlFor="projectTypes">Usługi</label>
                    <SelectProjectType
                        name="projectTypes"
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        value={values.projectTypes}
                        className="fullWidth"
                        mode="multiple"
                        showArrow={true}
                    />
                    <ErrorMessage component="div" className="errorMessage" name="services"/>
                </div>
			</div>
            <div className="row with two columns">
                <div className="formItem column">
                    <label htmlFor="color">Kolor</label>
                    <ColorPicker
                        width={380}
                        color={values.color}
                        onChange={(color) => setFieldValue('color', color)}
                    />
                    <ErrorMessage component="div" className="errorMessage" name="color"/>
                </div>
            </div>
            {/* <Dump value={props}/> */}
        </>
    )
}

export function ClientFormNewContact(props) {

    const {
        values,
        touched,
        errors,
        dirty,
        isSubmitting,
        handleChange,
        handleBlur,
        handleSubmit,
        handleReset,
        setFieldValue,
        setFieldTouched
    } = props;
    return (
        <FieldArray
            name="newPeople"
            render={(arrayHelpers) => {
                function add(event, arrayHelpers) {
                    event.preventDefault();
                    arrayHelpers.push({
                        firstName: "",
                        lastName: "",
                        email: "",
                        phone: "",
                        position: "",
                    });
                }

                return (
                    <span>
						{values.newPeople.map((data, i) => (
                            <ClientPerson
                                setFieldValue={setFieldValue}
                                arrayHelpers={arrayHelpers}
                                values={values}
                                data={data}
                                key={i}
                                index={i}
                                name="newPeople"
                            />
                        ))}
                        <div className="textCenter bottom32">
							<button className="plus" onClick={(event) => {
                                add(event, arrayHelpers);
                            }}><i className="fas fa-plus"/></button>
							<br/>
							<a href="#" onClick={(event) => {
                                add(event, arrayHelpers);
                            }}>dodaj kolejną osobę kontaktową</a>
							<ErrorMessage component="div" className="errorMessage" name="newPeopleInfo"/>
						</div>
					</span>
                );
            }}
        />
    )
}

export function ClientFormNewAgreement(props) {

    const {
        values,
        touched,
        errors,
        dirty,
        isSubmitting,
        handleChange,
        handleBlur,
        handleSubmit,
        handleReset,
        setFieldValue,
        setFieldTouched
    } = props;
    return (
        <FieldArray
            name="newAgreements"
            render={(arrayHelpers) => {
                function add(event, arrayHelpers) {
                    event.preventDefault();
                    arrayHelpers.push({
                        type: null,
                        file: null
                    });
                }

                return (
                    <span>
						{values.newAgreements.map((data, i) => (
                            <ClientAgreementForm
                                setFieldValue={setFieldValue}
                                arrayHelpers={arrayHelpers}
                                values={values}
                                data={data}
                                key={i}
                                index={i}
                                name="newAgreements"
                            />
                        ))}
                        <div className="textCenter bottom32">
							<button className="plus" onClick={(event) => {
                                add(event, arrayHelpers);
                            }}><i className="fas fa-plus"/></button>
							<br/>
							<a href="#" onClick={(event) => {
                                add(event, arrayHelpers);
                            }}>Dodaj zgodę klienta</a>
							{/* <ErrorMessage component="div" className="errorMessage" name="newAgreements"/> */}
						</div>
					</span>
                );
            }}
        />
    )
}

function validateAgreements(values) {
	const errors = {};
	if (!values.type) {
		errors.type = 'Wybierz typ zgody';
	}
	if (!values.file) {
		errors.file = 'Plik jest wymagany';
	}
	return errors;
}

export function validateClient(values) {
    let errors = {};
    if (!values.name) {
        errors.name = 'Nazwa firmy jest wymagana';
    }
    if (!values.phone) {
        errors.phone = 'Telefon jest wymagany';
    } else if (values.phone.length < 9) {
        errors.phone = 'Telefon musi mieć przynajmniej 9 cyfr';
    }
    if (!values.email) {
        errors.email = 'Adres e-mail jest wymagany';
    }
    if (!values.city) {
        errors.city = 'Nazwa miasta jest wymagana';
    }
    if (!values.postalCode) {
        errors.postalCode = 'Kod pocztowy jest wymagany';
    } else if (values.postalCode.length < 6) {
        errors.postalCode = 'Kod pocztowy musi mieć 5 cyfr, np.11-111';
    }
    if (!values.street) {
        errors.street = 'Nazwa ulicy jest wymagana';
    }
    if (!values.province) {
        errors.province = 'Nazwa województwa jest wymagana';
    }
    if (!values.fvName) {
        errors.fvName = 'Nazwa firmy jest wymagana';
    }
    if (!values.nip) {
        errors.nip = 'NIP jest wymagany';
    } else if (values.nip.length < 10) {
        errors.nip = 'NIP musi mieć przynajmniej 10 cyfr';
    }
    if (!values.fvPhone) {
        errors.fvPhone = 'Numer telefonu jest wymagany';
    } else if (values.fvPhone.length < 6) {
        errors.fvPhone = 'Telefon musi mieć przynajmniej 9 cyfr';
    }
    if (!values.fvEmail) {
        errors.fvEmail = 'Adres e-mail jest wymagany';
    }
    if (!values.fvCity) {
        errors.fvCity = 'Nazwa miasta jest wymagana';
    }

    if (!values.fvStreet) {
        errors.fvStreet = 'Nazwa ulicy jest wymagana';
    }
    if (!values.fvPostalCode) {
        errors.fvPostalCode = 'Kod pocztowy jest wymagany';
    } else if (values.fvPostalCode.length < 6) {
        errors.fvPostalCode = 'Kod pocztowy musi mieć 5 cyfr, np.11-111';
    }
    errors.newPeople = [];
    values.newPeople.map((item, i) => {
        let result = validateProfile(item);
        if (result !== null && isEmptyObject(result) === false) {
            errors.newPeople[i] = result;
        }
    });
    if (values.persons){
        if (values.newPeople.length == 0 && values.persons.length==0) {
            errors.newPeopleInfo = "Osoba kontaktowa jest wymagana";
        }
    }
    if(!values.persons && values.newPeople.length==0){
        errors.newPeopleInfo = "Osoba kontaktowa jest wymagana";
    }

	if (values.status === null) {
		errors.status = 'Status jest wymagany';
	}

    if(!values.projectTypes || values.projectTypes.length==0){
        errors.projectTypes = "Wymagane jest wybranie minimum jednej usługi";
    }

    // console.log(errors)

    // remove newPeople error object if all add new person forms passes validation
    if (errors.newPeople.length === 0) {
        delete errors.newPeople;
    }

    errors.newAgreements = [];
    values.newAgreements.map((item, i) => {
        let result = validateAgreements(item);
        if (result !== null && isEmptyObject(result) === false) {
            errors.newAgreements[i] = result;
        }
    });

    errors.agreements = [];
    values.agreements.map((item, i) => {
        let result = validateAgreements(item);
        console.log(result);
        if (result !== null && isEmptyObject(result) === false) {
            errors.agreements[i] = result;
        }
    });

    if (errors.newAgreements.length === 0) {
        delete errors.newAgreements;
    }
    if (errors.agreements.length === 0) {
        delete errors.agreements;
    }

    console.log(errors)

    return errors;
}