import React from 'react';
import axios from 'axios';
import {Form, Formik} from "formik";
import moment from "moment";
import CalendarAbsenceForm, {validateCalendarAbsence} from "./calendarAbsenceForm";
import {formikErrorHelper} from "../form/formUtils";
import Dump from "../component/dump";

export default function CalendarAbsenceAdd(props) {
	function handleSubmit(values, bag) {
		let toSend = {
			owner: values.owner,
			type: values.type,
			dateFrom: values.range[0].format('YYYY-MM-DD HH:mm:ss'),
			dateTo: values.range[1].format('YYYY-MM-DD HH:mm:ss'),
		};

		axios.post(API_URL + '/calendar/create', toSend)
			.then(function(response) {
				bag.setSubmitting(false);
				//console.log(response);
				if (typeof props.handleRefresh === 'function') {
					props.handleRefresh();
				}
				if (typeof props.handleClose === 'function') {
					props.handleClose();
				}
			}).catch(function(error) {
				formikErrorHelper(error, bag);
			});
	}

	const now = moment();
	let defaultFrom = now;
	let defaultTo = now.clone().add(1, 'days');

	return (
		<Formik
			initialValues={{
				owner: null,
				type: null,
				range: [defaultFrom, defaultTo]
			}}
			validate={validateCalendarAbsence}
			onSubmit={handleSubmit}
		>
			{(propsFormik) => {
				const {values, touched, errors, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched} = propsFormik;

				return (
					<Form>
						<div className="rcModelContents" style={{minWidth: 770}}>
							<div className="headerWithButtons">
								<h1>Dodaj urlop</h1>
							</div>

							<CalendarAbsenceForm {...propsFormik} />

							{status && <div className="errorMessage">{status}</div>}

							{/*<Dump value={values} />*/}
						</div>
						<div className="rcModalActions">
							<button type="submit" disabled={isSubmitting}>Dodaj</button>
						</div>
					</Form>
				)
			}}
		</Formik>
	);
}
