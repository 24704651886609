import React from 'react';

export function validateProcedure(values) {
	let errors = {};
	if (!values.name) {
		errors.name = 'Nazwa jest wymagana';
	}
	if (!values.department) {
		errors.department = 'Dział jest wymagany';
	}
	return errors;
}
