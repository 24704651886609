import axios from "axios";

export const FETCH_TASKS_BEGIN = 'FETCH_TASKS_BEGIN';
export const FETCH_TASKS_SUCCESS = 'FETCH_TASKS_SUCCESS';
export const FETCH_TASKS_FAILURE = 'FETCH_TASKS_FAILURE';

export function actionFetchTasksBegin() {
	return {
		type: FETCH_TASKS_BEGIN
	};
}

export function actionFetchTasksSuccess(data) {
	return {
		type: FETCH_TASKS_SUCCESS,
		list: data
	};
}

export function actionFetchTasksFailure(error) {
	console.log('Error ', error);
	return {
		type: FETCH_TASKS_FAILURE,
		error: error
	};
}

export function actionFetchTasks() {
	return dispatch => {
		dispatch(actionFetchTasksBegin());

		return axios.get(API_URL + '/definition/task/list')
			.then(function(response) {
				if (Array.isArray(response.data.data.list.list)) {
					dispatch(actionFetchTasksSuccess(response.data.data.list.list));
				} else {
					dispatch(actionFetchTasksFailure());
				}
			}).catch(function(error) {
				dispatch(actionFetchTasksFailure(error));
			});
	};
}