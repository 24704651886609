import { useState, useEffect } from 'react';
import Select from "./select";
import { Option } from "rc-select";
import { OfferStatuses } from '../state/actionsOffer';

function SelectOfferStatus(props) {
  const [statuses, setStatuses] = useState([]);

  useEffect(() => {
    setStatuses(OfferStatuses)
  }, []);

	return (
		<Select
			loading={props.loading}
			showSearch={true}
			showArrow={true}
			{...props}
		>
			{statuses && statuses.map((item, index) => (
				<Option value={parseInt(item.id)} key={'status' + item.id}>{item.name}</Option>
			))}
		</Select>
	);
}

export default SelectOfferStatus;