import React from "react";
import {Link, useHistory} from "react-router-dom";
import axios from 'axios';
import {ErrorMessage, Form, Formik} from 'formik';
import Field from "../form/field";
import {useDispatch} from 'react-redux';
import {actionLogin} from "../state/actionsAuth";

export default function Login(props) {

	const dispatch = useDispatch();
	const history = useHistory();

	function handleSubmit(values, bag) {
		axios.post(API_URL + '/auth/login', values)
			.then(function(response) {
				bag.setSubmitting(false);

				if (response.data.data.access_token) {
					//console.log('accessToken', response.data.data.access_token);

					dispatch(actionLogin(response.data.data.access_token));
					history.push('/');
				}
			}).catch(function (error) {
				if (error.response && error.response.status === 400) {
					bag.setStatus('Nieprawidłowe hasło lub nazwa użytkownika');
					bag.setSubmitting(false);
				}
			});
	}

	return (
		<div className="loginWrapper">
			<h2>Logowanie</h2>
			<div className="loginBox">
				<Formik
					initialValues={{
						username: "",
						password: ""
					}}
					validate={values => {
						let errors = {};
						if (!values.username) {
							errors.username = 'Nazwa użytkownika jest wymagana';
						}
						if (!values.password) {
							errors.password = 'Hasło jest wymagane';
						}
						return errors;
					}}
					onSubmit={handleSubmit}
				>
					{(propsFormik) => {
						const {values, touched, errors, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, handleReset, status} = propsFormik;
						return (
							<Form>
								<div className="formItem">
									<Field type="email" name="username" placeholder="Nazwa użytkownika"/>
									<ErrorMessage component="div" className="errorMessage" name="username"/>
								</div>

								<div className="formItem">
									<Field type="password" name="password" placeholder="Hasło"/>
									<ErrorMessage component="div" className="errorMessage" name="password"/>
								</div>

								{status && <div className="errorMessage">{status}</div>}

								<input type="submit" value="Zaloguj się" className="top8" disabled={isSubmitting}/>
							</Form>
						)
					}}
				</Formik>
			</div>
			<br/>
			<Link to={"/password/recovery"}>Zapomniałem hasła</Link>
		</div>
	);
}
