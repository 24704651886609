import React from 'react';
import {Form, Formik} from "formik";
import SelectPerson from "../form/selectPerson";
import DatePicker from "../form/datePicker";
import SelectIssueType from '../form/selectIssueType';
import SelectIssueStatus from '../form/selectIssueStatus';
import SelectClient from '../form/selectClient';

export default function FilterIssue(props) {

	function handleSubmit(values) {
		if (typeof props.onChange === 'function') {
			props.onChange(values);
		}
		if (typeof props.handleClose === 'function') {
			props.handleClose();
		}
	}

	return (
		<Formik
			initialValues={props.filters ? props.filters : {}}
			onSubmit={handleSubmit}
		>
			{(propsFormik) => {
				const {values, touched, errors, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, resetForm, setFieldValue, setFieldTouched} = propsFormik;

				return (
					<Form>
						<div className="rcModelContents">
							<h3 className="top0 bottom24">Filtry</h3>

							<div className="row with two columns">
								<div className="column">
									<div className="formItem">
										<label htmlFor="dateRealization" className="bottom10">Data realizacji</label>
										<DatePicker
											name="dateRealization"
											onChange={setFieldValue}
											onBlur={setFieldTouched}
											value={values.dateRealization}
											className="fullWidth"
										/>
									</div>
									<div className="formItem">
										<label htmlFor="type" className="bottom10">Typ zgłoszenia</label>
										<SelectIssueType
											name="type"
											value={values.type}
											onChange={setFieldValue}
											onBlur={setFieldTouched}
											className="fullWidth"
											mode="multiple"
										/>
									</div>
									<div className="formItem">
										<label htmlFor="status" className="bottom10">Status zgłoszenia</label>
										<SelectIssueStatus
											name="status"
											value={values.status}
											onChange={setFieldValue}
											onBlur={setFieldTouched}
											className="fullWidth"
											mode="multiple"
										/>
									</div>
									<div className="formItem">
										<label htmlFor="dateFrom" className="bottom10">Data od</label>
										<DatePicker
											name="dateFrom"
											onChange={setFieldValue}
											onBlur={setFieldTouched}
											value={values.dateFrom}
											className="fullWidth"
										/>
									</div>
								</div>
								<div className="column">
									<div className="formItem">
										<label htmlFor="orderedBy" className="bottom10">Zlecający</label>
										<SelectPerson
											name="orderedBy"
											onChange={setFieldValue}
											onBlur={setFieldTouched}
											value={values.orderedBy}
											className="fullWidth"
										/>
									</div>

									<div className="formItem">
										<label htmlFor="user2" className="bottom10">Wykonawca</label>
										<SelectPerson
											name="user2"
											onChange={setFieldValue}
											onBlur={setFieldTouched}
											value={values.user2}
											className="fullWidth"
										/>
									</div>
									<div className="formItem">
										<label htmlFor="client" className="bottom10">Klient</label>
										<SelectClient
											name="client"
											onChange={setFieldValue}
											onBlur={setFieldTouched}
											value={values.client}
											className="fullWidth"
											mode="multiple"
										/>
									</div>
									<div className="formItem">
										<label htmlFor="dateTo" className="bottom10">Data do</label>
										<DatePicker
											name="dateTo"
											onChange={setFieldValue}
											onBlur={setFieldTouched}
											value={values.dateTo}
											className="fullWidth"
										/>
									</div>
								</div>
							</div>
							<div className="textCenter bottom20 top20">
								<a href="#" className="blue" onClick={(event) => {
									event.preventDefault();

									if (typeof props.onChange === 'function') {
										props.onChange({});
									}
									if (typeof props.handleClose === 'function') {
										props.handleClose();
									}
								}}>Wyczyść filtry</a>
							</div>

							{/*<Dump value={values}/>*/}
							{/*<Dump value={propsFormik}/>*/}
						</div>

						<div className="rcModalActions">
							<button>Zapisz</button>
						</div>
					</Form>
				)
			}}
		</Formik>
	);
}
