import React, {useContext, useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import axios from "axios";
import {isEmptyObject} from "../utils";
import Spinner from "../component/spinner";
import Sortable from "../component/sortable";
import TaskRow from "../task/taskRow";
import ReactModal from "react-modal";
import TaskView from "../task/taskView";
import {connect, useDispatch} from "react-redux";
import {actionFetchTaskStatuses} from "../state/actionsTaskStatuses";
import {AccessContext} from "../access";
import Avatar from "../component/avatar";
import {Helmet} from "react-helmet-async";

const mapStateToProps = function(state) {
	return {
		taskStatuses: state.taskStatuses.list,
		taskStatusesLoading: state.taskStatuses.loading,
	};
};

function ProfileView(props) {
	let {id} = useParams();
	const dispatch = useDispatch();
	const access = useContext(AccessContext);

	const [person, setPerson] = useState({});

	const [tasks, setTasks] = useState(null);
	const [orderColumn, setOrderColumn] = useState('dateRealizationLimit');
	const [orderDirection, setOrderDirection] = useState(true);

	const [showTasks, setShowTasks] = useState(false);
	const [showClients, setShowClients] = useState(false);

	const [projectId, setProjectId] = useState(null);
	const [taskId, setTaskId] = useState(null);
	const [procedureId, setProcedureId] = useState(null);
	const [serviceId, setServiceId] = useState(null);

	const [showModalView, setShowModalView] = useState(false);

	useEffect(() => {
		if (props.taskStatusesLoading === false && (props.taskStatuses == null || props.taskStatuses.length === 0)) {
			dispatch(actionFetchTaskStatuses());
		}
	}, []);

	useEffect(() => {
		async function fetchPerson() {
			const response = await axios(API_URL + '/profile/' + id);
			//console.log(response);
			if (response.data.data.user) {
				setPerson(response.data.data.user);
			}
		}

		fetchPerson();
	}, [id]);

	async function fetchTasks() {
		if (access.manager === true || access.id === person.id) {
			let toSend = {};

			if (orderColumn && orderDirection !== null) {
				toSend.sort = orderColumn;
				if (orderDirection === true) {
					toSend.order = 'desc';
				}
				if (orderDirection === false) {
					toSend.order = 'asc';
				}
			}

			if (person && person.hasOwnProperty('id')) {
				const response = await axios.get(API_URL + '/project/task/assigned/' + person.id, {params: toSend});

				let data = response.data.data;
				//console.log(data);
				if (Array.isArray(data.list)) {
					data.list.map(function(item) {
						if (item.hasOwnProperty('procedure') && item.procedure.hasOwnProperty('id')) {
							item.procedure = item.procedure.id;
						}

						if (item.hasOwnProperty('service') && item.service.hasOwnProperty('id')) {
							item.service = item.service.id;
						}
					});

					setTasks(data.list);
				}
			}
		}
	}

	useEffect(() => {
		fetchTasks();
	}, [orderDirection, orderColumn, person]);

	function handleRefresh() {
		fetchTasks();
	}

	function handleSortChange(column, direction) {
		setOrderDirection(direction);
		setOrderColumn(column);
	}

	function handleCloseModalView() {
		setShowModalView(false);
	}

	function handleOpenModalView(id, projectId, serviceId, procedureId) {
		setTaskId(id);
		setProjectId(projectId);
		setServiceId(serviceId);
		setProcedureId(procedureId);
		setShowModalView(true);
	}

	function canViewTasks() {
		return (access.manager === true || access.id === person.id);
	}

	return (isEmptyObject(person) ? <div className="textCenter"><Spinner /></div> : render());

	function render() {
		return (
			<>
				<Helmet>
					<title>{props.title ? props.title + ' - ' + APP_NAME : APP_NAME}</title>
				</Helmet>

				<div className="headerWithButtons">
					<h1>{props.title}</h1>
					{(access.admin === false && access.id !== person.id) ? '' : (
						<div className="right">
							<Link className="left32 button withChevron" to={props.link + "/edit/" + person.id}>
								Edytuj&nbsp;<i className="fas fa-chevron-right"/>
							</Link>
						</div>
					)}
				</div>

				<div className="box displayInlineBlock floatLeft">
					<Avatar picture={person.picture} size="large"/>
				</div>

				<div className="box overflowHidden" style={{marginLeft: 193}}>
					<h3 className="top0 bottom32">{props.subTitle}</h3>
					<div className="row with four columns">
						<div className="formItem column">
							<label htmlFor="firstName">Imię</label>
							<b>{person.firstName}</b>
						</div>
						<div className="formItem column">
							<label htmlFor="lastName">Nazwisko</label>
							<b>{person.lastName}</b>
						</div>
						<div className="formItem column">
							<label htmlFor="department">Dział</label>
							<b>{person.department ? person.department.name : ''}</b>
						</div>
						<div className="formItem column">
							<label htmlFor="position">Stanowisko</label>
							<b>{person.position ? person.position.name : null}</b>
						</div>
						<div className="formItem column">
							<label htmlFor="phone">Numer telefonu</label>
							<b>{person.phone}</b>
						</div>
						<div className="formItem column">
							<label htmlFor="email">Adres e-mail</label>
							<b>{person.email}</b>
						</div>
					</div>

					<div className="formItem">
						<label htmlFor="businessArea">Obszary działalności</label>
						<b>{person.businessArea}</b>
					</div>
				</div>

				{person.clients && person.clients.length > 0 ? (<>
					<button className="grey plus right12 floatLeft" onClick={() => setShowClients(!showClients)}>
						<i className={"fas " + (!showClients ? "fa-caret-right" : "fa-caret-down")}/>
					</button>
					<h3 className="padTop4 bottom24">Opiekun klienta</h3>
				</>) : ''}
				<div className="row with two columns">
				{showClients && person.clients && person.clients.map((item, index) => {
					// dont render if the details are not there
					let color = (item.color ? item.color : 'grey');

					return (
						<div key={"client" + item.id} className="box smallerPadding column">
							<div className="clientDot right4" style={{ backgroundColor: color}}>{item.name.charAt(0)}</div> {item.name}
						</div>
					);
				})}
				</div>

				{person.replacements && person.replacements.length > 0 ? <h3 className="top0 bottom24">Zastępstwa</h3> : ''}
				<div className="row with two columns">
					{person.replacements && person.replacements.map(function(item, index) {
						return (
							<div className="box smallerPadding column" key={"replacement" + index}>
								<div className="floatRight top4">do {item.dateTo}</div>
								<div className="floatRight top4 right16">od {item.dateFrom}</div>
								<Avatar picture={item.replacedUser.picture} cssClass="noShadow right4"/> {item.replacedUser.fullname}
							</div>
						);
					})}
				</div>

				{canViewTasks() ? (<>
					{(tasks && tasks.length > 0) ? (<>
						<button className="grey plus right12 floatLeft" onClick={() => setShowTasks(!showTasks)}>
							<i className={"fas " + (!showTasks ? "fa-caret-right" : "fa-caret-down")}/>
						</button>
						<h3 className="padTop4 bottom24">Zadania</h3>
						{showTasks ? (
							<div className="boxTable">
								<div className="boxHeader">
									<div className="boxCell">Lp.</div>
									<div className="boxCell"><Sortable onChange={handleSortChange} index="name" value={(orderColumn === 'name') ? orderDirection : null}>Nazwa zadania</Sortable></div>
									<div className="boxCell"><Sortable onChange={handleSortChange} index="client" value={(orderColumn === 'client') ? orderDirection : null}>Klient</Sortable></div>
									<div className="boxCell"><Sortable onChange={handleSortChange} index="estimation" value={(orderColumn === 'estimation') ? orderDirection : null}>Prognozowany<br/> czas realizacji</Sortable></div>
									<div className="boxCell"><Sortable onChange={handleSortChange} index="dateRealization" value={(orderColumn === 'dateRealization') ? orderDirection : null}>Prognozowana<br/> data wykonania</Sortable></div>
									<div className="boxCell"><Sortable onChange={handleSortChange} index="dateRealizationLimit" value={(orderColumn === 'dateRealizationLimit') ? orderDirection : null}>Data i godzina graniczna<br/>do wykonania</Sortable></div>
									<div className="boxCell"><Sortable onChange={handleSortChange} index="orderedBy" value={(orderColumn === 'orderedBy') ? orderDirection : null}>Zlecający</Sortable></div>
									<div className="boxCell"><Sortable onChange={handleSortChange} index="status" value={(orderColumn === 'status') ? orderDirection : null}>Status</Sortable></div>
									<div className="boxCell"/>
								</div>

								{tasks && tasks.map(function(item, index) {
									return [
										<TaskRow
											key={'task' + index}
											value={item}
											lp={index + 1}
										    refresh={fetchTasks}
										    handleOpenModalView={handleOpenModalView}
											taskStatuses={props.taskStatuses}
										/>,
										<div key={'spacer' + index} className="boxRow spacer"/>
									];
								})}
							</div>
						) : ''}
					</>) : ''}

					{(tasks == null) ? <div className="textCenter"><Spinner/></div> : ''}
				</>) : ''}

				<ReactModal
					isOpen={showModalView}
					contentLabel=""
					onRequestClose={handleCloseModalView}
					shouldCloseOnOverlayClick={true}
					className="rcModal withActions bigger"
					overlayClassName="rcOverlay"
				>
					<span onClick={handleCloseModalView} className="rcModalClose"><i className="fas fa-times"/>&nbsp;Zamknij</span>

					<TaskView
						handleRefresh={handleRefresh}
						handleClose={handleCloseModalView}
						taskId={taskId}
						projectId={projectId}
						serviceId={serviceId}
						procedureId={procedureId}
					/>
				</ReactModal>
			</>
		);
	}
}

export default connect(mapStateToProps)(ProfileView);