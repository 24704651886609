import React, {useEffect, useState} from 'react'
import axios from 'axios'
import {Form, Formik} from "formik"
import {useParams, useHistory} from "react-router-dom";
import BackButton from "../component/backButton";
import {isEmptyObject, objectClone} from "../utils";
import GuestForm, {validateGuest} from "./guestForm";
import Spinner from "../component/spinner";
import {formikErrorHelper} from "../form/formUtils";
import moment from "moment";

export default function GuestEdit(props) {
	let {id} = useParams();
	const history = useHistory();

	const [guest, setGuest] = useState({});

	useEffect(() => {
		async function fetchGuest() {
			const response = await axios(API_URL + '/reception/' + id);
			//console.log(response);

			if (response.data.data.reception) {
				let foo = objectClone(response.data.data.reception);
				if (foo.hasOwnProperty('user') && foo.user.hasOwnProperty('id')) {
					foo.user = foo.user.id;
				}
				if (foo.hasOwnProperty('client') && foo.client.hasOwnProperty('id')) {
					foo.client = foo.client.id;
				}

				setGuest(foo);
			}
		}

		fetchGuest();
	}, [id]);

	function handleSubmit(values, bag) {
		let toSave = {};
		Object.keys(values).map(function(item) {
			if (guest[item] !== values[item]) {
				toSave[item] = values[item];
			}
		});

		if (toSave.hasOwnProperty('client') && toSave.client) {
			toSave.client = parseInt(toSave.client);
		}

		if (toSave.hasOwnProperty('user')) {
			toSave['user'] = parseInt(toSave['user']);
		}

		toSave.category = "guest";

		axios.patch(API_URL + '/reception/' + id, toSave)
			.then(function(response) {
				bag.setSubmitting(false);
				//console.log(response.data);
				history.goBack();
			}).catch(function(error) {
				formikErrorHelper(error, bag);
			});
	}

	return (isEmptyObject(guest) ? <div className="textCenter"><Spinner/></div> : renderFormik());

	function renderFormik() {
		let initial = objectClone(guest);
		delete initial.id;
		initial.operationDate = moment(initial.operationDate);

		if (initial.hasOwnProperty('client') === false) {
			initial.client = null;
		}
		if (initial.hasOwnProperty('clientName') === false) {
			initial.clientName = "";
		}

		return (
			<Formik
				initialValues={initial}
				validate={validateGuest}
				onSubmit={handleSubmit}
			>
				{(propsFormik) => {
					const {values, touched, errors, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched, status} = propsFormik;

					return (
						<Form>
							<div className="headerWithButtons">
								<h1>Edytuj klienta</h1>
								<div className="right">
									<BackButton>Anuluj</BackButton>
									<button type="submit" disabled={isSubmitting} className="left32 withChevron">
										Zapisz&nbsp;<i className="fas fa-chevron-right"/>
									</button>
								</div>
							</div>

							<div className="box">
								<GuestForm {...propsFormik}/>
							</div>

							{status && <div className="errorMessage">{status}</div>}

							{/*<Dump value={values} />*/}
							{/*<Dump value={initial} />*/}
						</Form>
					)
				}}
			</Formik>
		);
	}
}
