import {FETCH_TASK_TYPES_BEGIN, FETCH_TASK_TYPES_SUCCESS, FETCH_TASK_TYPES_FAILURE} from './actionsTaskTypes';

const reducerTaskTypes = (state = {list: [], loading: false}, action) => {

	switch (action.type) {
		case FETCH_TASK_TYPES_BEGIN:
			return {
				...state,
				loading: true,
				error: null // also, reset any errors
			};

		case FETCH_TASK_TYPES_SUCCESS:
			// replace the items with the ones from the server
			return {
				...state,
				loading: false,
				list: action.list,
			};

		case FETCH_TASK_TYPES_FAILURE:
			// save the error
			return {
				...state,
				error: action.error,
				loading: false,
				list: []
			};

		default:
			return state
	}
};

export default reducerTaskTypes