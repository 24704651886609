import {FETCH_PROFILE_BEGIN, FETCH_PROFILE_SUCCESS, FETCH_PROFILE_FAILURE} from './actionsProfile';

const reducerProfile = (state = {me: {}, loading: false}, action) => {

	switch (action.type) {
		case FETCH_PROFILE_BEGIN:
			return {
				...state,
				loading: true,
				error: null // also, reset any errors
			};

		case FETCH_PROFILE_SUCCESS:
			// replace the items with the ones from the server
			return {
				...state,
				loading: false,
				me: action.me,
			};

		case FETCH_PROFILE_FAILURE:
			// save the error
			return {
				...state,
				error: action.error,
				loading: false,
				me: {}
			};

		default:
			return state
	}
};

export default reducerProfile