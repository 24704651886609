import axios from "axios";

export const FETCH_KNOWLEDGE_TYPES_BEGIN = 'FETCH_KNOWLEDGE_TYPES_BEGIN';
export const FETCH_KNOWLEDGE_TYPES_SUCCESS = 'FETCH_KNOWLEDGE_TYPES_SUCCESS';
export const FETCH_KNOWLEDGE_TYPES_FAILURE = 'FETCH_KNOWLEDGE_TYPES_FAILURE';

export function actionFetchKnowledgeTypesBegin() {
	return {
		type: FETCH_KNOWLEDGE_TYPES_BEGIN
	};
}

export function actionFetchKnowledgeTypesSuccess(data) {
	return {
		type: FETCH_KNOWLEDGE_TYPES_SUCCESS,
		list: data
	};
}

export function actionFetchKnowledgeTypesFailure(error) {
	console.log('Error ', error);
	return {
		type: FETCH_KNOWLEDGE_TYPES_FAILURE,
		error: error
	};
}

export function actionFetchKnowledgeTypes() {
	return dispatch => {
		dispatch(actionFetchKnowledgeTypesBegin());

		return axios.get(API_URL + '/knowledgeBase/types')
			.then(function(response) {
				//console.log(response.data.data.types);
				if (Array.isArray(response.data.data.types)) {
					dispatch(actionFetchKnowledgeTypesSuccess(response.data.data.types));
				} else {
					dispatch(actionFetchKnowledgeTypesFailure());
				}
			}).catch(function(error) {
				dispatch(actionFetchKnowledgeTypesFailure(error));
			});
	};
}