import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Link, useHistory, useParams} from "react-router-dom";
import {isEmptyObject, objectClone} from "../utils";
import Spinner from "../component/spinner";
import {connect, useDispatch} from "react-redux";
import BackButton from "../component/backButton";
import FileView from "../component/fileView";
import moment from 'moment';
import Dump from "../component/dump";

const mapStateToProps = function(state) {
	return {
		procedures: state.procedures.list,
		loading: state.procedures.loading,
	};
};

const OfferView = (props) => {
	const { id } = useParams();
	const history = useHistory();
	const dispatch = useDispatch();

	const [offer, setOffer] = useState({});

  useEffect(() => {
    async function fetchOffer() {
      const response = await axios(API_URL + '/offer/' + id);
      if (response.data.data.offer) {
        let foo = objectClone(response.data.data.offer);
        setOffer(foo);
      }
    }
    fetchOffer();
  }, []);

  const renderView = () => {

    return (
      <>
        <div className="headerWithButtons">
          <h1>{offer.name}</h1>
          <div className="right">
            <BackButton>Powrót </BackButton>
            {[0,3].includes(offer.status.id) &&
              <Link className="left32 button withChevron" to={"/offer/edit/" + offer.id}>
                Edytuj&nbsp;<i className="fas fa-chevron-right"/>
              </Link>
            }
          </div>
        </div>
        <div className="box">
          {offer.previous &&
            <div className="row with one columns">
              <div className="formItem column">
                <label htmlFor="services">Poprzednio złożona oferta</label>
                <input className="fullWidth" type="text" value={offer.previous.name} readOnly="readonly" />
                <Link className="top10 button withChevron" to={"/offer/view/" + offer.previous.id} target="_blank">
                  Zobacz&nbsp;<i className="fas fa-eye"></i>
                </Link>
              </div>
            </div>
          }

          <div className="row with one columns">
            <div className="formItem column">
              <label htmlFor="services">Usługa której dotyczy oferta</label>
              <input className="fullWidth" type="text" value={offer.projectTypes.map((projectType) =>  ' ' + projectType.name + '' )} readOnly="readonly" />
            </div>
          </div>

          <div className="formItem">
            <label htmlFor="description">Opis projektu</label>
            <textarea className="fullWidth" readOnly="readonly" rows="4" value={offer.description} />
          </div>

          <div className="row with four columns">
            <div className="formItem column">
              <label htmlFor="client">Klient</label>
              <input className="fullWidth" type="text" value={offer.client.name} readOnly="readonly" />
            </div>

            <div className="formItem column">
              <label htmlFor="salesPerson">Sprzedawca</label>
              <input className="fullWidth" type="text" value={offer.seller.name} readOnly="readonly" />
            </div>

            <div className="formItem column">
              <label htmlFor="submission">Data złożenia oferty klientowi</label>
              <input className="fullWidth" type="text" value={offer.submission} readOnly="readonly" />
            </div>

            <div className="formItem column">
              <label htmlFor="created">Data utworzenia oferty</label>
              <input className="fullWidth" type="text" value={offer.created} readOnly="readonly" />
            </div>

          </div>

          <div className="row with one columns">
            <div className="formItem column">
              <label htmlFor="status">Status</label>
              <input className="fullWidth" type="text" value={offer.status.name} readOnly="readonly" />
            </div>
          </div>

          {offer.comments.length > 0 &&
            <div className="formItem">
              <label htmlFor="description">Komentarze</label>
              {offer.comments && offer.comments.map((comment, index) => {
                const created = moment(comment.created).format('YYYY-MM-DD');
                return (
                  <div key={comment.id}>
                    <span>{created} - {comment.status}</span>
                    <textarea className="fullWidth" readOnly="readonly" rows="4" value={comment.comment} />
                  </div>
                )
              })}
            </div>
          }

          <div className="row with four columns">
            {offer.files && offer.files.map(function(file, index) {
              return (
                <div className="formItem column" key={file.id}>
                  {(index === 0) ? <label>Załączone pliki (np. instrukcje)</label> : <label>&nbsp;</label>}
                  <FileView fileId={file.id} path={file.path}  />
                </div>
              );
            })}
          </div>
        </div>

        {/*<Dump value={values} />*/}
        {/* <Dump value={offer} /> */}
        {/*<Dump value={service} />*/}
      </>
    );
  }

  return (isEmptyObject(offer) ? <div className="textCenter top96"><Spinner/></div> : renderView());
}

export default connect(mapStateToProps)(OfferView);
