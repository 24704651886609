export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const AUTHED = 'AUTHED';
export const NOT_AUTHED = 'NOT_AUTHED';

export const actionLogin = function(token) {
	return {type: LOGIN, token: token};
};

export const actionLogout = function() {
	return {type: LOGOUT};
};

export const actionAuthed = function() {
	return {type: AUTHED};
};

export const actionNotAuthed = function() {
	return {type: NOT_AUTHED};
};