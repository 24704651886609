import React, {useEffect, useState} from 'react'
import axios from 'axios'
import {Link} from "react-router-dom"
import GuestRow from "./guestRow";
import Sortable from "../component/sortable";
import Spinner from "../component/spinner";
import PaginationLocale from 'rc-pagination/lib/locale/pl_PL';
import Pagination from 'rc-pagination';
import ReactModal from "react-modal";
import GuestView from "./guestView";

export default function GuestList(props) {
	const perPage = 10;
	const [currentPage, setCurrentPage] = useState(1);
	const [guestCount, setGuestCount] = useState(1);

	const [search, setSearch] = useState("");
	const [guests, setGuests] = useState(null);

	const [orderColumn, setOrderColumn] = useState('operationDate');
	const [orderDirection, setOrderDirection] = useState(true);

	const [guestId, setGuestId] = useState(null);
	const [showModalView, setShowModalView] = useState(false);

	async function fetchGuests() {
		let toSend = {
			'filter[category]': "guest",
			page: currentPage,
			perPage: perPage
		};

		if (search) {
			toSend.search = search;
		}

		if (orderColumn && orderDirection !== null) {
			toSend.sort = orderColumn;
			if (orderDirection === true) {
				toSend.order = 'desc';
			}
			if (orderDirection === false) {
				toSend.order = 'asc';
			}
		}

		const response = await axios.get(API_URL + '/reception/list', {params: toSend});

		let data = response.data.data;
		//console.log(data);
		if (Array.isArray(data.list)) {
			setGuests(data.list);
			setGuestCount(data.count);
		}
	}

	useEffect(() => {
		fetchGuests();
	}, [search, currentPage, perPage, orderDirection, orderColumn]);

	function handleSortChange(index, direction) {
		setOrderDirection(direction);
		setOrderColumn(index);
	}

	function onPageChange(page) {
		setCurrentPage(page);
	}

	function handleCloseModalView() {
		setShowModalView(false);
	}

	function handleOpenModalView(id) {
		setGuestId(id);
		setShowModalView(true);
	}

	function handleRefresh() {
		fetchGuests();
	}

	return (
		<div>
			<div className="headerWithButtons">
				<div className="left">
					<h1>Recepcja - Goście</h1>
					<Link to="/guest/add" className="button left32 withChevron">
						Dodaj&nbsp;<i className="fas fa-plus"/>
					</Link>
				</div>

				<div className="right">
					<Link to="/package" className="button right32 green">Przesyłki</Link>
					<input type="search" className="pill" placeholder="Szukaj" value={search} onChange={function(event) { setSearch(event.target.value); }}/>
				</div>
			</div>

			<div className="boxTable">
				<div className="boxHeader">
					<div className="boxCell"><Sortable onChange={handleSortChange} index="fullName" value={(orderColumn === 'fullName') ? orderDirection : null}>Osoba</Sortable></div>
					<div className="boxCell"><Sortable onChange={handleSortChange} index="user" value={(orderColumn === 'user') ? orderDirection : null}>Do kogo</Sortable></div>
					<div className="boxCell"><Sortable onChange={handleSortChange} index="client" value={(orderColumn === 'client') ? orderDirection : null}>Firma</Sortable></div>
					<div className="boxCell"><Sortable onChange={handleSortChange} index="operationDate" value={(orderColumn === 'operationDate') ? orderDirection : null}>Data i godzina wejścia</Sortable></div>
					<div className="boxCell"/>
				</div>

				{guests && guests.map(function(item, index) {
					return [
						<GuestRow key={'guest' + index} value={item} refresh={handleRefresh} handleOpenModalView={handleOpenModalView}/>,
						<div key={'spacer' + index} className="boxRow spacer"/>
					];
				})}
			</div>

			{(guests == null) ? <div className="textCenter"><Spinner/></div> : ''}

			<div className="textCenter">
				<Pagination
					onChange={onPageChange}
					pageSize={perPage}
					defaultCurrent={1}
					current={currentPage}
					total={guestCount}
					//showTotal={(total) => `Total ${total} items`}
					prevIcon={<i className="fas fa-chevron-left"/>}
					nextIcon={<i className="fas fa-chevron-right"/>}
					locale={PaginationLocale}
				/>
			</div>

			<ReactModal
				isOpen={showModalView}
				contentLabel=""
				onRequestClose={handleCloseModalView}
				shouldCloseOnOverlayClick={true}
				className="rcModal withActions bigger"
				overlayClassName="rcOverlay"
			>
				<span onClick={handleCloseModalView} className="rcModalClose"><i className="fas fa-times"/> Zamknij</span>

				<GuestView handleClose={handleCloseModalView} packageId={guestId} />
			</ReactModal>

			{/*<Dump value={filterSelectedTag}/>*/}
			{/*{<Dump label="column" value={orderColumn}/>}*/}
			{/*{<Dump label="direction" value={orderDirection}/>}*/}
		</div>
	);
}
