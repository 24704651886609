import React, {useEffect, useState} from "react"
import {connect, useDispatch} from "react-redux";
import Tag from "./tag";
import {actionFetchTags} from "../state/actionsTags";

const mapStateToProps = function(state) {
	return {
		tags: state.tags.list,
		loading: state.tags.loading,
	};
};

function FilterTag(props) {
	const dispatch = useDispatch();

	const [selected, setSelected] = useState(null);

	useEffect(() => {
		if (props.loading === false) {
			dispatch(actionFetchTags());
		}
	}, []);

	function handleFilterDepartmentClick(value) {
		setSelected(value);

		if (props.hasOwnProperty('onChange') && typeof props.onChange === 'function') {
			props.onChange(value);
		}
	}

	return (
		<div className="filter bottom40">
			Tagi:&nbsp;&nbsp;&nbsp;

			<Tag
				className={"tag preventTextSelection" + (selected === null ? ' selected' : ' cursorPointer')}
				onClick={() => handleFilterDepartmentClick(null)}
			>
				Wszystkie
			</Tag>

			{props.tags.map(function(tag) {
				return (
					<Tag
						key={"tag" + tag.id}
						className={"tag preventTextSelection" + (selected === tag.id ? ' selected' : ' cursorPointer')}
						onClick={() => handleFilterDepartmentClick(tag.id)}
					>
						#{tag.name}
					</Tag>
				);
			})}
		</div>
	);
}

export default connect(mapStateToProps)(FilterTag);