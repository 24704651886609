import React from 'react';
import {ErrorMessage, FieldArray} from "formik";
import Field from "../form/field";
import SelectDepartment from "../form/selectDepartment";
import SelectPosition from "../form/selectPosition";
import ManageReplacements from "../form/manageReplacements";
import SelectRole from "../form/selectRole";

export default function ProfileForm(props) {

	const {values, errors, handleChange, handleBlur, setFieldValue, setFieldTouched} = props;

	return (
		<>
			<div className="row with four columns">
				<div className="formItem column">
					<label htmlFor="firstName">Imię</label>
					<Field className="fullWidth" type="text" name="firstName"/>
					<ErrorMessage component="div" className="errorMessage" name="firstName"/>
				</div>

				<div className="formItem column">
					<label htmlFor="lastName">Nazwisko</label>
					<Field className="fullWidth" type="text" name="lastName"/>
					<ErrorMessage component="div" className="errorMessage" name="lastName"/>
				</div>

				<div className="formItem column">
					<label htmlFor="department">Dział</label>
					<SelectDepartment
						className="fullWidth"
						name="department"
						onChange={setFieldValue}
						onBlur={setFieldTouched}
						value={values.department}
						placeholder="Wybierz dział"
					/>
					<ErrorMessage component="div" className="errorMessage" name="department"/>
				</div>

				<div className="formItem column">
					<label htmlFor="position">Stanowisko</label>
					<SelectPosition
						className="fullWidth"
						name="position"
						onChange={setFieldValue}
						onBlur={setFieldTouched}
						value={values.position}
						placeholder="Wybierz stanowisko"
					/>
					<ErrorMessage component="div" className="errorMessage" name="position"/>
				</div>

				<div className="formItem column">
					<label htmlFor="phone">Numer telefonu</label>
					<Field className="fullWidth" type="tel" name="phone"/>
					<ErrorMessage component="div" className="errorMessage" name="phone"/>
				</div>

				<div className="formItem column">
					<label htmlFor="email">Adres e-mail</label>
					<Field className="fullWidth" type="email" name="email"/>
					<ErrorMessage component="div" className="errorMessage" name="email"/>
				</div>
			</div>

			<div className="formItem">
				<label htmlFor="businessArea">Obszary działalności</label>
				<Field className="fullWidth" component="textarea" name="businessArea" rows="6"/>
				<ErrorMessage component="div" className="errorMessage" name="businessArea"/>
			</div>

			{(props.access.manager === false) ? '' : (
			<div className="formItem">
				<label htmlFor="roles">Role</label>
				<SelectRole
					className="fullWidth"
					name="roles"
					onChange={setFieldValue}
					onBlur={setFieldTouched}
					value={values.roles}
				/>
				<ErrorMessage component="div" className="errorMessage" name="roles"/>
			</div>
			)}
		</>
	)
}

export function ProfileFormNewContact(props) {

	const {values, errors, handleChange, handleBlur, setFieldValue, setFieldTouched} = props;

	return (
		<FieldArray
			name="newReplacements"
			render={(arrayHelpers) => {
				function add(event, arrayHelpers) {
					event.preventDefault();
					arrayHelpers.push({
						replacedUser: "",
						dateFrom: "",
						dateTo: "",
					});
				}

				return (
					<span>
						{values.newReplacements.map((data, i) => (
							<ManageReplacements
								setFieldValue={setFieldValue}
								arrayHelpers={arrayHelpers}
								values={values}
								data={data}
								key={i}
								index={i}
								name="newReplacements"
							/>
						))}
						<div className="textCenter bottom32">
							<button className="plus" onClick={(event) => {add(event, arrayHelpers);}}><i className="fas fa-plus"/></button>
							<br/>
							<a href="#" onClick={(event) => {add(event, arrayHelpers);}}>dodaj zastępstwo</a>
						</div>
					</span>
				);
			}}
		/>
	)
}

export function validateProfile(values) {
	let errors = {};
	if (!values.firstName) {
		errors.firstName = 'Imię jest wymagane';
	}
	if (!values.lastName) {
		errors.lastName = 'Nazwisko jest wymagane';
	}
	if (!values.phone) {
		errors.phone = 'Telefon jest wymagany';
	} else if (values.phone.length < 9) {
		errors.phone = 'Telefon musi mieć przynajmniej 9 cyfr';
	}
	if (!values.email) {
		errors.email = 'Adres e-mail jest wymagany';
	}
	return errors;
}
