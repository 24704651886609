import React from "react";
import {ErrorMessage} from "formik";
import SelectPerson from "./selectPerson";
import DatePicker from "../form/datePicker";
import moment from 'moment';

export default function ManageReplacements(props) {
	const {index, name, values, setFieldValue, arrayHelpers} = props;
	const prefix = name + '.' + index;

	function handleClose(event) {
		event.preventDefault();

		if (props.data.hasOwnProperty('id') && typeof props.handleDelete == 'function') {
			props.handleDelete(props.data.id);
		} else {
			arrayHelpers.remove(index);
		}
	}

	function prepareDateForDatePicker(value) {
		if (value instanceof Date) {
			return value;
		}

		if (value === '') {
			return null;
		}

		return moment(value);
	}

	return (
		<div className="box smallerPadding">
			<a href="#" className="floatRight red font24" onClick={handleClose}><i className="fas fa-times"/></a>

			<h3 className="top0 bottom32">Zastępstwo</h3>
			<div className="row with three columns">
				<div className="column">
					<div className="formItem">
						<label htmlFor={`${prefix}.replacedUser`} className="bottom12">Kto</label>
						<SelectPerson
							name={`${prefix}.replacedUser`}
							onChange={setFieldValue}
							value={values[name][index]['replacedUser']}
							className="fullWidth"
						/>
						<ErrorMessage component="div" className="errorMessage" name={`${prefix}.replacedUser`}/>
					</div>
				</div>
				<div className="column">
					<div className="formItem">
						<label htmlFor={`${prefix}.dateFrom`} className="bottom12">Od</label>
						<DatePicker
							value={prepareDateForDatePicker(values[name][index]['dateFrom'])}
							onChange={setFieldValue}
							name={`${prefix}.dateFrom`}
						/>
						<ErrorMessage component="div" className="errorMessage" name={`${prefix}.dateFrom`}/>
					</div>
				</div>
				<div className="column">
					<div className="formItem">
						<label htmlFor={`${prefix}.dateTo`} className="bottom12">Do</label>
						<DatePicker
							value={prepareDateForDatePicker(values[name][index]['dateTo'])}
							onChange={setFieldValue}
							name={`${prefix}.dateTo`}
						/>
						<ErrorMessage component="div" className="errorMessage" name={`${prefix}.dateTo`}/>
					</div>
				</div>
			</div>
		</div>
	);
}
