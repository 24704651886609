import React, {useEffect} from 'react';
import Select from "./select";
import {Option} from "rc-select";
import {connect, useDispatch} from "react-redux";
import {actionFetchPositions} from "../state/actionsPositions";

const mapStateToProps = function(state) {
	return {
		positions: state.positions.list,
		loading: state.positions.loading,
	};
};

function SelectPosition(props) {
	const dispatcher = useDispatch();

	useEffect(() => {
		if (props.loading === false && (props.positions == null || props.positions.length === 0)) {
			dispatcher(actionFetchPositions());
		}
	}, []);

	let {positions, dispatch, ...otherProps} = props;
	let data = props.positions;

	return (
		<Select
			loading={props.loading}
			placeholder="Wybierz stanowisko"
			{...otherProps}
		>
			{data && data.map(function(item) {
				let label = (<div><span className="colorDot small" style={{backgroundColor: item.color}}/>{item.name}</div>);
				return (<Option value={parseInt(item.id)} key={'position' + item.id} label={label}>{label}</Option>);
			})}
		</Select>
	);
}

export default connect(mapStateToProps)(SelectPosition);