import React, {useEffect, useState, useRef} from 'react';
import {useDispatch, useSelector} from "react-redux";
import axios from 'axios';
import {Form, Formik} from "formik";
import {findIndexOfObjectWithPropertyValueInArray, isEmptyObject, objectClone} from "../utils";
import {useHistory} from "react-router-dom"
import OfferForm, {validateOffer} from "./offerForm";
import moment from "moment";
import BackButton from "../component/backButton";
import Spinner from "../component/spinner";
import {formikErrorHelper} from "../form/formUtils";
// import {getCompanyFromService, getProjectTypeFromService} from "./offerUtils";
import Dump from "../component/dump";
import ReactModal from 'react-modal';
import OfferClientAdd from './offerClientAdd';


export default function OfferAdd(props) {
  const history = useHistory();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const me = useSelector(state => state.profile.me);

  const [projectTypes, setProjectTypes] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const [showAddClientModal, setShowAddClientModal] = useState(false);
  const [newClient, setNewClient] = useState(null);

  const [service, setService] = useState(null);
  const [serviceError, setServiceError] = useState({});

  const offerFormRef = useRef();

  useEffect(() => {
    if ( !isEmptyObject(me) ) {
      setLoading(false);
    }
  }, [me]);

  function handleFileAdd(uploadedFile) {
    setUploadedFiles([...uploadedFiles, uploadedFile]);
  }

  function handleFileDelete(fileId) {
    let index = findIndexOfObjectWithPropertyValueInArray(uploadedFiles, fileId);

    if (index !== null && index !== -1) {
      let newUploadedFiles = [...uploadedFiles];
      newUploadedFiles.splice(index, 1);
      setUploadedFiles(newUploadedFiles);
    }
  }

  function addFiles(uploadedFiles, projectId) {
    let promisesArray = [];

    if (uploadedFiles && Array.isArray(uploadedFiles) && uploadedFiles.length > 0) {
      uploadedFiles.map((item) => {
        promisesArray.push(
          axios.post(API_URL + '/offer/file/' + projectId + '/' + item.id)
        );
      });
    }

    return promisesArray;
  }

  function handleSubmit(values, bag) {
    let toSend = objectClone(values);
    let error = {};
    delete toSend.created;

    function finish() {
      bag.setSubmitting(false);
      history.goBack();
    }

    axios.post(API_URL + '/offer/create', toSend)
      .then(function(response) {
        let newId = response.data.id;
        let promises = [];
        promises.concat(addFiles(uploadedFiles, newId));

        Promise.all(promises).then(function() {
          finish();
        });
      }).catch(function(error) {
        formikErrorHelper(error, bag);
      });
  }

  function handleShowAddClient(pt) {
    setProjectTypes(pt);
    setShowAddClientModal(true);
  }
  function handleHideAddClient() {
    setShowAddClientModal(false)
  }
  function handleAddNewClient(id) {
    // console.log(id);
    setNewClient(id);
    offerFormRef.current.updateClient(id);
    handleHideAddClient();
  }

  return (
    <>
      {!loading &&
        <Formik
          initialValues={{
            name: "",
            client: "",
            seller: me.id,
            projectTypes: [],
            description: "",
            created: moment().format('YYYY-MM-DD'),
            submission: moment(),
            files: null,
          }}
          validate={function(values) {
            let errors = validateOffer(values);
            if (uploadedFiles.length === 0) {
              errors.files = 'Plik jest wymagany!';
            }
            return errors;
          }}
          // validate={validateOffer}
          onSubmit={handleSubmit}
        >
          {(propsFormik) => {
            const {values, touched, errors, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched} = propsFormik;
            return (
              <>
                <Form>
                  <div className="headerWithButtons">
                    <h1>Tworzenie nowej oferty</h1>
                    <div className="right">
                      <BackButton>Anuluj</BackButton>
                      <button type="submit" disabled={isSubmitting} className="left32 withChevron">
                        Dodaj&nbsp;<i className="fas fa-chevron-right"/>
                      </button>
                    </div>
                  </div>
                  {/* <Dump value={values} label="" /> */}
                  <OfferForm
                    {...propsFormik}
                    handleFileAdd={handleFileAdd}
                    handleFileDelete={handleFileDelete}
                    uploadedFiles={uploadedFiles}
                    // service={service}
                    handleShowAddClient={handleShowAddClient}
                    newClient={newClient}
                    editMode={false}
                    copyMode={false}
                    comments={[]}
                    ref={offerFormRef}
                  />
                </Form>
              </>
            )
          }}
        </Formik>
      }
      {(loading) ? <div className="textCenter"><Spinner/></div> : ''}


      <ReactModal
          isOpen={showAddClientModal}
          contentLabel=""
          onRequestClose={handleHideAddClient}
          shouldCloseOnOverlayClick={true}
          className="rcModal withActions bigger"
          overlayClassName="rcOverlay"
        >
          <span onClick={handleHideAddClient} className="rcModalClose"><i className="fas fa-times"/> Zamknij</span>
          {!loading &&
            <OfferClientAdd
              handleAddNewClient={handleAddNewClient}
              seller={me.id}
              projectTypes={projectTypes}
            />
          }
      </ReactModal>
    </>
  );

}