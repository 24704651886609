import React, {Component} from 'react';
import ReactModal from "react-modal"
import PopupMenu from "../component/popupMenu";
import axios from "axios";
import {confirmDelete, findObjectWithPropertyValueInArray} from "../utils";
import SelectClient from "./selectClient";

class ManageClients extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showModal: false,

			allClients: null,
			selected: null,

			clients: (props.value ? props.value : []),
			toAdd: [],
			toDelete: [],
		};

		this.handleOpenModal = this.handleOpenModal.bind(this);
		this.handleCloseModal = this.handleCloseModal.bind(this);
		this.handleAdd = this.handleAdd.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
	}

	handleOpenModal(event) {
		event && event.preventDefault();

		this.setState({showModal: true});
	}

	handleCloseModal(event) {
		event && event.preventDefault();

		this.setState({
			showModal: false,
			selected: null,
		});
	}

	handleAdd() {
		// add only if not already adding and not already on list
		if (this.state.toAdd.indexOf(this.state.selected) === -1 && this.state.clients.indexOf(this.state.selected) === -1) {
			let newAdd = [...this.state.toAdd];
			newAdd.push(this.state.selected);
			this.setState({toAdd: newAdd});
		}

		this.handleCloseModal();
	}

	handleDelete(id) {
		if (this.state.toAdd.indexOf(id) !== -1) { // only remove from to add table
			let newToAdd = [...this.state.toAdd];
			newToAdd.splice(this.state.toAdd.indexOf(id), 1);
			this.setState({toAdd: newToAdd});
		} else {
			if (this.state.toDelete.indexOf(id) === -1) { // add to delete array only once
				let newDelete = [...this.state.toDelete];
				newDelete.push(id);
				this.setState({toDelete: newDelete});
			}
		}

		this.handleCloseModal();
	}

	save(id, callback) {
		let saveAll = this.state.toAdd.map((clientId) => {
			return axios.post(API_URL + '/client/clientService/' + clientId + '/' + id);
		});

		let deleteAll = this.state.toDelete.map((clientId) => {
			return axios.delete(API_URL + '/client/clientService/' + clientId + '/' + id);
		});

		let allActions = saveAll.concat(deleteAll);

		Promise.all(allActions)
			.then(() => {
			if (typeof callback === 'function') {
				callback();
			}
		});
	}

	render() {
		return (
			<span>
				<h3 className="top0 bottom24 displayInlineBlock">Opiekun klienta</h3>
				<button className="left12 plus" onClick={this.handleOpenModal}><i className="fas fa-plus"/></button>

				{this.state.clients.concat(this.state.toAdd).map((id, index) => {

					// skip item to be deleted
					if (this.state.toDelete.indexOf(id) !== -1) {
						return null;
					}

					// dont render if the details are not there
					let item = findObjectWithPropertyValueInArray(this.state.allClients, id);
					if (item === null || item === undefined) {
						return null;
					}

					let color = (item.color ? item.color : 'grey');

					return (
						<div key={"client" + item.id} className="box smallerPadding">
							<div className="floatRight">
								<PopupMenu style={{right: '-12px'}}>
									<li onClick={() => confirmDelete(() => this.handleDelete(item.id))} className="red">Usuń</li>
								</PopupMenu>
							</div>
							<div className="clientDot right4" style={{backgroundColor: color}}>{item.name.charAt(0)}</div> {item.name}
						</div>
					);
				})}

				<ReactModal
					isOpen={this.state.showModal}
					contentLabel=""
					onRequestClose={this.handleCloseModal}
					shouldCloseOnOverlayClick={true}
					className="rcModal withActions"
					overlayClassName="rcOverlay"
				>
					<span onClick={this.handleCloseModal} className="rcModalClose"><i className="fas fa-times"/> Zamknij</span>

					<div className="rcModelContents">
						<h3 className="top0 bottom24">Wyszukaj klienta</h3>
						<SelectClient
							name="clients"
							onChange={(name, value) => this.setState({selected: value})}
						/>
					</div>
					<div className="rcModalActions">
						<button disabled={this.state.selected === null} onClick={this.handleAdd}>Dodaj</button>
					</div>
				</ReactModal>

				{/*<Dump label="clients" value={this.state.clients}/>*/}
				{/*<Dump label="toAdd" value={this.state.toAdd}/>*/}
				{/*<Dump label="toDelete" value={this.state.toDelete}/>*/}
				{/*<Dump label="selected" value={this.state.selected}/>*/}
				{/*<Dump label="allClients" value={this.state.allClients}/>*/}
			</span>
		);
	}

	componentDidMount() {
		axios.get(API_URL + '/client/list').then((response) => {
			if (Array.isArray(response.data.data.list)) {
				//console.log(response.data.data.list);
				this.setState({allClients: response.data.data.list});
			}
		})
	}
}

export default ManageClients
