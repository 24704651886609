import React from 'react'

export default function Sortable(props) {

	function onClick() {
		if (typeof props.onChange === 'function') {
			if (props.value === true) {
				props.onChange(props.index, false);
			} else {
				props.onChange(props.index, true);
			}
		}
	}

	let sort = <i className="fas fa-sort"/>;
	switch (props.value) {
		case true: sort = <i className="fas fa-sort-down"/>; break;
		case false: sort = <i className="fas fa-sort-up"/>; break;
	}

	return (
		<span className="boxSort" onClick={onClick}><span>{sort}{props.children}</span></span>
	);
}
