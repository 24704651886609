import React, { useImperativeHandle, forwardRef, createRef } from 'react';
import {ErrorMessage} from "formik";
import Field from "../form/field";
import SelectClient from "../form/selectClient";
import SelectService from "../form/selectService";
import SelectProjectType from '../form/selectProjectType';
import SelectPerson from "../form/selectPerson";
import DatePicker from "../form/datePicker";
import FileView from "../component/fileView";
import FileUpload from "../component/fileUpload";
import PillStatus from "../component/pillStatus";
import SelectInterval from "../form/selectInterval";
import CheckBox from "../form/checkBox";
import Dump from "../component/dump";
import { isEmptyObject } from '../utils';
import SelectOfferStatus from '../form/selectOfferStatus';
import SelectOfferClient from '../form/selectOfferClient';
import moment from 'moment';

// export default function OfferForm(props) {
const OfferForm = forwardRef( (props, ref) => {

  const selectClientRef = createRef();

  const {values, errors, handleChange, handleBlur, setFieldValue, setFieldTouched, handleShowAddClient, editMode, copyMode, comments} = props;

  const AddNewClient = (pt) => {
    handleShowAddClient(pt);
  }

  useImperativeHandle(ref, () => ({
    updateClient(clientId) {
      // console.log('update client');
      // console.log(clientId);
      selectClientRef.current.updateClientsList();
      setFieldValue('client', clientId);
    }
  }))

  return (
    <div className="box">
      <div className="row with one columns">
        <div className="formItem column">
          <label htmlFor="name">Nazwa oferty</label>
          <Field className="fullWidth" type="text" name="name"/>
          <ErrorMessage component="div" className="errorMessage" name="name"/>
        </div>
      </div>
      {(copyMode) &&
        <div className="row with one columns">
          <div className="formItem column">
            <label>Poprzednia oferta</label>
            <input className="fullWidth" type="text" value={values.previous.name} readOnly="readonly" />
            <Field className="fullWidth" type="hidden" name="previous_id" value={values.previous.id}/>
          </div>
        </div>
      }

      <div className="row with one columns">
        <div className="formItem column">
          <label htmlFor="projectTypes">Usługi której dotyczy oferta</label>
          <SelectProjectType
              name="projectTypes"
              onChange={setFieldValue}
              onBlur={setFieldTouched}
              value={values.projectTypes}
              className="fullWidth"
              mode="multiple"
              showArrow={true}
          />
          <ErrorMessage component="div" className="errorMessage" name="projectTypes"/>
        </div>
      </div>

      <div className="formItem">
        <label htmlFor="description">Opis projektu</label>
        <Field className="fullWidth" component="textarea" name="description" rows="4"/>
        <ErrorMessage component="div" className="errorMessage" name="description"/>
      </div>

      <div className="row with four columns">
        <div className="formItem column">
          <label htmlFor="client">
            Klient
            <button
              type="button"
              className="floatRightxxx"
              title="Dodaj nowegp klienta"
              onClick={() => {
                AddNewClient(values.projectTypes)
              }}
              style={{
                padding: '4px 6px',
                fontSize: '10px',
                minWidth: '10px',
                marginLeft: '10px'
              }}
            >
              <i className="fas fa-plus"/>
            </button>
          </label>
          {!editMode && !copyMode && <>
            <SelectOfferClient
              name="client"
              onChange={setFieldValue}
              onBlur={setFieldTouched}
              value={values.client}
              className="fullWidth"
              ref={selectClientRef}
            />

            <ErrorMessage component="div" className="errorMessage" name="client"/>
          </>}
          {(editMode || copyMode) && <>
            <input className="fullWidth" type="text" value={values.client.name} readOnly="readonly" />
            <Field className="fullWidth" type="hidden" name="client" value={values.client.id}/>
          </>}
        </div>

        <div className="formItem column">
          <label htmlFor="salesPerson">Sprzedawca</label>
          {!editMode && <>
            <SelectPerson
              name="seller"
              onChange={setFieldValue}
              onBlur={setFieldTouched}
              value={values.seller}
              className="fullWidth"
              />
            <ErrorMessage component="div" className="errorMessage" name="seller"/>
          </>}
          {editMode && <>
            <input className="fullWidth" type="text" value={values.seller.name} readOnly="readonly" />
            <Field className="fullWidth" type="hidden" name="seller" value={values.seller.id}/>
          </>}
        </div>

        <div className="formItem column">
          <label htmlFor="submission">Data złożenia oferty klientowi</label>
          <DatePicker
            name="submission"
            onChange={setFieldValue}
            onBlur={setFieldTouched}
            value={values.submission}
            className="fullWidth"
            />
          <ErrorMessage component="div" className="errorMessage" name="submission"/>
        </div>

        <div className="formItem column">
          <label htmlFor="created">Data utworzenia oferty</label>
          <Field className="fullWidth" type="text" name="created" value={values.created} readOnly="readonly" />
          <ErrorMessage component="div" className="errorMessage" name="created"/>
        </div>
      </div>

      {editMode &&
        <>
        <div className="row with four columns">
          <div className="formItem column">
            <label htmlFor="type">Status</label>
            <SelectOfferStatus
              name="status"
              onChange={setFieldValue}
              onBlur={setFieldTouched}
              value={values.status}
              className="fullWidth"
            />
            <ErrorMessage component="div" className="errorMessage" name="status"/>
          </div>
        </div>
        {[1,3,4].includes(values.status) &&
          <div className="formItem">
            <label htmlFor="comment">Komentarz</label>
            <Field className="fullWidth" component="textarea" name="comment" rows="4"/>
            <ErrorMessage component="div" className="errorMessage" name="comment"/>
          </div>
        }


        {comments.length > 0 &&
          <div className="formItem">
            <label htmlFor="description">Komentarze</label>
            {comments && comments.map((comment, index) => {
              const created = moment(comment.created).format('YYYY-MM-DD');
              return (
                <div key={comment.id}>
                  <span>{created} - {comment.status}</span>
                  <textarea className="fullWidth" readOnly="readonly" rows="4" value={comment.comment} />
                </div>
              )
            })}
          </div>
        }

        </>
      }

      <div className="row with four columns">
        {values.files && values.files.map(function(file, index) {
          return (
            <div className="formItem column" key={file.id}>
              {(index === 0) ? <label>Załączone pliki (np. instrukcje)</label> : <label>&nbsp;</label>}
              <FileView fileId={file.id} path={file.path} handleDelete={() => props.handleFileDelete(file.id, values.files, setFieldValue)}/>
            </div>
          );
        })}
        {props.uploadedFiles.map(function(file) {
          return (
            <div className="formItem column" key={file.id}>
              <label>&nbsp;</label>
              <FileView fileId={file.id} path={file.path} handleDelete={() => props.handleFileDelete(file.id, values.files, setFieldValue)}/>
            </div>
          )
        })}
      </div>

      <FileUpload handleFile={props.handleFileAdd}/>
      <ErrorMessage component="div" className="errorMessage" name="files"/>

      {/* <Dump label="errors" value={errors}/> */}
      {/* <Dump label="values" value={values}/> */}
      {/*<Dump label="formik" value={props}/>*/}
    </div>
  )
});

export default OfferForm;

export function validateOffer(values) {
  let errors = {};
  if (values.projectTypes.length < 1) {
    errors.projectTypes = 'Usługa jest wymagana';
  }
  if (!values.client) {
    errors.client = 'Klient jest wymagany';
  }
  if (!values.seller) {
    errors.seller = 'Sprzedawca jest wymagany';
  }
  if (!values.name) {
    errors.name = 'Nazwa jest wymagana';
  }

  // if (!values.files.length) {
	// 	errors.files = 'Plik jest wymagany';
	// }
  console.log(values);
  return errors;
}
