import React, {useEffect} from 'react';
import Select from "./select";
import {Option} from "rc-select";
import {connect, useDispatch} from "react-redux";
import {actionFetchAgreementTypes} from "../state/actionsAgreementTypes";

const mapStateToProps = function(state) {
	return {
		agreementTypes: state.agreementTypes.list,
		loading: state.agreementTypes.loading,
	};
};

function SelectAgreementTypes(props) {
	const dispatcher = useDispatch();

	useEffect(() => {
		if (props.loading === false && (props.agreementTypes == null || props.agreementTypes.length === 0)) {
			dispatcher(actionFetchAgreementTypes());
		}
	}, []);

	let {agreementTypes, dispatch, ...otherProps} = props;
	let data = props.agreementTypes;

	return (
		<Select
			loading={props.loading}
			placeholder="Wybierz typ zgody"
			{...otherProps}
		>
			{data && data.map(function(item) {
				let label = (<div><span className="colorDot small" style={{backgroundColor: item.color}}/>{item.name}</div>);
				return (<Option value={parseInt(item.id)} key={'position' + item.id} label={label}>{label}</Option>);
			})}
		</Select>
	);
}

export default connect(mapStateToProps)(SelectAgreementTypes);