import {FETCH_SERVICES_BEGIN, FETCH_SERVICES_SUCCESS, FETCH_SERVICES_FAILURE} from './actionsServices';

const reducerServices = (state = {list: [], loading: false}, action) => {

	switch (action.type) {
		case FETCH_SERVICES_BEGIN:
			return {
				...state,
				loading: true,
				error: null // also, reset any errors
			};

		case FETCH_SERVICES_SUCCESS:
			// replace the items with the ones from the server
			return {
				...state,
				loading: false,
				list: action.list,
			};

		case FETCH_SERVICES_FAILURE:
			// save the error
			return {
				...state,
				error: action.error,
				loading: false,
				list: []
			};

		default:
			return state
	}
};

export default reducerServices