import React from "react";
import {ErrorMessage} from "formik";
import Field from "../form/field";
import axios from "axios";
import SelectPosition from "../form/selectPosition";

export default function ClientPerson(props) {

	const {index, name, values, setFieldValue, setFieldTouched, arrayHelpers} = props;

	const prefix = name + '.' + index;

	function handleClose(event) {
		event.preventDefault();

		if (props.data.hasOwnProperty('id')) {
			axios.delete(API_URL + '/clientPerson/' + props.data.id)
				.then(function(response) {
					arrayHelpers.remove(index);
				});
		} else {
			arrayHelpers.remove(index);
		}
	}

	return (
		<div className="box">
			<a href="#" className="floatRight red font24" onClick={handleClose}><i className="fas fa-times"/></a>

			<h3 className="top0 bottom32">Osoba kontaktowa</h3>
			<div className="row with four columns">
				<div className="column">
					<div className="formItem">
						<label htmlFor={`${prefix}.firstName`} className="bottom12">Imię</label>
						<Field className="fullWidth" type="text" name={`${prefix}.firstName`}/>
						<ErrorMessage component="div" className="errorMessage" name={`${prefix}.firstName`}/>
					</div>
				</div>

				<div className="column">
					<div className="formItem">
						<label htmlFor={`${prefix}.lastName`} className="bottom12">Nazwisko</label>
						<Field className="fullWidth" type="text" name={`${prefix}.lastName`}/>
						<ErrorMessage component="div" className="errorMessage" name={`${prefix}.lastName`}/>
					</div>
				</div>

				<div className="column">
					<div className="formItem">
						<label htmlFor={`${prefix}.position`}>Stanowisko</label>
						<SelectPosition
							className="fullWidth"
							name={`${prefix}.position`}
							onChange={setFieldValue}
							onBlur={setFieldTouched}
							value={values[name][index]['position']}
						/>
						<ErrorMessage component="div" className="errorMessage" name={`${prefix}.position`}/>
					</div>
				</div>

				<div className="column"/>

				<div className="column">
					<div className="formItem">
						<label htmlFor={`${prefix}.phone`} className="bottom12">Numer telefonu</label>
						<Field className="fullWidth" type="tel" name={`${prefix}.phone`}/>
						<ErrorMessage component="div" className="errorMessage" name={`${prefix}.phone`}/>
					</div>
				</div>

				<div className="column">
					<div className="formItem">
						<label htmlFor={`${prefix}.email`} className="bottom12">Adres e-mail</label>
						<Field className="fullWidth" type="email" name={`${prefix}.email`}/>
						<ErrorMessage component="div" className="errorMessage" name={`${prefix}.email`}/>
					</div>
				</div>
			</div>

			<div className="formItem">
				<label htmlFor={`${prefix}.description`}>Opis</label>
				<Field className="fullWidth" component="textarea" name={`${prefix}.description`} rows="4"/>
				<ErrorMessage component="div" className="errorMessage" name={`${prefix}.description`}/>
			</div>
			{/*<Dump value={prefix} />*/}
			{/*<Dump value={values} />*/}
		</div>
	);
}
