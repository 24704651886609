import axios from "axios";

export const FETCH_PROVINCES_BEGIN = 'FETCH_PROVINCES_BEGIN';
export const FETCH_PROVINCES_SUCCESS = 'FETCH_PROVINCES_SUCCESS';
export const FETCH_PROVINCES_FAILURE = 'FETCH_PROVINCES_FAILURE';

export function actionFetchProvincesBegin() {
	return {
		type: FETCH_PROVINCES_BEGIN
	};
}

export function actionFetchProvincesSuccess(data) {
	return {
		type: FETCH_PROVINCES_SUCCESS,
		list: data
	};
}

export function actionFetchProvincesFailure(error) {
	console.log('Error ', error);
	return {
		type: FETCH_PROVINCES_FAILURE,
		error: error
	};
}

export function actionFetchProvinces() {
	return dispatch => {
		dispatch(actionFetchProvincesBegin());

		return axios.get(API_URL + '/common/provinces')
			.then(function(response) {
				if (Array.isArray(response.data.data.list)) {
					dispatch(actionFetchProvincesSuccess(response.data.data.list));
				} else {
					dispatch(actionFetchProvincesFailure());
				}
			}).catch(function(error) {
				dispatch(actionFetchProvincesFailure(error));
			});
	};
}