import React from "react"
import {addDays, endOfMonth, endOfWeek, format, isSameMonth, startOfMonth, startOfWeek, getDate} from 'date-fns';
import CalendarMonthlyWeek from "./calendarMonthlyWeek";
import {getDayName} from "../../utils";

export default function CalendarMonthly(props) {

	const prefixCss = 'calendarMonthly';

	let currentMonth = props.currentMonth;
	if (!(currentMonth instanceof Date)) {
		currentMonth = new Date();
	}

	let data = props.value;
	if (typeof data === 'undefined') {
		data = {};
	}

	function renderHeader() {
		const dateFormat = "i";
		const toReturn = [];

		let startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });

		for (let i = 0; i < 7; i++) {
			toReturn.push(
				<div className={prefixCss + "-header-cell"} key={i}>
					{getDayName(format(addDays(startDate, i), dateFormat))}
				</div>
			);
		}

		return <div className={prefixCss + "-row"}>{toReturn}</div>;
	}

	function renderCells() {
		const monthStart = startOfMonth(currentMonth);
		const monthEnd = endOfMonth(monthStart);
		const startDate = startOfWeek(monthStart, { weekStartsOn: 1 });
		const endDate = endOfWeek(monthEnd, { weekStartsOn: 1 });

		let rows = [];
		let day = startDate;

		let weekData = [];
		while (day <= endDate) {
			for (let i = 1; i <= 7; i++) {
				if (isSameMonth(day, monthStart) && data.hasOwnProperty(getDate(day))) {
					data[getDate(day)].map(function(item) {
						weekData.push(item);
					});
				}

				day = addDays(day, 1);
			}

			//rows.push(<Dump value={weekData}/>);
			rows.push(
				<CalendarMonthlyWeek
					key={'week' + getDate(day)}
					value={weekData}
					prefixCss={prefixCss}
					startOfWeek={startOfWeek(addDays(day, -1), { weekStartsOn: 1 })}
					endOfWeek={endOfWeek(addDays(day, -1), { weekStartsOn: 1 })}
					selectedDate={props.selectedDate}
					onDateChange={props.onDateChange}
				/>
			);
			weekData = [];
		}

		return rows;
	}

	return (
		<div className={prefixCss}>
			{renderHeader()}
			{renderCells()}
			{/*<Dump value={data}/>*/}
		</div>
	);
}
