import React, {useEffect, useState} from "react"
import {connect, useDispatch} from "react-redux";
import {actionFetchKnowledgeTypes} from "../state/actionsKnowledgeTypes";

const mapStateToProps = function(state) {
	return {
		knowledgeTypes: state.knowledgeTypes.list
	};
};

function FilterKnowledgeTypes(props) {
	const [selected, setSelected] = useState(null);
	const dispatch = useDispatch();

	useEffect(() => {
		if (props.knowledgeTypes.length === 0) {
			dispatch(actionFetchKnowledgeTypes());
		}
	}, []);

	function handleClick(value) {
		setSelected(value);

		if (props.hasOwnProperty('onChange') && 'function' === typeof props.onChange) {
			props.onChange(value);
		}
	}

	return (
		<div className="filter bottom40">
			<button className={"pill right8 " + (selected === null ? 'grey' : 'transparent')} onClick={() => handleClick(null)}>
				Wszystko
			</button>
			{props.knowledgeTypes.map(function(item) {
				return (
					<button
						className={"pill right8 " + (selected === item.id ? 'grey' : 'transparent')}
						key={'knowledgeType' + item.id}
						onClick={() => handleClick(item.id)}
					>
						{item.name}
					</button>
				);
			})}
		</div>
	);
}

export default connect(mapStateToProps)(FilterKnowledgeTypes);