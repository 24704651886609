import React from "react"
import {format, isSaturday, isSunday} from 'date-fns';

export default function CalendarMonthlyCell(props) {

	const prefixCss = props.prefixCss;
	const dateFormat = "d";

	let isWeekend = false;
	if (isSaturday(props.day) || isSunday(props.day)) {
		isWeekend = true;
	}

	return (
		<div
			className={`${prefixCss}-cell ${props.disabled ? (prefixCss + "-disabled") : ''} ${isWeekend ? 'weekend' : ''}`}
			key={format(props.day, 'yyyyMMdd')}
			onClick={function() { if (typeof props.onDateClick === 'function') { props.onDateClick(props.day); }}}
		>
			<span className={`${prefixCss}-number${(props.selected ? " selected" : "")}`}>{format(props.day, dateFormat)}</span>

			{props.value && Array.isArray(props.value) && props.value.map(function(item) {
				return (
					<div
						className="calendarMonthlyEntry"
						key={'calMonthlyEntry' + item.id}
						style={{backgroundColor: (item.type && item.type.color) ? item.type.color : '#666'}}
					>
						{item.type && item.type.name}
					</div>
				);
			})}
		</div>
	);
}
