import {FETCH_NOTIFICATION_COUNT_BEGIN, FETCH_NOTIFICATION_COUNT_SUCCESS, FETCH_NOTIFICATION_COUNT_FAILURE} from './actionsNotifications';

const reducerNotificationCount = (state = {count: null, loading: false}, action) => {

	switch (action.type) {
		case FETCH_NOTIFICATION_COUNT_BEGIN:
			return {
				...state,
				loading: true,
				error: null, // also, reset any errors
			};

		case FETCH_NOTIFICATION_COUNT_SUCCESS:
			// replace the items with the ones from the server
			return {
				...state,
				loading: false,
				count: action.count,
			};

		case FETCH_NOTIFICATION_COUNT_FAILURE:
			// save the error
			return {
				...state,
				error: action.error,
				loading: false,
				count: null,
			};

		default:
			return state
	}
};

export default reducerNotificationCount