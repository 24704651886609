import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Form, Formik} from "formik";
import {findIndexOfObjectWithPropertyValueInArray, isEmptyObject, objectClone} from "../../utils";
import AdminTaskForm, {validateTask} from "./adminTaskForm";
import Spinner from "../../component/spinner";
import {formikErrorHelper} from "../../form/formUtils";

export default function AdminTaskEdit(props) {

	const [task, setTask] = useState({});
	const [uploadedFiles, setUploadedFiles] = useState([]);

	useEffect(() => {
		async function fetchTask() {
			const response = await axios(API_URL + '/definition/task/' + props.taskId);
			//console.log(response.data.data);
			if (response.data.data.task) {
				setTask(response.data.data.task);
			}
		}

		fetchTask();
	}, [props.taskId]);

	function handleFileAdd(uploadedFile) {
		setUploadedFiles([...uploadedFiles, uploadedFile]);
	}

	function handleFileDelete(fileId, valueFiles, setFieldValue) {
		let index = findIndexOfObjectWithPropertyValueInArray(uploadedFiles, fileId);

		if (index !== null && index !== -1) {
			let newUploadedFiles = [...uploadedFiles];
			newUploadedFiles.splice(index, 1);
			setUploadedFiles(newUploadedFiles);
		} else {
			let index = findIndexOfObjectWithPropertyValueInArray(valueFiles, fileId);
			if (index !== null && index !== -1) {
				axios.delete(API_URL + '/definition/task/file/' + task.id + '/' + fileId)
					.then(function() {
						valueFiles.splice(index, 1);
						setFieldValue('files', valueFiles);
					});
			}
		}
	}

	function addFiles(uploadedFiles, taskId) {
		let promisesArray = [];

		if (uploadedFiles && Array.isArray(uploadedFiles) && uploadedFiles.length > 0) {
			uploadedFiles.map((item) => {
				promisesArray.push(
					axios.post(API_URL + '/definition/task/file/' + taskId + '/' + item.id)
				);
			});
		}

		return promisesArray;
	}

	function handleSubmit(values, bag) {
		let toSend = {};
		Object.keys(values).map(function(item) {
			if (task[item] !== values[item]) {
				toSend[item] = values[item];
			}
		});

		delete toSend.userModified;
		delete toSend.files;

		if (toSend.hasOwnProperty('type')) {
			toSend['type'] = parseInt(toSend['type']);
		}
		if (toSend.hasOwnProperty('department')) {
			toSend['department'] = parseInt(toSend['department']);
		}

		let promises = [];
		promises.concat(addFiles(uploadedFiles, props.taskId));

		Promise.all(promises).then(() => {
			axios.patch(API_URL + '/definition/task/' + props.taskId, toSend)
				.then(function(response) {
					bag.setSubmitting(false);
					//console.log(response);
					if (typeof props.handleRefresh === 'function') {
						props.handleRefresh();
					}
					if (typeof props.handleClose === 'function') {
						props.handleClose();
					}
				}).catch(function(error) {
					formikErrorHelper(error, bag);
				});
		});

		//console.log(toSave);
	}

	return (isEmptyObject(task) ? <div className="textCenter top96"><Spinner/></div> : renderFormik());

	function renderFormik() {
		let initial = objectClone(task);
		delete initial.id;
		delete initial.created;
		delete initial.modified;
		delete initial.userModified;

		if (initial.hasOwnProperty('type') && initial.type.hasOwnProperty('id')) {
			initial.type = initial.type.id;
		}

		if (initial.hasOwnProperty('department') && initial.department.hasOwnProperty('id')) {
			initial.department = initial.department.id;
		}

		if (initial.hasOwnProperty('userModified') && initial.userModified.hasOwnProperty('name')) {
			initial.userModified = initial.userModified.name;
		}

		return (
			<Formik
				initialValues={initial}
				validate={validateTask}
				onSubmit={handleSubmit}
			>
				{(propsFormik) => {
					const {values, touched, errors, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched} = propsFormik;

					return (
						<Form>
							<div className="rcModelContents">
								<h3 className="top0 bottom24">Edycja zadania</h3>

								<AdminTaskForm
									{...propsFormik}
									task={task}
									showFieldsModified={true}
									handleFileAdd={handleFileAdd}
									handleFileDelete={handleFileDelete}
									uploadedFiles={uploadedFiles}
								/>

								<div className="rcModalActions">
									<button type="submit" disabled={isSubmitting}>Zapisz</button>
								</div>
							</div>
						</Form>
					)
				}}
			</Formik>
		);
	}
}
