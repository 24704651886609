import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {findObjectWithPropertyValueInArray, findObjectWithPropertyValueInAssocArray, formatDate, isEmptyObject, objectClone} from "../utils";
import Spinner from "../component/spinner";
import {parseISO} from "date-fns";
import ReactModal from "react-modal";
import PopupMenu from "../component/popupMenu";
import TaskView from "../task/taskView";
import FileView from "../component/fileView";
import {getDepartmentFromService, getProjectDate, getProjectStatusId, getProjectTypeFromService} from "./projectUtils";
import {connect, useDispatch} from "react-redux";
import {actionFetchTaskStatuses} from "../state/actionsTaskStatuses";
import PillStatus from "../component/pillStatus";
import {actionFetchIntervals} from "../state/actionsIntervals";
import CheckBox from "../form/checkBox";

const mapStateToProps = function(state) {
	return {
		taskStatuses: state.taskStatuses.list,
		taskStatusesLoading: state.taskStatuses.loading,
		intervals: state.intervals.list,
		intervalsLoading: state.intervals.loading,
	};
};

function ProjectView(props) {
	const dispatch = useDispatch();
	const [project, setProject] = useState({});
	const [showModalTaskView, setShowModalTaskView] = useState(false);
	const [taskId, setTaskId] = useState(null);
	const [procedureId, setProcedureId] = useState(null);

	useEffect(() => {
		if (props.taskStatusesLoading === false && (props.taskStatuses == null || props.taskStatuses.length === 0)) {
			dispatch(actionFetchTaskStatuses());
		}
	}, []);

	useEffect(() => {
		if (props.intervalsLoading === false && (props.intervals == null || props.intervals.length === 0)) {
			dispatch(actionFetchIntervals());
		}
	}, []);

	useEffect(() => {
		fetchProject();
	}, [props.projectId]);

	async function fetchProject() {
		const response = await axios(API_URL + '/project/' + props.projectId);

		let data = response.data.data.project;
		if (data) {
			let foo = objectClone(data);

			if (Array.isArray(foo.services) && foo.services.length > 0) {
				foo.service = foo.services.pop();
				delete foo.services;
			}

			if (foo.hasOwnProperty('unitQuantity') === false) {
				foo.unitQuantity = 1;
			}
			if (foo.hasOwnProperty('recurrent')) {
				foo.recurrent = parseInt(foo.recurrent);
			}

			setProject(foo);
		}
	}

	function renderType(type) {
		if (type && type.hasOwnProperty('name')) {
			return type.name;
		}

		return '';
	}

	function handleCloseModalTaskView() {
		setShowModalTaskView(false);
		fetchProject();
	}

	function handleOpenModalTaskView(taskId, procedureId) {
		setTaskId(taskId);
		setProcedureId(procedureId);
		setShowModalTaskView(true);
	}

	return (isEmptyObject(project) ? <div className="textCenter top96"><Spinner/></div> : renderForm());

	function getStatusObject(statusId) {
		let status = findObjectWithPropertyValueInArray(props.taskStatuses, statusId);
		if (status && status.hasOwnProperty('id') && status.hasOwnProperty('name')) {
			return status;
		}
		return null;
	}

	function renderEndDate(statusId) {
		if (statusId === 9 || statusId === 10) {
			return (
				<div className="formItem column">
					<label htmlFor="type">Data zakończenia</label>
					{/* <input type="text" readOnly value={formatDate(getProjectDate(project))} className="fullWidth"/> */}
					<input type="text" readOnly value={formatDate(parseISO(project.jobsEnded))} className="fullWidth"/>
				</div>
			);
		}

		return '';
	}

	function renderCompletedDate(statusId) {
		if (statusId === 9 || statusId === 10) {
			return (
				<div className="formItem column">
					<label htmlFor="type">Data zrealizowania</label>
					{/* <input type="text" readOnly value={formatDate(getProjectDate(project))} className="fullWidth"/> */}
					<input type="text" readOnly value={formatDate(parseISO(project.completed))} className="fullWidth"/>
				</div>
			);
		}

		return '';
	}

	function renderForm() {
		let statusId = getProjectStatusId(project);
		let department = getDepartmentFromService(project.service);

		let interval = findObjectWithPropertyValueInAssocArray(props.intervals, project.recurrent);
		if (interval && interval.hasOwnProperty('name')) {
			interval = interval.name;
		}

		return (
			<div className="rcModelContents">
				<h3 className="top0 bottom24">
					{project.name}
					<span className="font14 displayInlineBlock left24 valignTop">
						<PillStatus value={project.status}/>
					</span>
				</h3>

				<div className="row with four columns">
					<div className="formItem column">
						<label>Klient</label>
						<input type="text" readOnly value={project.client ? project.client.name : ''} className="fullWidth"/>
					</div>

					<div className="formItem column">
						<label>Spółka</label>
						<input type="text" readOnly value={project.company ? project.company.name : ''} className="fullWidth"/>
					</div>

					<div className="formItem column">
						<label>Sprzedawca</label>
						<input type="text" readOnly value={project.seller ? project.seller.name : ''} className="fullWidth"/>
					</div>

					<div className="formItem column">
						<label>Typ usługi</label>
						<input type="text" readOnly value={renderType(getProjectTypeFromService(project.service))} className="fullWidth"/>
					</div>
				</div>

				<div className="row with four columns">
					<div className="formItem column">
						<label>Dział</label>
						<input type="text" readOnly value={department ? department.name : ''} className="fullWidth"/>
					</div>
					<div className="formItem column">
						<label>Ilość jednostkowa</label>
						<input type="text" readOnly value={project.unitQuantity} className="fullWidth"/>
					</div>

					<div className="formItem column">
						<label>Wartość jednostkowa</label>
						<input type="text" readOnly value={project.unitValue} className="fullWidth"/>
					</div>

					<div className="formItem column">
						<label>Planowana wartość (PLN)</label>
						<input type="text" readOnly value={project.unitQuantity * project.unitValue} className="fullWidth"/>
					</div>
				</div>

				<div className="row with four columns">
					<div className="formItem column">
						<label>Powtarza się</label>
						<input type="text" readOnly value={interval ? interval : ''} className="fullWidth"/>
					</div>

					{project.recurrent !== 0 ? '' :
						<div className="formItem column">
							<label>Planowana data wykonania</label>
							<input type="text" readOnly value={formatDate(parseISO(project.implementationDate))} className="fullWidth"/>
						</div>
					}

					{renderEndDate(statusId)}
					{renderCompletedDate(statusId)}
				</div>

				<div className="formItem">
					<label>Opis projektu</label>
					<textarea className="fullWidth" value={project.description} rows="4" readOnly/>
				</div>

				<div className="row with four columns">
					{project.files && project.files.map(function(file, index) {
						return (
							<div className="formItem column" key={file.id}>
								{(index === 0) ? <label>Załączone pliki (np. instrukcje)</label> : <label>&nbsp;</label>}
								<FileView fileId={file.id} path={file.path}/>
							</div>
						);
					})}
				</div>

				<div className="row with two columns">
					<div className="formItem column">
						<label>Usługa realizowana w Projekcie</label>
						<input type="text" readOnly value={project.service && project.service.hasOwnProperty('name') ? project.service.name : ''}/>
					</div>
				</div>

				{/*<Dump value={props.taskStatuses}/>*/}

				<h3 className="bottom24">Przydzielone zadania</h3>
				{renderTasks()}

				<div className="rcModalActions">
					<button
						className="left32"
						onClick={(event) => {
							event.preventDefault();
							if (typeof props.handleClose === 'function') {
								props.handleClose();
							}
						}}
					>
						Zamknij
					</button>
				</div>
			</div>
		);
	}

	function renderTasks() {
		return (
			<>
				{project.service && project.service.procedures.map(function(procedure) {
					return (
						<span key={procedure.id}>
							<h4 className="bottom12">{procedure.name}</h4>

							<div className="boxTable">
								<div className="boxHeader">
									<div className="boxCell">Lp.</div>
									<div className="boxCell">Nazwa zadania</div>
									<div className="boxCell">Przydzielona<br/>osoba</div>
									<div className="boxCell">Data zakończenia<br/>zadania </div>
									<div className="boxCell">Graniczna data<br/>wykonania</div>
									<div className="boxCell">Data utworzenia<br/>projektu</div>
									<div className="boxCell">Status</div>
									<div className="boxCell"/>
								</div>

								{procedure.tasks.map(function(task, index) {
									return [
										<div className="boxRow withBorder" key={"task" + task.id}>
											<div className="boxCell">{index + 1}</div>
											<div className="boxCell">{task.name}</div>
											<div className="boxCell">
												{task.user && task.user.hasOwnProperty('name')? task.user.name : ''}
											</div>
											<div className="boxCell">
												{task.dateEndJob ? formatDate(new Date(task.dateEndJob)) : ''}
											</div>
											<div className="boxCell">
												<CheckBox checked={task.finalDate}/>
												{task.dateRealizationLimit ? formatDate(new Date(task.dateRealizationLimit)) : ''}
											</div>
											<div className="boxCell">
												{project.created ? formatDate(new Date(project.created)) : ''}
											</div>
											<div className="boxCell"><PillStatus value={task.status}/></div>

											<div className="boxCell textRight padRight8">
												<PopupMenu>
													<li onClick={() => handleOpenModalTaskView(task.id, procedure.id)}>Zobacz szczegóły</li>
												</PopupMenu>
											</div>
										</div>,
										<div className="boxRow spacer" key={"taskSpacer" + task.id}/>
									];
								})}
							</div>
						</span>
					);
				})}

				<ReactModal
					isOpen={showModalTaskView}
					contentLabel=""
					onRequestClose={handleCloseModalTaskView}
					shouldCloseOnOverlayClick={true}
					className="rcModal withActions bigger"
					overlayClassName="rcOverlay"
				>
					<span onClick={handleCloseModalTaskView} className="rcModalClose"><i className="fas fa-times"/> Zamknij</span>

					<TaskView
						handleClose={handleCloseModalTaskView}
						projectId={props.projectId}
						taskId={taskId}
						serviceId={(project.service && project.service.hasOwnProperty('id')) ? project.service.id : null}
						procedureId={procedureId}
					/>
				</ReactModal>
			</>
		);
	}
}

export default connect(mapStateToProps)(ProjectView);
