import React, {useState} from "react"
import {addDays, endOfMonth, format, isSameDay, startOfMonth, isSunday, isSaturday} from 'date-fns'
import Spinner from "../spinner";
import Avatar from "../avatar";

export default function CalendarAbsence(props) {

	const prefixCss = 'calendarAbsence';
	const prefixEntryCss = 'calendarAbsenceEntry';

	let data = props.value;
	let currentDate = props.currentDate;
	let [selectedDate, setSelectedDate] = useState(new Date());

	function onDateClick(day) {
		setSelectedDate(day);

		// console.log(day);
		// console.log(format(day, 'd'));
	}

	return (
		<div className={prefixCss}>
			{renderMonthHeader()}

			{data && data.map(function(item) {
				return (
					<div className={prefixCss + '-row'} key={item.user.id}>
						<div className={prefixCss + '-cell' + ' ' + prefixCss + '-user'}>
							<Avatar picture={item.user.picture}/>
							&nbsp;{item.user.firstName} {item.user.lastName}
						</div>

						{renderCells(item.monthlyData)}
					</div>
				);
			})}

			{(data === null) ? <div className="textCenter"><Spinner/></div> : null}
		</div>
	);

	function renderMonthHeader() {
		const dateFormat = "d";
		const toReturn = [<div className={`${prefixCss}-header-cell ${prefixCss}-user`} key={-1}>Pracownik</div>];

		let date = startOfMonth(currentDate);

		for (let i = 0; date < endOfMonth(currentDate); i++) {
			toReturn.push(
				<div className={`${prefixCss}-header-cell`} key={i}>
					<span>{format(date, dateFormat)}</span>
				</div>
			);

			date = addDays(date, 1)
		}

		return <div className={`${prefixCss}-row`}>{toReturn}</div>;
	}

	function renderCells(monthlyData) {
		//console.log(monthlyData);

		let toReturn = [];

		const startDate = startOfMonth(currentDate);
		const endDate = endOfMonth(currentDate);

		let day = startDate;
		while (day <= endDate) {
			const cloneDay = day;

			let contents = '';

			monthlyData.map(function(item) {
				let type = prefixEntryCss;

				//console.log(item.dateFrom);
				//console.log(isSameDay(day, new Date(item.dateFrom)));

				if (isSameDay(day, new Date(item.dateFrom))) {
					type += '-start';
				}

				if (isSameDay(day, new Date(item.dateTo))) {
					type += '-end';
				}

				if (type === prefixEntryCss && (day > new Date(item.dateFrom) && day < new Date(item.dateTo))) {
					type = prefixEntryCss + '-middle';
				}

				if (type !== prefixEntryCss) {
					contents = renderCellContents(item, type);
				}
			});

			let cssClass = prefixCss + '-cell';

			if (isSameDay(day, selectedDate)) {
				cssClass += ' ' + prefixCss + "-selected";
			}

			if (isSaturday(day) || isSunday(day)) {
				cssClass += ' ' + prefixCss + "-weekend";
			}

			toReturn.push(
				<div
					className={cssClass}
					key={format(day, 'yyyyMMdd')}
					onClick={() => onDateClick(cloneDay)}
				>
					{contents}
				</div>
			);

			day = addDays(day, 1);
		}

		return toReturn;
	}

	function renderCellContents(item, type = '') {
		return (
			<div className={`${prefixEntryCss} ${type}`}
	            style={{ backgroundColor: item.type.color }}
				key={type + format(new Date(item.dateFrom), 'yyyyMMdd')}
				onClick={() => props.handleOpenModalEdit(item)}
			/>
		);
	}
}
