import React from 'react';
import {ErrorMessage} from "formik";
import Field from "../form/field";
import SelectClient from "../form/selectClient";
import SelectPerson from "../form/selectPerson";
import DateTimePicker from "../form/dateTimePicker";

export default function GuestForm(props) {

	const {values, touched, errors, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, handleReset, setFieldValue, setFieldTouched} = props;

	return (
		<>
			<div className="formItem">
				<label htmlFor="name">Imię i nazwisko</label>
				<Field className="fullWidth" type="text" name="fullName"/>
				<ErrorMessage component="div" className="errorMessage" name="fullName"/>
			</div>

			<h4>Wyszukaj firmę w bazie Klientów lub wprowadź nową firmę:</h4>

			<div className="row with two columns">

				<div className="formItem column">
					<label htmlFor="client">Firma</label>
					<SelectClient
						className="fullWidth"
						name="client"
						onChange={setFieldValue}
						onBlur={setFieldTouched}
						value={values.client}
						allowClear={true}
						disabled={values.clientName}
						placeholder="Szukaj"
					/>
					<ErrorMessage component="div" className="errorMessage" name="client"/>
				</div>

				<div className="formItem column">
					<label htmlFor="clientName">Nazwa firmy</label>
					<Field className="fullWidth" type="text" name="clientName" disabled={values.client != null}/>
					<ErrorMessage component="div" className="errorMessage" name="clientName"/>
				</div>

				<div className="formItem column">
					<label htmlFor="implementationDate">Data i godzina wejścia</label>
					<DateTimePicker
						name="operationDate"
						onChange={setFieldValue}
						onBlur={setFieldTouched}
						value={values.operationDate}
						className="fullWidth"
					/>
					<ErrorMessage component="div" className="errorMessage" name="operationDate"/>
				</div>

				<div className="formItem column">
					<label htmlFor="province">Do kogo</label>
					<SelectPerson
						className="fullWidth"
						name="user"
						onChange={setFieldValue}
						onBlur={setFieldTouched}
						value={values.user}
					/>
					<ErrorMessage component="div" className="errorMessage" name="user"/>
				</div>
			</div>

			<div className="formItem">
				<label htmlFor="description">Notatka</label>
				<Field className="fullWidth" component="textarea" name="description" rows="4"/>
				<ErrorMessage component="div" className="errorMessage" name="description"/>
			</div>
		</>
	)
}

export function validateGuest(values) {
	let errors = {};

	if (!values.fullName) {
		errors.fullName = 'Imię i nazwisko jest wymagane';
	}

	if (values.clientName == "" && !values.client) {
		errors.client = 'Firma jest wymagana';
	}
	if (values.client === null && values.clientName == "") {
		errors.clientName = 'Nazwa firmy jest wymagana';
	}

	if (!values.user) {
		errors.user = 'Do kogo jest wymagane';
	}

	return errors;
}