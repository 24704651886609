import React, {useState} from 'react';
import axios from "axios";

export default function CommentEdit(props) {
	const [comment, setComment] = useState(props.text);
	const [error, setError] = useState("");
	const [isSubmitting, setIsSubmitting] = useState(false);

	function handleSubmit(event) {
		event.preventDefault();

		let toSave = {text: comment};

		if (validate(comment)) {
			setIsSubmitting(true);

			axios.patch(API_URL + '/comment/' + props.commentId, toSave)
				.then(function(response) {
					let newId = response.data.id;
					if (newId) {
						setError('');
						setComment('');

						if (typeof props.handleAdd === 'function') {
							props.handleAdd(newId);
						}
					}
				}).catch(function(error) {
				setError(error);

			}).finally(function() {
				setIsSubmitting(false);
				if (typeof props.handleEditFinished === 'function') {
					props.handleEditFinished(comment);
				}
			});
		}
	}

	function validate(comment) {
		if (comment.length === 0) {
			setError('Pole komentarz nie może być puste');
			return false;
		} else {
			setError(null);
			return true;
		}
	}

	function onChange(event) {
		setComment(event.target.value);
		validate(event.target.value);
	}

	return (
		<>
			<div className="formItem">
				<textarea className="fullWidth"
					name="comment"
					rows="2"
					value={comment}
					onChange={onChange}
				/>
				<div className="errorMessage floatLeft">{error}</div>

				<button className="floatRight top8" disabled={isSubmitting} onClick={handleSubmit}>Zapisz zmiany</button>
			</div>

			{/*<Dump value={comment} />*/}
			{/*{<Dump value={error} />}*/}
			{/*{<Dump value={isSubmitting} />}*/}
		</>
	);
}
