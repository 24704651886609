import React, {useEffect} from 'react';
import Select from "./select";
import {Option} from "rc-select";
import {connect, useDispatch} from "react-redux";
import {actionFetchCalendarTypes} from "../state/actionsCalendarTypes";

const mapStateToProps = function(state) {
	return {
		types: state.calendarTypes.list,
		vacations: state.calendarTypes.listVacation,
		loading: state.calendarTypes.loading,
	};
};

function SelectCalendarType(props) {
	const dispatcher = useDispatch();

	useEffect(() => {
		if (props.loading === false && (props.types == null || props.types.length === 0 || props.vacations == null || props.vacations.length === 0)) {
			dispatcher(actionFetchCalendarTypes());
		}
	}, []);

	let {types, vacations, dispatch, ...otherProps} = props;
	let data = props.vacations;

	return (
		<Select
			{...otherProps}
			loading={props.loading}
			optionLabelProp="label"
		>
			{data && data.map(function(item) {
				let label = (<div><span className="colorDot small" style={{backgroundColor: item.color}}/>{item.name}</div>);
				return (<Option value={parseInt(item.id)} key={'optionCalendarType' + item.id} label={label}>{label}</Option>);
			})}
		>
		</Select>
	);
}

export default connect(mapStateToProps)(SelectCalendarType);