import axios from "axios";

export const FETCH_TAGS_BEGIN = 'FETCH_TAGS_BEGIN';
export const FETCH_TAGS_SUCCESS = 'FETCH_TAGS_SUCCESS';
export const FETCH_TAGS_FAILURE = 'FETCH_TAGS_FAILURE';

export function actionFetchTagsBegin() {
	return {
		type: FETCH_TAGS_BEGIN
	};
}

export function actionFetchTagsSuccess(data) {
	return {
		type: FETCH_TAGS_SUCCESS,
		list: data
	};
}

export function actionFetchTagsFailure(error) {
	console.log('Error ', error);
	return {
		type: FETCH_TAGS_FAILURE,
		error: error
	};
}

export function actionFetchTags() {
	return dispatch => {
		dispatch(actionFetchTagsBegin());

		return axios.get(API_URL + '/definition/tag/list')
			.then(function(response) {
				if (Array.isArray(response.data.data.list)) {
					dispatch(actionFetchTagsSuccess(response.data.data.list));
				} else {
					dispatch(actionFetchTagsFailure());
				}
			}).catch(function(error) {
				dispatch(actionFetchTagsFailure(error));
			});
	};
}