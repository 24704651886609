import {confirmAlert} from "./component/confirmAlert";

export function objectClone(input) {
	return Object.assign({}, input);
}

export function isEmptyObject(obj = {}) {
	if (obj === null) {
		return null;
	}
	return (Object.keys(obj).length === 0 && obj.constructor === Object);
}

export function getMonthName(month = null) {
	switch(parseInt(month) + 1) {
		case 1: return 'Styczeń';
		case 2: return 'Luty';
		case 3: return 'Marzec';
		case 4: return 'Kwiecień';
		case 5: return 'Maj';
		case 6: return 'Czerwiec';
		case 7: return 'Lipiec';
		case 8: return 'Sierpień';
		case 9: return 'Wrzesień';
		case 10: return 'Październik';
		case 11: return 'Listopad';
		case 12: return 'Grudzień';
	}
	return month;
}

export function getDayNameShort(day = null) {
	switch(parseInt(day)) {
		case 1: return 'Pn';
		case 2: return 'Wt';
		case 3: return 'Śr';
		case 4: return 'Cz';
		case 5: return 'Pt';
		case 6: return 'So';
		case 7: return 'Nd';
	}
	return day;
}

export function getDayName(day = null) {
	switch(parseInt(day)) {
		case 1: return 'Poniedziałek';
		case 2: return 'Wtorek';
		case 3: return 'Środa';
		case 4: return 'Czwartek';
		case 5: return 'Piątek';
		case 6: return 'Sobota';
		case 7: return 'Niedziela';
	}
	return day;
}

export function formatHours(hours) {
	hours = parseInt(hours);
	switch(hours) {
		case 1: return '1 godzina';
		case 2: return '2 godziny';
		case 3: return '3 godziny';
		case 4: return '4 godziny';
		case 5: return '5 godzin';
		case 6: return '6 godzin';
		case 7: return '7 godzin';
		case 8: return '8 godzin';
		case 9: return '9 godzin';
		case 10: return '10 godzin';
		case 11: return '11 godzin';
		case 12: return '12 godzin';
		case 13: return '13 godzin';
		case 14: return '14 godzin';
		case 15: return '15 godzin';
		case 16: return '16 godzin';
		case 17: return '17 godzin';
		case 18: return '18 godzin';
		case 19: return '19 godzin';
		case 20: return '20 godzin';
		case 21: return '21 godzin';
		case 22: return '22 godziny';
		case 23: return '23 godziny';
		case 24: return '24 godziny';
	}
}

export function formatDate(date = new Date()) {
	if (isValidDate(date) === false) {
		return '';
	}

	return date.toLocaleDateString(undefined, {day: '2-digit'}) +
		'-' + date.toLocaleDateString(undefined, {month: '2-digit'}) +
		'-' + date.toLocaleDateString(undefined, { year: 'numeric' });
}

export function formatLocalizedDate(date = new Date()) {
	if (isValidDate(date) === false) {
		return '';
	}

	return date.getDate() + ' ' + getMonthName(date.getMonth()).toLowerCase() + ' ' + date.getFullYear();
}

export function formatLocalizedDateTime(date = new Date()) {
	if (isValidDate(date) === false) {
		return '';
	}

	return formatLocalizedDate(date) + ' ' + ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2);
}

export function isValidDate(date) {
	return date instanceof Date && !isNaN(date);
}

export function filenameForLabel(file) {
	if (file instanceof File) {
		return file.name;
	}

	if (typeof file !== 'undefined' && file !== null && file.hasOwnProperty('path')) {
		return getFilenameFromPath(file.path);
	}

	return '';
}

export function getFilenameFromPath(path = "") {
	return path.split('/').slice(-1).pop();
}

export function getFileUrl(id, filename = "") {
	return API_URL + '/media/download/id/' + id + '/' + filename + '?access-token=' + localStorage.getItem('accessToken');
}

export function findIndexOfObjectWithPropertyValueInArray(array, value, property = 'id') {
	if (Array.isArray(array)) {
		return array.findIndex(function(item) {
			return (item[property] == value);
		});
	}

	return null;
}

export function findIndexOfObjectWithPropertyValueInAssocArray(array, value) {
	let index = -1;

	Object.keys(array).map(function(key) {
		if (key == value) {
			index = key;
		}
	});

	return index;
}

export function findObjectWithPropertyValueInArray(array, value, property = 'id') {
	let index = findIndexOfObjectWithPropertyValueInArray(array, value, property);

	if (index === null || index === -1) {
		return null;
	}

	return array[index];
}

export function findObjectWithPropertyValueInAssocArray(array, value) {
	let index = findIndexOfObjectWithPropertyValueInAssocArray(array, value);

	if (index === null || index === -1) {
		return null;
	}

	return array[index];
}

export function nl2br(str = "") {
	if (str && typeof str == "string") {
		return str.replace(/(?:\r\n|\r|\n)/g, '<br>');
	}

	return str;
}

export function getNameFromId(array, id) {
	let found = findObjectWithPropertyValueInArray(array, id);

	if (typeof found === 'object') {
		if (found.hasOwnProperty('name')) {
			return found.name;
		}

		if (found.hasOwnProperty('firstName') && found.hasOwnProperty('lastName')) {
			return found.firstName + ' ' + found.lastName;
		}
	}

	return id;
}

export function confirmDelete(callback) {
	confirmAlert({
		title: 'Czy skasować?',
		message: '',
		buttons: [
			{label: 'Tak', className: 'right16', onClick: callback},
			{label: 'Nie', className: 'grey'}
		]
	});
}

export function isColorDark(colorHex) {
	if (colorHex.length === 4) {
		colorHex = colorHex.replace(/^#(.)(.)(.)/i, '#$1$1$2$2$3$3');
	}

	const n = parseInt(colorHex.slice(1), 16);

	const r = (n & 0xff0000) >> 16;
	const g = (n & 0xff00) >> 8;
	const b = n & 0xff;

	const lightness = (r * 299 + g * 587 + b * 114) / 1000;

	return lightness <= 128;
}
export function isColorLight(colorHex) {
	return !isColorDark(colorHex);
}

export function getNotificationUrl(params) {
	if (params.hasOwnProperty('name')) {
		switch (params.name) {
			case 'issue': return "/yourIssues/" + params.id;
			case 'projecttaskstatus': return "/task/" + params.project + "/" + params.task + "/" + params.procedure + "/" + params.service;
		}
	}

	return null;
}