import React, {useState} from 'react';
import axios from 'axios';
import {Form, Formik} from "formik";
import {useHistory} from "react-router-dom";
import BackButton from "../component/backButton";
import {findIndexOfObjectWithPropertyValueInArray, objectClone} from "../utils";
import {formikErrorHelper} from "../form/formUtils";
import moment from "moment";
import PackageForm, {validatePackage} from "./packageForm";

export default function PackageAdd(props) {
	const history = useHistory();
	const [uploadedFiles, setUploadedFiles] = useState([]);

	function handleSubmit(values, bag) {
		let toSave = objectClone(values);

		if (toSave.hasOwnProperty('client') && toSave.client != null) {
			toSave.client = parseInt(toSave.client);
			if (toSave.hasOwnProperty('clientName')) {
				delete toSave.clientName;
				delete toSave.clientPostal;
				delete toSave.clientAddress;
				delete toSave.clientCity;
			}
		}

		if (toSave.hasOwnProperty('clientName') && toSave.clientName != "") {
			if (toSave.hasOwnProperty('client')) {
				delete toSave.client;
			}
		}

		if (toSave.hasOwnProperty('user')) {
			toSave['user'] = parseInt(toSave['user']);
		}

		toSave.category = "parcel";

		axios.post(API_URL + '/reception/create', toSave)
			.then(function(response) {
				let promises = [];
				let newId = response.data.id;

				promises.concat(addFiles(uploadedFiles, newId));

				Promise.all(promises).then(function() {
					bag.setSubmitting(false);
					history.goBack();
				});
			}).catch(function(error) {
				formikErrorHelper(error, bag);
			});
	}

	function handleFileAdd(uploadedFile) {
		setUploadedFiles([...uploadedFiles, uploadedFile]);
	}

	function handleFileDelete(fileId) {
		let index = findIndexOfObjectWithPropertyValueInArray(uploadedFiles, fileId);

		if (index !== null && index !== -1) {
			let newUploadedFiles = [...uploadedFiles];
			newUploadedFiles.splice(index, 1);
			setUploadedFiles(newUploadedFiles);
		}
	}

	function addFiles(uploadedFiles, id) {
		let promisesArray = [];

		if (uploadedFiles && Array.isArray(uploadedFiles) && uploadedFiles.length > 0) {
			uploadedFiles.map((item) => {
				promisesArray.push(
					axios.post(API_URL + '/reception/file/' + id + '/' + item.id)
				);
			});
		}

		return promisesArray;
	}


	return (
		<Formik
			initialValues={{
				client: null,
				clientName: "",
				clientPostal: "",
				clientAddress: "",
				clientCity: "",
				operationDate: moment(),
				user: null,
				type: null,
				direction: null,
				company: null,
				description: "",
			}}
			validate={validatePackage}
			onSubmit={handleSubmit}
		>
			{(propsFormik) => {
				const {values, touched, errors, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched, status} = propsFormik;

				return (
					<Form>
						<div className="headerWithButtons">
							<h1>Dodaj przesyłkę</h1>
							<div className="right">
								<BackButton>Anuluj</BackButton>
								<button type="submit" disabled={isSubmitting} className="left32 withChevron">
									Dodaj&nbsp;<i className="fas fa-chevron-right"/>
								</button>
							</div>
						</div>

						<div className="box">
							<PackageForm
								{...propsFormik}
								handleFileAdd={handleFileAdd}
								handleFileDelete={handleFileDelete}
								uploadedFiles={uploadedFiles}
							/>
						</div>

						{status && <div className="errorMessage">{status}</div>}

						{/*<Dump value={values} />*/}
					</Form>
				)
			}}
		</Formik>
	);
}
