import React, {useEffect} from 'react';
import Select from "./select";
import {Option} from "rc-select";
import {connect, useDispatch} from "react-redux";
import {actionFetchDocumentTypes} from "../state/actionsDocumentTypes";

const mapStateToProps = function(state) {
	return {
		documentTypes: state.documentTypes.list,
		loading: state.documentTypes.loading,
	};
};

function SelectDocumentType(props) {
	const dispatcher = useDispatch();

	useEffect(() => {
		if (props.loading === false && (props.documentTypes == null || props.documentTypes.length === 0)) {
			dispatcher(actionFetchDocumentTypes());
		}
	}, []);

	let {documentTypes, dispatch, ...otherProps} = props;
	let data = props.documentTypes;

	return (
		<Select
			loading={props.loading}
			{...otherProps}
		>
			{data && data.map(function(item) {
				let label = (<div><span className="colorDot small" style={{backgroundColor: item.color}}/>{item.name}</div>);
				return (<Option value={parseInt(item.id)} key={'documentType' + item.id} label={label}>{label}</Option>);
			})}
		</Select>
	);
}

export default connect(mapStateToProps)(SelectDocumentType);