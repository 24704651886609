import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Form, Formik} from "formik";
import {findIndexOfObjectWithPropertyValueInArray, isEmptyObject, objectClone} from "../utils";
import KnowledgeForm, {validateKnowledge} from "./knowledgeForm";
import Spinner from "../component/spinner";
import {formikErrorHelper} from "../form/formUtils";

export default function KnowledgeEdit(props) {

	const [knowledge, setKnowledge] = useState({});
	const [uploadedFiles, setUploadedFiles] = useState([]);

	useEffect(() => {
		async function fetchKnowledge() {
			const response = await axios(API_URL + '/knowledgeBase/' + props.knowledgeId);
			if (response.data.data.user) {
				setKnowledge(response.data.data.user);
			}
		}

		fetchKnowledge();
	}, [props.knowledgeId]);

	function handleFileAdd(uploadedFile) {
		setUploadedFiles([...uploadedFiles, uploadedFile]);
	}

	function handleFileDelete(fileId, valueFiles, setFieldValue) {
		let index = findIndexOfObjectWithPropertyValueInArray(uploadedFiles, fileId);

		if (index !== null && index !== -1) {
			let newUploadedFiles = [...uploadedFiles];
			newUploadedFiles.splice(index, 1);
			setUploadedFiles(newUploadedFiles);
		} else {
			let index = findIndexOfObjectWithPropertyValueInArray(valueFiles, fileId);
			if (index !== null && index !== -1) {
				axios.delete(API_URL + '/knowledgeBase/file/' + knowledge.id + '/' + fileId)
					.then(function() {
						valueFiles.splice(index, 1);
						setFieldValue('files', valueFiles);
					});
			}
		}
	}

	function addFiles(uploadedFiles, knowledgeId) {
		let promisesArray = [];

		if (uploadedFiles && Array.isArray(uploadedFiles) && uploadedFiles.length > 0) {
			uploadedFiles.map((item) => {
				promisesArray.push(
					axios.post(API_URL + '/knowledgeBase/file/' + knowledgeId + '/' + item.id)
				);
			});
		}

		return promisesArray;
	}

	function handleSubmit(values, bag) {
		let toSend = {};
		Object.keys(values).map(function(item) {
			if (knowledge[item] !== values[item]) {
				toSend[item] = values[item];
			}
		});

		delete toSend.userModified;
		delete toSend.files;

		if (toSend.hasOwnProperty('type')) {
			toSend['type'] = parseInt(toSend['type']);
		}
		if (toSend.hasOwnProperty('department')) {
			toSend['department'] = parseInt(toSend['department']);
		}

		let promises = [];
		promises.concat(addFiles(uploadedFiles, props.knowledgeId));

		Promise.all(promises).then(() => {
			axios.patch(API_URL + '/knowledgeBase/' + props.knowledgeId, toSend)
				.then(function(response) {
					bag.setSubmitting(false);
					//console.log(response);
					if (typeof props.handleRefresh === 'function') {
						props.handleRefresh();
					}
					if (typeof props.handleClose === 'function') {
						props.handleClose();
					}
				}).catch(function(error) {
					formikErrorHelper(error, bag);
				});
		});

		//console.log(toSave);
	}

	return (isEmptyObject(knowledge) ? <div className="textCenter top96"><Spinner/></div> : renderFormik());

	function renderFormik() {
		let initial = objectClone(knowledge);
		delete initial.id;
		delete initial.created;
		delete initial.modified;
		delete initial.userModified;

		if (initial.hasOwnProperty('type') && initial.type.hasOwnProperty('id')) {
			initial.type = initial.type.id;
		}

		if (initial.hasOwnProperty('department') && initial.department.hasOwnProperty('id')) {
			initial.department = initial.department.id;
		}

		if (initial.hasOwnProperty('userModified') && initial.userModified.hasOwnProperty('name')) {
			initial.userModified = initial.userModified.name;
		}

		return (
			<Formik
				initialValues={initial}
				validate={validateKnowledge}
				onSubmit={handleSubmit}
			>
				{(propsFormik) => {
					const {values, touched, errors, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched} = propsFormik;

					return (
						<Form>
							<div className="rcModelContents">
								<h3 className="top0 bottom24">Edycja Bazy wiedzy</h3>

								<KnowledgeForm
									{...propsFormik}
									knowledge={knowledge}
									showFieldsModified={true}
									handleFileAdd={handleFileAdd}
									handleFileDelete={handleFileDelete}
									uploadedFiles={uploadedFiles}
								/>

								<div className="rcModalActions">
									<button type="submit" className="left32" disabled={isSubmitting}>Zapisz</button>
								</div>
							</div>
						</Form>
					)
				}}
			</Formik>
		);
	}
}
