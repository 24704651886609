import React from "react";
import {isBefore, parseISO} from 'date-fns';

export function getProjectDate(project) {
	let date = null;

	if (project && project.hasOwnProperty('service') && project.service && project.service.hasOwnProperty('procedures')) {
		project.service.procedures.map(function(procedure) {
			if (procedure && procedure.hasOwnProperty('tasks')) {
				procedure.tasks.map(function(task) {
					//console.log(task);
					if (task.hasOwnProperty('dateRealization')) {
						//console.log(task.status.id);
						if (date == null) {
							date = parseISO(task.dateRealization);
						} else if (isBefore(date, parseISO(task.dateRealization))) {
							date = parseISO(task.dateRealization);
						}
					}
				})
			}
		});
	}

	return date;
}

export function getProjectTypeFromService(service) {
	if (service && service.hasOwnProperty('type')) {
		return service.type;
	}

	return null;
}

export function getCompanyFromService(service) {
	if (service && service.hasOwnProperty('department') && service.department.hasOwnProperty('companies')) {
		if (Array.isArray(service.department.companies) && service.department.companies.length > 0) {
			return service.department.companies[0];
		}
	}

	return null;
}

export function getDepartmentFromService(service) {
	if (service && service.hasOwnProperty('department')) {
		return service.department;
	}

	return null;
}

export function getProjectStatusId(project) {
	let finished = true;
	let foundStatus = false;
	let inProgress = false;

	if (project && project.hasOwnProperty('service') && project.service && project.service.hasOwnProperty('procedures')) {
		project.service.procedures.map(function(procedure) {
			procedure.tasks.map(function(task) {
				//console.log(task);
				if (task.hasOwnProperty('status')) {
					//console.log(task.status.id);
					foundStatus = true;

					if (task.status.id !== 9 && task.status.id !== 10) {
						finished = false;
					}

					if (task.status.id > 1) {
						inProgress = true;
					}
				} else {
					finished = false;
				}
			})
		});
	}


	if (foundStatus === false) {
		return 0;
	}

	if (finished === true) {
		return 9;
	}

	if (inProgress) {
		return 2;
	}

	return 1;
}

export function getStatusColor(statusId) {
	switch (statusId) {
		case 0: return '#eeeeee'; // oczekujace
		case 1: return '#FF6F65'; // Do realizacji
		case 2: return '#F6E482'; // W realizacji
		case 9: return '#B8E986'; // zrealizowane
		case 10: return '#2B2B2B'; // anulowane
		case 31: return '#13bdf1'; // Do uzupełnienia
		default: return '#eeeeee';
	}
}
