import React from "react"
import PopupMenu from "../component/popupMenu";
import {parseISO} from 'date-fns'
import {confirmDelete, findObjectWithPropertyValueInArray, formatDate} from "../utils";
import axios from "axios";
import {Link} from "react-router-dom";
import {getProjectStatusId, getProjectTypeFromService} from "./projectUtils";
import PillName from "../component/pillName";
import PillStatus from "../component/pillStatus";
import Dump from "../component/dump";

export default function ProjectRow(props) {

	let item = props.value;

	if (item.services && Array.isArray(item.services) && item.services.length > 0) {
		item.service = item.services.pop();
		delete item.services;
	}

	function renderType(type) {
		if (type && type.hasOwnProperty('name')) {
			return type.name;
		}

		return '';
	}

	function handleDelete() {
		axios.delete(API_URL + '/project/' + item.id)
			.then(function(response) {
				if (typeof props.refresh === 'function') {
					props.refresh();
				}
			});
	}

	function getStatusObject(statusId) {
		let status = findObjectWithPropertyValueInArray(props.taskStatuses, statusId);
		if (status && status.hasOwnProperty('id') && status.hasOwnProperty('name')) {
			return status;
		}
		return null;
	}

	function getCompanyName(company = '', id = null) {
		let shortName = company.split(' ');
		shortName = shortName.map(function(item) {
			return item.substring(0, 1);
		});
		return shortName.join('');
	}

	return (
		<>
			<div className="boxRow">
				<div className="boxCell textBold">
					<a href="#" onClick={(event) => { event.preventDefault(); props.handleOpenModalView(item.id); }}>
						{item.name}
					</a>
				</div>
				<div className="boxCell">{renderType(getProjectTypeFromService(item.service))}</div>
				<div className="boxCell">{item.client ? item.client.name : ''}</div>
				<div className="boxCell">{item.company ? <PillName value={{name: getCompanyName(item.company.name), color: item.company.color }}/> : ''}</div>
				<div className="boxCell" style={{minWidth: 155}}>{formatDate(parseISO(item.modified))}</div>
				<div className="boxCell">{item.userModified ? item.userModified.name : ''}</div>
				<div className="boxCell" style={{minWidth: 155}}>{formatDate(parseISO(item.created))}</div>
				<div className="boxCell" style={{minWidth: 155}}>{formatDate(parseISO(item.jobsEnded))}</div>
				<div className="boxCell" style={{minWidth: 155}}>{formatDate(parseISO(item.completed))}</div>
				<div className="boxCell" style={{minWidth: 125}}><PillStatus value={item.status}/></div>

				<div className="boxCell textRight padRight8">
					<PopupMenu>
						<li onClick={() => props.handleOpenModalView(item.id)}>Zobacz</li>
						<li><Link to={"/project/edit/" + item.id}>Edytuj</Link></li>
						<li onClick={() => confirmDelete(handleDelete)} className="red">Usuń</li>
					</PopupMenu>
				</div>
			</div>
			{/* <Dump value={item} /> */}
		</>
	);
}