import React from 'react';
import {ErrorMessage} from "formik";
import Field from "../../form/field";
import ColorPicker from "../../component/colorPicker";

export default function AdminProjectTypeForm(props) {

	const {values, errors, handleChange, handleBlur, setFieldValue, setFieldTouched} = props;

	return (
		<>
			<div className="formItem">
				<label htmlFor="name">Nazwa</label>
				<Field className="fullWidth" type="text" name="name"/>
				<ErrorMessage component="div" className="errorMessage" name="name"/>
			</div>

			<div className="formItem">
				<label htmlFor="color">Kolor</label>
				<ColorPicker
					color={values.color}
					onChange={(color) => setFieldValue('color', color)}
				/>
				<ErrorMessage component="div" className="errorMessage" name="color"/>
			</div>
		</>
	)
}

export function validateProjectType(values) {
	let errors = {};
	if (!values.name) {
		errors.name = 'Nazwa jest wymagana';
	}
	if (!values.color) {
		errors.color = 'Kolor jest wymagany';
	}
	return errors;
}
