import React from "react";

export let access = {
	manager: false,
	admin: false,
	reception: false,
	id: null,
};

function hasRole(role, roles = []) {
	let toReturn = false;

	// if the roles array is associative change it a regular array
	let parsedRoles = [];
	for (let key in roles) {
		if (roles.hasOwnProperty(key)) {
			parsedRoles.push(roles[key]);
		}
	}

	parsedRoles.map(function(item) {
		if (item === role) {
			toReturn = true;
		}
	});

	return toReturn;
}

export function hasAdminRole(roles = []) {
	//return true;
	//return false;
	return hasRole('ROLE_ADMIN', roles);
}

export function hasReceptionRole(roles = []) {
	//return true;
	//return false;

	if (hasAdminRole(roles)) {
		return true;
	}
	
	return hasRole('ROLE_RECEPTION', roles);
}

export function hasManagerRole(roles = []) {
	//return true;
	//return false;
	if (hasAdminRole(roles)) {
		return true;
	}

	return hasRole('ROLE_MANAGER', roles);
}

export const AccessContext = React.createContext(access);