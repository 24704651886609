import React from 'react';
import axios from 'axios';
import {Form, Formik} from "formik";
import moment from "moment";
import {confirmDelete, isEmptyObject} from "../utils";
import CalendarAbsenceForm, {validateCalendarAbsence} from "./calendarAbsenceForm";
import {formikErrorHelper} from "../form/formUtils";
import Dump from "../component/dump";

export default function CalendarAbsenceEdit(props) {
	let data = props.value;

	function handleDelete() {
		axios.delete(API_URL + '/calendar/' + data.id)
			.then(function(response) {
				if (typeof props.handleRefresh === 'function') {
					props.handleRefresh();
				}
				if (typeof props.handleClose === 'function') {
					props.handleClose();
				}
			});
	}

	function handleSubmit(values, bag) {
		// console.log(data);
		// console.log(values);

		let toSend = {};

		if (values.owner !== data.user.id) {
			toSend.owner = values.owner;
		}

		if (values.type !== data.type.id) {
			toSend.type = values.type;
		}

		if (values.range[0].format('YYYY-MM-DD HH:mm:ss') !== data.dateFrom) {
			toSend.dateFrom = values.range[0].format('YYYY-MM-DD HH:mm:ss');
		}
		if (values.range[1].format('YYYY-MM-DD HH:mm:ss') !== data.dateTo) {
			toSend.dateTo = values.range[1].format('YYYY-MM-DD HH:mm:ss');
		}

		if (isEmptyObject(toSend) === false) {
			toSend.id = data.id;

			axios.patch(API_URL + '/calendar/' + data.id, toSend)
				.then(function(response) {
					bag.setSubmitting(false);
					//console.log(response.data);
					if (typeof props.handleRefresh === 'function') {
						props.handleRefresh();
					}
					if (typeof props.handleClose === 'function') {
						props.handleClose();
					}
				}).catch(function(error) {
					formikErrorHelper(error, bag);
				});
		} else { // no update needed just close
			if (typeof props.handleClose === 'function') {
				props.handleClose();
			}
		}
	}

	return (
		<Formik
			initialValues={{
				owner: data.user.id,
				type: data.type.id,
				range: [moment(data.dateFrom), moment(data.dateTo)]
			}}
			validate={validateCalendarAbsence}
			onSubmit={handleSubmit}
		>
			{(propsFormik) => {
				const {values, touched, errors, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched} = propsFormik;

				return (
					<Form>
						<div className="rcModelContents" style={{minWidth: 770}}>
							<div className="headerWithButtons">
								<h1>Edytuj urlop</h1>
							</div>

							<CalendarAbsenceForm {...propsFormik} />

							{status && <div className="errorMessage">{status}</div>}

							{/*<Dump value={props.value} />*/}
							{/*<Dump value={values} />*/}
						</div>
						<div className="rcModalActions">
							<button type="submit" disabled={isSubmitting}>Zapisz</button>
							<button onClick={(event) => { event.preventDefault(); confirmDelete(handleDelete); }} className="red left12">Usuń</button>
						</div>
					</Form>
				)
			}}
		</Formik>
	);
}
