import React, {useEffect} from 'react';
import Select from "./select";
import {Option} from "rc-select";
import {connect, useDispatch} from "react-redux";
import {actionFetchIssueStatuses} from "../state/actionsIssueStatuses";
import {getStatusColor} from "../project/projectUtils";

const mapStateToProps = function(state) {
	return {
		issueStatuses: state.issueStatuses.list,
		loading: state.issueStatuses.loading,
	};
};

function SelectIssueStatus(props) {
	const dispatcher = useDispatch();

	useEffect(() => {
		if (props.loading === false && (props.issueStatuses == null || props.issueStatuses.length === 0)) {
			dispatcher(actionFetchIssueStatuses());
		}
	}, []);

	let {issueStatuses, dispatch, showAcceptStatus, ...otherProps} = props;

	return (
		<Select
			loading={props.loading}
			optionLabelProp="label"
			{...otherProps}
		>
			{props.issueStatuses && props.issueStatuses.map(function(item) {
				let color = getStatusColor(item.id);
				let labelSmall = (<div><span className="colorDot small" style={{backgroundColor: color}}/>{item.name}</div>);
				let label = (<div><span className="colorDot" style={{backgroundColor: color}}/>{item.name}</div>);

				if (showAcceptStatus || item.id !== 30) {
					return <Option value={parseInt(item.id)} key={'issueStatus' + item.id} label={labelSmall}>{labelSmall}</Option>
				}

				return '';
			})}
		</Select>
	);
}

export default connect(mapStateToProps)(SelectIssueStatus);