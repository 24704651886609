import React from "react";

export default function Spinner(props) {
	return (
		<div className="sk-chase">
			<div className="sk-chase-dot"/>
			<div className="sk-chase-dot"/>
			<div className="sk-chase-dot"/>
			<div className="sk-chase-dot"/>
			<div className="sk-chase-dot"/>
			<div className="sk-chase-dot"/>
		</div>
	);
}