import React, {useContext} from 'react'
import axios from 'axios'
import {Form, Formik} from "formik"
import {useHistory} from "react-router-dom"
import AvatarUpload from "../component/avatarUpload"
import BackButton from "../component/backButton";
import ManageClients from "../form/manageClients";
import {objectClone} from "../utils";
import ProfileForm, {ProfileFormNewContact, validateProfile} from "./profileForm";
import {formikErrorHelper} from "../form/formUtils";
import {AccessContext} from "../access";
import Dump from "../component/dump";

export default function ProfileAdd(props) {
	const history = useHistory();
	let manageClients = React.createRef();
	const access = useContext(AccessContext);

	function handleSubmit(values, bag) {
		let toSend = objectClone(values);

		if (toSend.hasOwnProperty('department')) {
			toSend['department'] = parseInt(toSend['department']);
		}

		if (toSend.hasOwnProperty('position')) {
			toSend['position'] = parseInt(toSend['position']);
		}

		let newReplacements = toSend.newReplacements;
		delete toSend.newReplacements;

		axios.post(API_URL + '/profile/create', toSend)
			.then(function(response) {
				//console.log(response.data.data);
				bag.setSubmitting(false);

				let newId = response.data.data.id;
				if (newId) {
					manageClients.current.save(newId, () => {
						saveReplacements(newReplacements, newId);
						history.goBack();
					});
				}
			}).catch(function(error) {
				formikErrorHelper(error, bag);
			});
	}

	function saveReplacements(toSave, id) {
		let savePromisesArray = [];

		toSave.map(function(item) {
			item.user = id;

			if (typeof item.dateFrom == 'object') { //moment
				item.dateFrom = item.dateFrom.format('YYYY-MM-DD HH:mm:ss');
			}

			if (typeof item.dateTo == 'object') { //moment
				item.dateTo = item.dateTo.format('YYYY-MM-DD HH:mm:ss');
			}

			savePromisesArray.push(
				axios.post(API_URL + '/replacement/create', item)
			);
		});

		Promise.all(savePromisesArray).then(() => history.goBack());
	}

	return (
		<Formik
			initialValues={{
				firstName: "",
				lastName: "",
				department: null,
				position: null,
				phone: "",
				email: "",
				businessArea: "",
				picture: "",
				newReplacements: [],
			}}
			validate={validateProfile}
			onSubmit={handleSubmit}
		>
			{(propsFormik) => {
				const {values, touched, errors, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched, status} = propsFormik;

				return (
					<Form>
						<div className="headerWithButtons">
							<h1>Dodaj pracownika</h1>
							<div className="right">
								<BackButton>Anuluj</BackButton>
								<button type="submit" disabled={isSubmitting} className="left32 withChevron">
									Dodaj&nbsp;<i className="fas fa-chevron-right"/>
								</button>
							</div>
						</div>

						<div className="box displayInlineBlock">
							<h3 className="top0 bottom32">Zdjęcie</h3>
							<div className="padLeft16 padRight16">
								<AvatarUpload onSuccess={(value) => setFieldValue('picture', value.id)}/>
							</div>
						</div>

						<div className="box">
							<h3 className="top0 bottom32">Dane pracownika</h3>
							<ProfileForm access={access} {...propsFormik}/>
						</div>

						<Dump value={values}/>

						{status && <div className="errorMessage">{status}</div>}

						<ManageClients ref={manageClients}/>

						<ProfileFormNewContact {...propsFormik}/>
					</Form>
				)
			}}
		</Formik>
	);
}
