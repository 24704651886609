export const OfferStatuses = [
	{
			id: 0,
			name: 'Oczekuje na decyzję',
	},
	{
			id: 1,
			name: 'Odmowa',
	},
	{
			id: 2,
			name: 'Zaakceptowana',
	},
	{
			id: 3,
			name: 'W uzupełnieniu',
	},
	{
			id: 4,
			name: 'Złożono nową ofertę',
	}
]
