import axios from "axios";

export const FETCH_DEPARTMENTS_BEGIN = 'FETCH_DEPARTMENTS_BEGIN';
export const FETCH_DEPARTMENTS_SUCCESS = 'FETCH_DEPARTMENTS_SUCCESS';
export const FETCH_DEPARTMENTS_FAILURE = 'FETCH_DEPARTMENTS_FAILURE';

export function actionFetchDepartmentsBegin() {
	return {
		type: FETCH_DEPARTMENTS_BEGIN
	};
}

export function actionFetchDepartmentsSuccess(data) {
	return {
		type: FETCH_DEPARTMENTS_SUCCESS,
		list: data
	};
}

export function actionFetchDepartmentsFailure(error) {
	console.log('Error ', error);
	return {
		type: FETCH_DEPARTMENTS_FAILURE,
		error: error
	};
}

export function actionFetchDepartments() {
	return dispatch => {
		dispatch(actionFetchDepartmentsBegin());

		return axios.get(API_URL + '/definition/department/list')
			.then(function(response) {
				if (Array.isArray(response.data.data.list)) {
					dispatch(actionFetchDepartmentsSuccess(response.data.data.list));
				} else {
					dispatch(actionFetchDepartmentsFailure());
				}
			}).catch(function(error) {
				dispatch(actionFetchDepartmentsFailure(error));
			});
	};
}