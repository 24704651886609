import React, {useEffect, useState} from 'react'
import AdminProcedureRow from "./adminProcedureRow";
import Sortable from "../../component/sortable";
import Spinner from "../../component/spinner";
import PaginationLocale from 'rc-pagination/lib/locale/pl_PL';
import Pagination from 'rc-pagination';
import ReactModal from "react-modal";
import AdminProcedureEdit from "./adminProcedureEdit";
import AdminProcedureAdd from "./adminProcedureAdd";
import AdminProcedureView from "./adminProcedureView";
import axios from "axios";
import {useDispatch} from "react-redux";
import {actionFetchProcedures} from "../../state/actionsProcedures";

export default function AdminProcedureList(props) {
	const dispatch = useDispatch();

	const perPage = 10;
	const [currentPage, setCurrentPage] = useState(1);
	const [proceduresCount, setProceduresCount] = useState(1);

	const [search, setSearch] = useState("");
	const [procedures, setProcedures] = useState(null);
	const [procedureId, setProcedureId] = useState(null);

	const [orderColumn, setOrderColumn] = useState('modifiedDate');
	const [orderDirection, setOrderDirection] = useState(true);

	const [showModalAdd, setShowModalAdd] = useState(false);
	const [showModalEdit, setShowModalEdit] = useState(false);
	const [showModalView, setShowModalView] = useState(false);

	async function fetchProcedures() {
		let toSend = {
			page: currentPage,
			perPage: perPage
		};

		if (search) {
			toSend.search = search;
		}

		if (orderColumn && orderDirection !== null) {
			toSend.sort = orderColumn;
			if (orderDirection === true) {
				toSend.order = 'desc';
			}
			if (orderDirection === false) {
				toSend.order = 'asc';
			}
		}

		const response = await axios.get(API_URL + '/definition/procedure/list', {params: toSend});

		let data = response.data.data.list;
		if (Array.isArray(data.list)) {
			setProcedures(data.list);
			setProceduresCount(data.count);
		}
	}

	useEffect(() => {
		fetchProcedures();
	}, [search, currentPage, perPage, orderDirection, orderColumn]);

	function handleSortChange(column, direction) {
		setOrderDirection(direction);
		setOrderColumn(column);
	}

	function onPageChange(page) {
		setCurrentPage(page);
	}

	function handleCloseModalAdd() {
		setShowModalAdd(false);
	}

	function handleCloseModalView() {
		setShowModalView(false);
	}

	function handleOpenModalView(id) {
		setProcedureId(id);
		setShowModalView(true);
	}

	function handleCloseModalEdit() {
		setShowModalEdit(false);
	}

	function handleOpenModalEdit(id) {
		setProcedureId(id);
		setShowModalEdit(true);
	}

	function handleRefresh() {
		dispatch(actionFetchProcedures());
		fetchProcedures();
	}

	return (
		<div>
			<div className="headerWithButtons">
				<div className="left">
					<h1>Definicje - Procedury</h1>

					<button className="left24 withChevron" onClick={() => setShowModalAdd(true)}>
						Dodaj&nbsp;<i className="fas fa-plus"/>
					</button>
				</div>
				<div className="right">
					<input type="search" className="pill" placeholder="Szukaj" value={search} onChange={event => setSearch(event.target.value) }/>
				</div>
			</div>

			<div className="boxTable">
				<div className="boxHeader">
					<div className="boxCell"><Sortable onChange={handleSortChange} index="name" value={(orderColumn === 'name') ? orderDirection : null}>Nazwa procedury</Sortable></div>
					<div className="boxCell"><Sortable onChange={handleSortChange} index="department" value={(orderColumn === 'department') ? orderDirection : null}>Dział</Sortable></div>
					<div className="boxCell"><Sortable onChange={handleSortChange} index="service" value={(orderColumn === 'service') ? orderDirection : null}>Usługi</Sortable></div>
					<div className="boxCell"><Sortable onChange={handleSortChange} index="date" value={(orderColumn === 'date') ? orderDirection : null}>Data aktualizacji</Sortable></div>
					<div className="boxCell"><Sortable onChange={handleSortChange} index="person" value={(orderColumn === 'person') ? orderDirection : null}>Przez kogo</Sortable></div>
					<div className="boxCell"/>
				</div>

				{procedures && procedures.map(function(item, index) {
					return [
						<AdminProcedureRow key={'procedure' + index} value={item} handleOpenModalEdit={handleOpenModalEdit} handleOpenModalView={handleOpenModalView} refresh={handleRefresh}/>,
						<div key={'spacer' + index} className="boxRow spacer"/>
					];
				})}
			</div>

			{(procedures == null) ? <div className="textCenter"><Spinner/></div> : ''}

			<div className="textCenter">
				<Pagination
					onChange={onPageChange}
					pageSize={perPage}
					defaultCurrent={1}
					current={currentPage}
					total={proceduresCount}
					//showTotal={(total) => `Total ${total} items`}
					prevIcon={<i className="fas fa-chevron-left"/>}
					nextIcon={<i className="fas fa-chevron-right"/>}
					locale={PaginationLocale}
				/>
			</div>

			{/*<Dump value={documents}/>*/}
			{/*<Dump label="column" value={orderColumn}/>*/}
			{/*<Dump label="direction" value={orderDirection}/>*/}
			{/*<Dump label="filters" value={filters}/>*/}

			<ReactModal
				isOpen={showModalAdd}
				contentLabel=""
				onRequestClose={handleCloseModalAdd}
				shouldCloseOnOverlayClick={true}
				className="rcModal withActions bigger"
				overlayClassName="rcOverlay"
			>
				<span onClick={handleCloseModalAdd} className="rcModalClose"><i className="fas fa-times"/> Zamknij</span>

				<AdminProcedureAdd handleRefresh={handleRefresh} handleClose={handleCloseModalAdd}/>
			</ReactModal>

			<ReactModal
				isOpen={showModalEdit}
				contentLabel=""
				onRequestClose={handleCloseModalEdit}
				shouldCloseOnOverlayClick={true}
				className="rcModal withActions bigger"
				overlayClassName="rcOverlay"
			>
				<span onClick={handleCloseModalEdit} className="rcModalClose"><i className="fas fa-times"/> Zamknij</span>

				<AdminProcedureEdit handleRefresh={handleRefresh} handleClose={handleCloseModalEdit} procedureId={procedureId} />
			</ReactModal>

			<ReactModal
				isOpen={showModalView}
				contentLabel=""
				onRequestClose={handleCloseModalView}
				shouldCloseOnOverlayClick={true}
				className="rcModal withActions bigger"
				overlayClassName="rcOverlay"
			>
				<span onClick={handleCloseModalView} className="rcModalClose"><i className="fas fa-times"/> Zamknij</span>

				<AdminProcedureView handleClose={handleCloseModalView} procedureId={procedureId} />
			</ReactModal>
		</div>
	);
}
