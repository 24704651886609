import axios from "axios";

export const FETCH_CLIENTS_BEGIN = 'FETCH_CLIENTS_BEGIN';
export const FETCH_CLIENTS_SUCCESS = 'FETCH_CLIENTS_SUCCESS';
export const FETCH_CLIENTS_FAILURE = 'FETCH_CLIENTS_FAILURE';

export function actionFetchClientsBegin() {
	return {
		type: FETCH_CLIENTS_BEGIN
	};
}

export function actionFetchClientsSuccess(data) {
	return {
		type: FETCH_CLIENTS_SUCCESS,
		list: data
	};
}

export function actionFetchClientsFailure(error) {
	console.log('Error ', error);
	return {
		type: FETCH_CLIENTS_FAILURE,
		error: error
	};
}

export function actionFetchClients() {
	return dispatch => {
		dispatch(actionFetchClientsBegin());

		return axios.get(API_URL + '/client/list')
			.then(function(response) {
				if (Array.isArray(response.data.data.list)) {
					dispatch(actionFetchClientsSuccess(response.data.data.list));
				} else {
					dispatch(actionFetchClientsFailure());
				}
			}).catch(function(error) {
				dispatch(actionFetchClientsFailure(error));
			});
	};
}