import React from "react"
import {getStatusColor} from "../project/projectUtils";
import Pill from "./pill";

export default function PillStatus(props) {

	let status = props.value;

	if (status && status.hasOwnProperty('id') && status.hasOwnProperty('name')) {
		let color = status.hasOwnProperty('color') ? status.color : getStatusColor(status.id);

		return (<Pill value={status.name} color={color}/>);
	}

	return (<></>);
}