import React from "react";
import {Link, useHistory, useParams} from "react-router-dom";
import axios from 'axios';
import {ErrorMessage, Form, Formik} from 'formik';
import Field from "../form/field";
import {formikErrorHelper} from "../form/formUtils";

export default function PasswordRecoveryChange(props) {

	const history = useHistory();

	let {recoveryToken} = useParams();

	function handleSubmit(values, bag) {
		axios.post(API_URL + '/auth/reset_password_form', values)
			.then(function(response) {
				bag.setSubmitting(false);
				//console.log('success');
				history.push('/login');
			}).catch(function(error) {
				formikErrorHelper(error, bag);
			});
	}

	return (
		<div className="loginWrapper">
			<h2>Nowe hasło</h2>
			<div className="loginBox">
				<Formik
					initialValues={{
						password: "",
						passwordRepeat: "",
						token: recoveryToken
					}}
					validate={values => {
						let errors = {};
						if (!values.password) {
							errors.password = 'Hasło jest wymagane';
						}
						if (!values.passwordRepeat) {
							errors.passwordRepeat = 'Hasło jest wymagane';
						}
						if (values.password && values.passwordRepeat && values.password !== values.passwordRepeat) {
							errors.passwordRepeat = 'Hasła muszą być takie same';
						}
						return errors;
					}}
					onSubmit={handleSubmit}
				>
					{(propsFormik) => {
						const {values, touched, errors, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, handleReset, status} = propsFormik;
						return (
							<Form>
								<Field type="hidden" name="token"/>

								<div className="formItem">
									<Field type="password" name="password" placeholder="Hasło"/>
									<ErrorMessage component="div" className="errorMessage" name="password"/>
								</div>

								<div className="formItem">
									<Field type="password" name="passwordRepeat" placeholder="Powtórz hasło"/>
									<ErrorMessage component="div" className="errorMessage" name="passwordRepeat"/>
								</div>

								{status && <div className="errorMessage">{status}</div>}

								<input type="submit" value="Wyślij" className="top8" disabled={isSubmitting}/>
							</Form>
						)
					}}
				</Formik>
			</div>
			<br/>
			<Link to="/login">&lt; Powrót</Link>
			{/*<Dump value={props.match.params}/>*/}
		</div>
	);
}
