import React from "react"
import {Link} from "react-router-dom"
import PopupMenu from "../component/popupMenu";
import axios from "axios";
import Tag from "../component/tag";
import {confirmDelete} from "../utils";
import {ClientStatuses} from '../state/actionsClientStatuses';


export default function ClientRow(props) {
	const statuses=ClientStatuses
	let item = props.value;
	function handleDelete() {
		axios.delete(API_URL + '/client/' + item.id)
			.then(function(response) {
				if (typeof props.refresh === 'function') {
					props.refresh();
				}
			});
	}

	function arrayPeopleToNameString(people = []) {
		let toReturn = '';

		if (people) {
			people.map(function(person) {
				toReturn = toReturn + person.firstName + ' ' + person.lastName + ', ';
			});
		}

		return toReturn.slice(0, -2);
	}

	function renderClientService(services = []) {
		let toReturn = '';

		if (services) {
			services.map(function(item) {
				toReturn = toReturn + item.name + ', ';
			});
		}

		return toReturn.slice(0, -2);
	}
	return (
		<div className="boxRow">
			<div className="boxCell textBold"><Link to={"/client/view/" + item.id}>{item.name}</Link></div>
			<div className="boxCell" style={{minWidth: 300}}>{renderClientService(item.clientService)}</div>
			<div className="boxCell" style={{minWidth: 300}}>{arrayPeopleToNameString(item.persons)}</div>
			<div className="boxCell">
				{item.status === null || typeof item.status == undefined || !item.hasOwnProperty('status') ? '-' : ClientStatuses[item.status] }
			</div>
			<div className="boxCell textRight">
				<PopupMenu style={{right: '-12px'}}>
					<li><Link to={"/client/view/" + item.id}>Podgląd</Link></li>
					<li><Link to={"/client/edit/" + item.id}>Edytuj</Link></li>
					<li onClick={() => confirmDelete(handleDelete)} className="red">Usuń</li>
				</PopupMenu>
			</div>
		</div>
	);
}