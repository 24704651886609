import {FETCH_TASK_STATUSES_BEGIN, FETCH_TASK_STATUSES_SUCCESS, FETCH_TASK_STATUSES_FAILURE} from './actionsTaskStatuses';

const reducerTaskStatuses = (state = {list: [], loading: false}, action) => {

	switch (action.type) {
		case FETCH_TASK_STATUSES_BEGIN:
			return {
				...state,
				loading: true,
				error: null // also, reset any errors
			};

		case FETCH_TASK_STATUSES_SUCCESS:
			// replace the items with the ones from the server
			return {
				...state,
				loading: false,
				list: action.list,
			};

		case FETCH_TASK_STATUSES_FAILURE:
			// save the error
			return {
				...state,
				error: action.error,
				loading: false,
				list: []
			};

		default:
			return state
	}
};

export default reducerTaskStatuses