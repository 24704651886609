import React from 'react'
import {useHistory} from "react-router-dom"

export default function BackButton(props) {
	let history = useHistory();

	return (
		<span onClick={history.goBack} className={'link ' + (props.className ? props.className : '')}>
			{props.children}
		</span>
	);
}
