import React from "react"
import {addDays, endOfMonth, endOfWeek, format, isPast, isSameDay, isSameMonth, startOfMonth, startOfWeek, getDate, endOfDay} from 'date-fns';
import CalendarDailyCell from "./calendarDailyCell";
import {getDayNameShort} from "../../utils";

export default function CalendarDaily(props) {

	const prefixCss = 'calendarDaily';

	let currentMonth = props.currentMonth;
	if (!(currentMonth instanceof Date)) {
		currentMonth = new Date();
	}

	let data = props.value;
	if (typeof data === 'undefined') {
		data = {};
	}

	function renderHeader() {
		const dateFormat = "i";
		const toReturn = [];

		let startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });

		for (let i = 0; i < 7; i++) {
			toReturn.push(
				<div className={prefixCss + "-header-cell"} key={i}>
					{getDayNameShort(format(addDays(startDate, i), dateFormat))}
				</div>
			);
		}

		return <div className={prefixCss + "-row"}>{toReturn}</div>;
	}

	function renderCells() {
		const monthStart = startOfMonth(currentMonth);
		const monthEnd = endOfMonth(monthStart);
		const startDate = startOfWeek(monthStart, { weekStartsOn: 1 });
		const endDate = endOfWeek(monthEnd, { weekStartsOn: 1 });

		let rows = [];
		let rowCells = [];

		let day = startDate;

		while (day <= endDate) {
			for (let i = 0; i < 7; i++) {
				let sameMonth = isSameMonth(day, monthStart);

				let displayDayContents = sameMonth;
				if (isPast(endOfDay(day))) {
					displayDayContents = false;
				}

				let disabled = !displayDayContents;

				rowCells.push(
					<CalendarDailyCell
						value={sameMonth && data.hasOwnProperty(getDate(day)) ? data[getDate(day)] : null}
						day={day}
						key={day}
						selected={isSameDay(day, props.selectedDate)}
						disabled={disabled}
						displayDayContents={displayDayContents}
						onDateClick={props.onDateChange}
						prefixCss={prefixCss}
					/>
				);

				day = addDays(day, 1);
			}

			rows.push(<div className={prefixCss + "-row"} key={'row' + day}>{rowCells}</div>);
			rowCells = [];
		}

		return rows;
	}

	return (
		<div className={prefixCss}>
			{renderHeader()}
			{renderCells()}
			{/*<Dump value={data}/>*/}
		</div>
	);
}
