import {useEffect, useState} from "react";
import {askUserPermission as ask, createUserSubscription, getUserSubscription, isPushNotificationSupported} from "./pushNotifications";
import axios from 'axios';
import {useDispatch, useSelector} from "react-redux";
import ab2str from "arraybuffer-to-string";
import {actionFetchProfile} from "./state/actionsProfile";

export default function usePushNotifications() {
	const me = useSelector(state => state.profile.me);
	const dispatch = useDispatch();

	// to manage the user consent: Notification.permission is a JavaScript native function that return the current state of the permission
	// we initialize the userConsent with that value
	const [userConsent, setUserConsent] = useState(Notification.permission);

	// to manage the use push notification subscription
	const [userSubscription, setUserSubscription] = useState(null);

	// retrieve if there is any push notification subscription for the registered service worker
	useEffect(() => {
		getUserSubscription().then(function(existingSubscription) {
			setUserSubscription(existingSubscription);
		});
	}, []);

	useEffect(() => {
		if (userConsent === 'granted' && userSubscription === null) {
			subscribeToPushNotification();
		}
	}, [userConsent, userSubscription]);

	useEffect(() => {
		if (userSubscription !== null) {
			sendSubscriptionToServer();
		}
	}, [userSubscription]);

	 // define a click handler that asks the user permission, it uses the setUserConsent state, to set the consent of the user
	function askUserPermission() {
		ask().then(consent => {
			setUserConsent(consent);

			if (consent !== "granted") {
				console.error("Consent denied by user");
			}
		});
	}

	// creates a push notification subscription. Once the subscription is created, it uses the setUserSubscription hook
	function subscribeToPushNotification() {
		createUserSubscription()
			.then(function(subscription) {
				setUserSubscription(subscription);
			})
			.catch(err => {
				console.error("Couldn't create the notification subscription", err, "name:", err.name, "message:", err.message, "code:", err.code);
			});
	}

	// sends the push subscription to the server.
	function sendSubscriptionToServer() {
		return axios.patch(API_URL + '/profile/' + me.id, {
			pushSettings: {
				endpoint: userSubscription.endpoint,
				p256dh: ab2str(userSubscription.getKey('p256dh'), 'base64'),
				auth: ab2str(userSubscription.getKey('auth'), 'base64'),
			}
		})
		.then(function() {
			dispatch(actionFetchProfile());
		});
	}

	return {
		isPushNotificationSupported: isPushNotificationSupported(),
		isConsentGranted: userConsent === "granted",
		askUserPermission,
		userConsent,
		userSubscription,
		sendSubscriptionToServer
	};
}
