import React from 'react'
import {ErrorMessage, Form, Formik} from "formik";
import SelectTask from "../form/selectTask";

export default function SelectPopupTask(props) {

	function handleSubmit(values, bag) {
		bag.setSubmitting(false);

		if (typeof props.handleClose === 'function') {
			props.handleClose(values.task);
		}
	}

	return (
		<Formik
			initialValues={{ task: "" }}
			onSubmit={handleSubmit}
		>
			{(propsFormik) => {
				const {values, touched, errors, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched} = propsFormik;

				return (
					<Form>
						<div className="rcModelContents">
							<h3 className="top0 bottom24">Dodaj nowe zadanie</h3>

							<div className="formItem">
								<label htmlFor="task">Zadanie</label>
								<SelectTask
									className="fullWidth"
									name="task"
									onChange={setFieldValue}
									onBlur={setFieldTouched}
									value={values.task}
								/>
								<ErrorMessage component="div" className="errorMessage" name="task"/>
							</div>

							{status && <div className="errorMessage">{status}</div>}

							{/*<Dump label="formik" value={propsFormik}/>*/}

							<div className="rcModalActions">
								<button type="submit" className="left32" disabled={isSubmitting}>Dodaj</button>
							</div>
						</div>
					</Form>
				)
			}}
		</Formik>
	);
}
