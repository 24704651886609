import React from "react";
import {Link, useHistory} from "react-router-dom";
import {ErrorMessage, Form, Formik} from 'formik';
import Field from "../form/field";
import axios from 'axios';

export default function PasswordRecovery(props) {

	const history = useHistory();

	function handleSubmit(values, bag) {
		axios.post(API_URL + '/auth/reset_password_request', values)
			.then(function(response) {
				bag.setSubmitting(false);

				if (response.data.data.password_reset_token) {
					//console.log('resetToken', response.data.data.password_reset_token);
					//history.push('/password/recoveryChange/' + response.data.data.password_reset_token);

					history.push('/password/recoverySent');
				}
			}).catch(function(error) {
				if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data') && error.response.data.hasOwnProperty('status')) {
					//console.log(error.response.data);
					if (error.response.data.status == 0) {
						bag.setStatus('Nieprawidłowy email użytkownika');
					}
				}
				bag.setSubmitting(false);
			});
	}

	return (
		<div className="loginWrapper">
			<h2>Przypomnij hasło</h2>
			<div className="loginBox">
				<Formik
					initialValues={{
						email: ""
					}}
					validate={values => {
						let errors = {};
						if (!values.email) {
							errors.email = 'Adres email jest wymagany';
						}
						return errors;
					}}
					onSubmit={handleSubmit}
				>
					{(propsFormik) => {
						const {values, touched, errors, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, handleReset, status} = propsFormik;
						return (
							<Form>
								<div className="formItem">
									<Field type="email" name="email" placeholder="Adres e-mail"/>
									<ErrorMessage component="div" className="errorMessage" name="email"/>
								</div>

								{status && <div className="errorMessage">{status}</div>}

								<input type="submit" value="Przypomnij hasło" className="top8" disabled={isSubmitting}/>
							</Form>
						)
					}}
				</Formik>
			</div>
			<br/>
			<Link to="/login">&lt; Powrót</Link>
		</div>
	);
}
