import React, {Component} from 'react';
import ReactModal from "react-modal"
import PopupMenu from "../component/popupMenu";
import axios from "axios";
import SelectPerson from "./selectPerson";
import {confirmDelete, findObjectWithPropertyValueInArray} from "../utils";
import Avatar from "../component/avatar";

class ManagePeople extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false,

            allPeople: null,
            selected: null,

            people: (props.value ? props.value : []),
            toAdd: [],
            toDelete: [],
        };

        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleAdd = this.handleAdd.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    handleCloseModal() {
        this.setState({
            showModal: false,
            selected: null,
        });
    }

    handleAdd() {
        // add only if not already adding and not already on list
        if (this.state.toAdd.indexOf(this.state.selected) === -1 && this.state.people.indexOf(this.state.selected) === -1) {
            let newAdd = [...this.state.toAdd];
            newAdd.push(this.state.selected);
            this.setState({toAdd: newAdd});
        }
        console.log(this.state.toAdd)
        this.handleCloseModal();
    }

    handleDelete(id) {
        if (this.state.toAdd.indexOf(id) !== -1) { // only remove from to add table
            let newToAdd = [...this.state.toAdd];
            newToAdd.splice(this.state.toAdd.indexOf(id), 1);
            this.setState({toAdd: newToAdd});
        } else {
            if (this.state.toDelete.indexOf(id) === -1) { // add to delete array only once
                let newDelete = [...this.state.toDelete];
                newDelete.push(id);
                this.setState({toDelete: newDelete});
            }
        }

        this.handleCloseModal();
    }

    save(id, callback) {
        let saveAll = this.state.toAdd.map((personId) => {
            return axios.post(API_URL + '/client/clientService/' + id + '/' + personId);
        });

        let deleteAll = this.state.toDelete.map((personId) => {
            return axios.delete(API_URL + '/client/clientService/' + id + '/' + personId);
        });

        let allActions = saveAll.concat(deleteAll);

        Promise.all(allActions)
            .then(() => {
                if (typeof callback === 'function') {
                    callback();
                }
            });
    }

    render() {
        return (
            <span>
				<h3 className="top0 bottom24 displayInlineBlock">Opiekunowie</h3>
				<button className="left12 plus" onClick={(event) => {
                    event.preventDefault();
                    this.setState({showModal: true});
                }}><i className="fas fa-plus"/></button>

                {this.state.people.concat(this.state.toAdd).map((id) => {

                    // skip item to be deleted
                    if (this.state.toDelete.indexOf(id) !== -1) {
                        return null;
                    }

                    // dont render if the details are not there
                    let item = findObjectWithPropertyValueInArray(this.state.allPeople, id);
                    if (item === null || item === undefined) {
                        return null;
                    }

                    return (
                        <div key={"person" + item.id} className="box smallerPadding">
                            <div className="floatRight">
                                <PopupMenu style={{right: '-12px'}}>
                                    <li onClick={() => confirmDelete(() => this.handleDelete(item.id))}
                                        className="red">Usuń
                                    </li>
                                </PopupMenu>
                            </div>
                            <Avatar picture={item.picture} cssClass="noShadow"/> {item.firstName} {item.lastName}
                        </div>
                    );
                })}

                <ReactModal
                    isOpen={this.state.showModal}
                    contentLabel=""
                    onRequestClose={this.handleCloseModal}
                    shouldCloseOnOverlayClick={true}
                    className="rcModal withActions"
                    overlayClassName="rcOverlay"
                >
					<span onClick={this.handleCloseModal} className="rcModalClose"><i className="fas fa-times"/> Zamknij</span>

					<div className="rcModelContents">
						<h3 className="top0 bottom24">Wyszukaj osobe</h3>
						<SelectPerson name="people" onChange={(name, value) => this.setState({selected: value})}/>
					</div>
					<div className="rcModalActions">
						<button disabled={this.state.selected === null} onClick={this.handleAdd}>Dodaj</button>
					</div>
				</ReactModal>

                {/*<Dump label="people" value={this.state.people}/>*/}
                {/*<Dump label="toAdd" value={this.state.toAdd}/>*/}
                {/*<Dump label="toDelete" value={this.state.toDelete}/>*/}
                {/*<Dump label="selected" value={this.state.selected}/>*/}
                {/*<Dump label="allPeople" value={this.state.allPeople}/>*/}
			</span>
        );
    }

    componentDidMount() {
        axios.get(API_URL + '/profile/list').then((response) => {
            if (Array.isArray(response.data.data.list)) {
                this.setState({allPeople: response.data.data.list});
            }
        })
    }
}

export default ManagePeople
