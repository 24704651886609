import React from 'react';
import Select from "./select";
import {Option} from "rc-select";
import {connect} from "react-redux";
import {ClientStatuses} from '../state/actionsClientStatuses';

const mapStateToProps = function(state) {
	return {
		statuses: ClientStatuses,
	};
};

function SelectClientStatus(props) {

	let {statuses,  ...otherProps} = props;

	return (
		<Select
			showSearch={true}
			{...otherProps}
		>
			{props.statuses && props.statuses.map(function(item, index) {
				return <Option value={parseInt(index)} key={'service' + index}>{item}</Option>
			})}
		</Select>
	);
}

export default connect(mapStateToProps)(SelectClientStatus);