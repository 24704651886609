import React from "react"
import {addMonths, subMonths, format, isSameMonth} from 'date-fns'
import {getMonthName} from "../../utils"

export default function MonthSelection(props) {

	const prefixCss = 'calendarMonthSelection';

	return (
		<div className={prefixCss}>
			<div className={prefixCss + "-left"} onClick={prevMonth}>
				<i className={`${prefixCss}-icon fas fa-chevron-left ${isSameMonth(props.value, new Date()) ? prefixCss + "-disabled" : "" }`}/>
			</div>
			<div className={prefixCss + "-right"} onClick={nextMonth}>
				<i className={prefixCss + "-icon fas fa-chevron-right"}/>
			</div>

			<div className={prefixCss + "-center"}>
				<span>{getMonthName(format(props.value, "MM") - 1) + ' ' + format(props.value, "yyyy")}</span>
			</div>
		</div>
	);

	function nextMonth() {
		let newMonth = addMonths(props.value, 1);
		if (typeof props.onDateChange === 'function') {
			props.onDateChange(newMonth);
		}
	}

	function prevMonth() {
		let newMonth = subMonths(props.value, 1);
		if (typeof props.onDateChange === 'function') {
			props.onDateChange(newMonth);
		}
	}
}
