import React, {useContext, useEffect, useState} from 'react';
import axios from 'axios';
import {FieldArray, Form, Formik} from "formik";
import {useHistory, useParams} from "react-router-dom";
import AvatarUpload from "../component/avatarUpload";
import BackButton from "../component/backButton";
import {isEmptyObject, objectClone} from "../utils";
import ManageClients from "../form/manageClients";
import ManageReplacements from "../form/manageReplacements";
import ProfileForm, {ProfileFormNewContact, validateProfile} from "./profileForm";
import Spinner from "../component/spinner";
import {formikErrorHelper} from "../form/formUtils";
import {AccessContext} from "../access";
import {Helmet} from "react-helmet-async";

export default function ProfileEdit(props) {
	const {id} = useParams();
	const history = useHistory();
	let manageClients = React.createRef();
	const access = useContext(AccessContext);

	const [person, setPerson] = useState({});
	const [replacementsToDelete, setReplacementsToDelete] = useState([]);

	useEffect(() => {
		async function fetchPerson() {
			const response = await axios.get(API_URL + '/profile/' + id);
			let data = response.data.data.user;
			//console.log(data);
			if (data) {
				let foo = objectClone(data);

				if (foo.hasOwnProperty('department') && foo.department.hasOwnProperty('id')) {
					foo.department = foo.department.id;
				}

				if (foo.hasOwnProperty('position') && foo.position.hasOwnProperty('id')) {
					foo.position = foo.position.id;
				}

				// if the roles array is associative change it a regular array
				if (data.hasOwnProperty('roles')) {
					foo.roles = [];
					for (let key in data.roles) {
						if (data.roles.hasOwnProperty(key)) {
							foo.roles.push(data.roles[key]);
						}
					}
				}

				if (foo.hasOwnProperty('replacements')) {
					foo.replacements.map((item) => {
						if (item.hasOwnProperty('replacedUser') && item.replacedUser.hasOwnProperty('id')) {
							item.replacedUser = item.replacedUser.id;
						}
					});
				}

				setPerson(foo);
			}
		}

		fetchPerson();
	}, [id]);

	function handleSubmit(values, bag) {
		let toSave = {};
		Object.keys(values).map(function(item) {
			if (person[item] !== values[item]) {
				toSave[item] = values[item];
			}
		});

		if (toSave.hasOwnProperty('department')) {
			toSave['department'] = parseInt(toSave['department']);
		}
		if (toSave.hasOwnProperty('position')) {
			toSave['position'] = parseInt(toSave['position']);
		}

		let promisesArray = [];

		if (toSave.hasOwnProperty('replacements')) {
			promisesArray.concat(saveExistingReplacements(toSave));
		}
		if (toSave.hasOwnProperty('newReplacements')) {
			promisesArray.concat(saveNewReplacements(toSave));
		}
		promisesArray.concat(deleteExistingReplacements());

		Promise.all(promisesArray).then(() => {
			manageClients.current.save(person.id, () => {
				axios.patch(API_URL + '/profile/' + id, toSave)
					.then(function(response) {
						bag.setSubmitting(false);
						//console.log(response);
						history.goBack();
					}).catch(function(error) {
						formikErrorHelper(error, bag);
					});
			});
		});
	}

	function handleReplacementDelete(id) {
		if (replacementsToDelete.indexOf(id) === -1) {
			setReplacementsToDelete([id, ...replacementsToDelete]);
		}
	}

	function saveNewReplacements(toSend, bag) {
		let promisesArray = [];

		toSend.newReplacements.map((item) => {
			item.user = id;

			if (typeof item.dateFrom == 'object') { //moment
				item.dateFrom = item.dateFrom.format('YYYY-MM-DD HH:mm:ss');
			}

			if (typeof item.dateTo == 'object') { //moment
				item.dateTo = item.dateTo.format('YYYY-MM-DD HH:mm:ss');
			}

			promisesArray.push(
				axios.post(API_URL + '/replacement/create', item)
			);
		});
		delete toSend.newReplacements;

		return promisesArray;
	}

	function saveExistingReplacements(toSend, bag) {
		let promisesArray = [];

		toSend.replacements.map((item) => {
			let replacementData = objectClone(item);
			delete replacementData.id;

			if (typeof replacementData.dateFrom == 'object') { //moment
				replacementData.dateFrom = replacementData.dateFrom.format('YYYY-MM-DD HH:mm:ss');
			}

			if (typeof replacementData.dateTo == 'object') { //moment
				replacementData.dateTo = replacementData.dateTo.format('YYYY-MM-DD HH:mm:ss');
			}

			promisesArray.push(
				axios.patch(API_URL + '/replacement/' + item.id, replacementData)
			);
		});
		delete toSend.replacements;

		return promisesArray;
	}

	function deleteExistingReplacements() {
		let promisesArray = [];

		replacementsToDelete.map((id) => {
			promisesArray.push(
				axios.delete(API_URL + '/replacement/' + id)
			);
		});

		return promisesArray;
	}

	return (
		<>
			<Helmet>
				<title>{props.title ? props.title + ' - ' + APP_NAME : APP_NAME}</title>
			</Helmet>
			{isEmptyObject(person) ? <div className="textCenter"><Spinner/></div> : renderFormik()}
		</>
	);

	function renderFormik() {
		let initial = objectClone(person);
		delete initial.id;
		delete initial.clients;
		initial.newReplacements = [];

		return (
			<Formik
				initialValues={initial}
				validate={validateProfile}
				onSubmit={handleSubmit}
			>
				{(propsFormik) => {
					const {values, touched, errors, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched, status} = propsFormik;

					return (
						<Form>
							<div className="headerWithButtons">
								<h1>{props.title}</h1>
								<div className="right">
									<BackButton>Anuluj</BackButton>
									<button type="submit" disabled={isSubmitting} className="left32 withChevron">
										Zapisz&nbsp;<i className="fas fa-chevron-right"/>
									</button>
								</div>
							</div>

							<div className="box displayInlineBlock">
								<h3 className="top0 bottom32">Zdjęcie</h3>
								<div className="padLeft16 padRight16">
									<AvatarUpload picture={person.picture} onSuccess={(value) => setFieldValue('picture', value.id)}/>
								</div>
							</div>

							<div className="box">
								<h3 className="top0 bottom32">{props.subTitle}</h3>
								<ProfileForm access={access} {...propsFormik}/>
							</div>

							{/*<Dump value={values}/>*/}

							{status && <div className="errorMessage">{status}</div>}

							<ManageClients ref={manageClients} value={person.clients.map(function(item) { return item.id})}/>

							<FieldArray
								name="replacements"
								render={(arrayHelpers) => {
									return (
										<span>
											{values.replacements.map((data, i) => {
												if (replacementsToDelete.indexOf(data.id) === -1) {
													return (
														<ManageReplacements
															setFieldValue={setFieldValue}
															arrayHelpers={arrayHelpers}
															values={values}
															data={data}
															key={i}
															index={i}
															name="replacements"
															handleDelete={handleReplacementDelete}
														/>
													)
												}

												return null;
											})}
										</span>
									);
								}}
							/>

							<ProfileFormNewContact {...propsFormik}/>
						</Form>
					)
				}}
			</Formik>
		);
	}
}
