
export function isPushNotificationSupported() {
	return "serviceWorker" in navigator && "PushManager" in window;
}

// asks user consent to receive push notifications and returns the response of the user, one of granted, default, denied
export async function askUserPermission() {
	return await Notification.requestPermission();
}

export function registerServiceWorker() {
	return navigator.serviceWorker.register("sw.js");
}

// using the registered service worker creates a push notification subscription and returns it
export async function createUserSubscription() {
	//wait for service worker installation to be ready
	const serviceWorker = await navigator.serviceWorker.ready;
	// subscribe and return the subscription
	return await serviceWorker.pushManager.subscribe({
		userVisibleOnly: true,
		applicationServerKey: urlBase64ToUint8Array(KEY_NOTIFICATIONS)
	});
}

// returns the subscription if present or nothing
export function getUserSubscription() {
	//wait for service worker installation to be ready, and then
	return navigator.serviceWorker.ready
		.then(function(serviceWorker) {
			return serviceWorker.pushManager.getSubscription();
		})
		.then(function(pushSubscription) {
			return pushSubscription;
		});
}

export function urlBase64ToUint8Array(base64String) {
	let padding = '='.repeat((4 - base64String.length % 4) % 4);
	let base64 = (base64String + padding)
		.replace(/-/g, '+')
		.replace(/_/g, '/');

	let rawData = window.atob(base64);
	let outputArray = new Uint8Array(rawData.length);

	for (let i = 0; i < rawData.length; ++i) {
		outputArray[i] = rawData.charCodeAt(i);
	}
	return outputArray;
}