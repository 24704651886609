import React, {useEffect} from 'react';
import Select from "./select";
import {Option} from "rc-select";
import {useDispatch, useSelector} from "react-redux";
import {actionFetchPeople} from "../state/actionsPeople";
import Avatar from "../component/avatar";

export default function SelectPerson(props) {
	const dispatcher = useDispatch();

	const people = useSelector(state => state.people.list);
	const peopleLoading = useSelector(state => state.people.loading);

	useEffect(() => {
		if (peopleLoading === false && (people == null || people.length === 0)) {
			dispatcher(actionFetchPeople());
		}
	}, []);

	let {dispatch, mode, ...otherProps} = props;

	return (
		<Select
			className="person"
			loading={peopleLoading}
			optionLabelProp="label"
			showSearch={true}
			mode={mode}
			{...otherProps}
		>
			{people && people.map(function(item) {
				let label = (<><Avatar picture={item.picture} cssClass="noShadow"/> {item.firstName} {item.lastName}</>);
				let labelSmall = (<><Avatar size="small" picture={item.picture} cssClass="noShadow"/> {item.firstName} {item.lastName}</>);
				return (<Option value={parseInt(item.id)} key={'optionPerson' + item.id} label={mode === 'multiple' ? labelSmall : label}>{labelSmall}</Option>);
			})}
		</Select>
	);
}
