import {FETCH_TAGS_BEGIN, FETCH_TAGS_FAILURE, FETCH_TAGS_SUCCESS} from './actionsTags';

const reducerTags = (state = {list: [], loading: false}, action) => {

	switch (action.type) {
		case FETCH_TAGS_BEGIN:
			return {
				...state,
				loading: true,
				error: null // also, reset any errors
			};

		case FETCH_TAGS_SUCCESS:
			// replace the items with the ones from the server
			return {
				...state,
				loading: false,
				list: action.list,
			};

		case FETCH_TAGS_FAILURE:
			// save the error
			return {
				...state,
				error: action.error,
				loading: false,
				list: []
			};

		default:
			return state
	}
};

export default reducerTags