import React, {useContext, useEffect, useState} from 'react'
import KnowledgeRow from "./knowledgeRow";
import Sortable from "../component/sortable";
import Spinner from "../component/spinner";
import PaginationLocale from 'rc-pagination/lib/locale/pl_PL';
import Pagination from 'rc-pagination';
import ReactModal from "react-modal";
import axios from "axios";
import KnowledgeAdd from "./knowledgeAdd";
import KnowledgeEdit from "./knowledgeEdit";
import KnowledgeView from "./knowledgeView";
import FilterKnowledgeTypes from "./filterKnowledgeTypes";
import {AccessContext} from "../access";

export default function KnowledgeList(props) {
	const access = useContext(AccessContext);

	const perPage = 10;
	const [currentPage, setCurrentPage] = useState(1);
	const [count, setCount] = useState(1);

	const [search, setSearch] = useState("");
	const [knowledge, setKnowledge] = useState(null);
	const [knowledgeId, setKnowledgeId] = useState(null);
	const [filterKnowledgeType, setFilterKnowledgeType] = useState(null);

	const [orderColumn, setOrderColumn] = useState('modifiedDate');
	const [orderDirection, setOrderDirection] = useState(true);

	const [showModalAdd, setShowModalAdd] = useState(false);
	const [showModalEdit, setShowModalEdit] = useState(false);
	const [showModalView, setShowModalView] = useState(false);

	async function fetchKnowledge() {
		let toSend = {
			page: currentPage,
			perPage: perPage
		};

		if (search) {
			toSend.search = search;
		}

		if (orderColumn && orderDirection !== null) {
			toSend.sort = orderColumn;
			if (orderDirection === true) {
				toSend.order = 'desc';
			}
			if (orderDirection === false) {
				toSend.order = 'asc';
			}
		}

		if (filterKnowledgeType) {
			toSend["filter[type]"] = filterKnowledgeType;
		}

		const response = await axios.get(API_URL + '/knowledgeBase/list', {params: toSend});

		let data = response.data.data;
		//console.log(data);
		if (Array.isArray(data.list)) {
			setKnowledge(data.list);
			setCount(data.count);
		}
	}

	useEffect(() => {
		fetchKnowledge();
	}, [search, filterKnowledgeType, currentPage, perPage, orderDirection, orderColumn]);

	function handleSortChange(column, direction) {
		setOrderDirection(direction);
		setOrderColumn(column);
	}

	function onPageChange(page) {
		setCurrentPage(page);
	}

	function handleCloseModalView() {
		setShowModalView(false);
	}

	function handleOpenModalView(id) {
		setKnowledgeId(id);
		setShowModalView(true);
	}

	function handleCloseModalEdit() {
		setShowModalEdit(false);
	}

	function handleOpenModalEdit(id) {
		setKnowledgeId(id);
		setShowModalEdit(true);
	}

	function handleCloseModalAdd() {
		setShowModalAdd(false);
	}

	function handleRefresh() {
		fetchKnowledge();
	}

	return (
		<div>
			<div className="headerWithButtons">
				<div className="left">
					<h1>Baza wiedzy</h1>
					{access.manager === false ? '' : (
						<button className="left24 withChevron" onClick={() => setShowModalAdd(true)}>
							Dodaj&nbsp;<i className="fas fa-plus"/>
						</button>
					)}
				</div>
				<div className="right">
					<input type="search" className="pill" placeholder="Szukaj" value={search} onChange={event => setSearch(event.target.value) }/>
				</div>
			</div>

			<FilterKnowledgeTypes onChange={value => setFilterKnowledgeType(value)} />

			<div className="boxTable">
				<div className="boxHeader">
					<div className="boxCell"><Sortable onChange={handleSortChange} index="name" value={(orderColumn === 'name') ? orderDirection : null}>Nazwa</Sortable></div>
					<div className="boxCell"><Sortable onChange={handleSortChange} index="type" value={(orderColumn === 'type') ? orderDirection : null}>Typ</Sortable></div>
					<div className="boxCell"><Sortable onChange={handleSortChange} index="department" value={(orderColumn === 'department') ? orderDirection : null}>Dział</Sortable></div>
					<div className="boxCell"><Sortable onChange={handleSortChange} index="modifiedDate" value={(orderColumn === 'modifiedDate') ? orderDirection : null}>Data aktualizacji</Sortable></div>
					<div className="boxCell"><Sortable onChange={handleSortChange} index="modifiedBy" value={(orderColumn === 'modifiedBy') ? orderDirection : null}>Przez kogo</Sortable></div>
					<div className="boxCell"/>
				</div>

				{knowledge && knowledge.map(function(item, index) {
					return [
						<KnowledgeRow
							key={'knowledge' + index}
							value={item}
							handleOpenModalEdit={handleOpenModalEdit}
							handleOpenModalView={handleOpenModalView}
							refresh={handleRefresh}
						/>,
						<div key={'spacer' + index} className="boxRow spacer"/>
					];
				})}
			</div>

			{(knowledge === null) ? <div className="textCenter"><Spinner/></div> : ''}

			<div className="textCenter">
				<Pagination
					onChange={onPageChange}
					pageSize={perPage}
					defaultCurrent={1}
					current={currentPage}
					total={count}
					//showTotal={(total) => `Total ${total} items`}
					prevIcon={<i className="fas fa-chevron-left"/>}
					nextIcon={<i className="fas fa-chevron-right"/>}
					locale={PaginationLocale}
				/>
			</div>

			{/*<Dump value={count}/>*/}
			{/*<Dump label="column" value={orderColumn}/>*/}
			{/*<Dump label="direction" value={orderDirection}/>*/}
			{/*<Dump label="filters" value={filterKnowledgeType}/>*/}

			<ReactModal
				isOpen={showModalAdd}
				contentLabel=""
				onRequestClose={handleCloseModalAdd}
				shouldCloseOnOverlayClick={true}
				className="rcModal withActions bigger"
				overlayClassName="rcOverlay"
			>
				<span onClick={handleCloseModalAdd} className="rcModalClose"><i className="fas fa-times"/> Zamknij</span>

				<KnowledgeAdd handleRefresh={handleRefresh} handleClose={handleCloseModalAdd}/>
			</ReactModal>

			<ReactModal
				isOpen={showModalEdit}
				contentLabel=""
				onRequestClose={handleCloseModalEdit}
				shouldCloseOnOverlayClick={true}
				className="rcModal withActions bigger"
				overlayClassName="rcOverlay"
			>
				<span onClick={handleCloseModalEdit} className="rcModalClose"><i className="fas fa-times"/> Zamknij</span>

				<KnowledgeEdit handleRefresh={handleRefresh} handleClose={handleCloseModalEdit} knowledgeId={knowledgeId} />
			</ReactModal>

			<ReactModal
				isOpen={showModalView}
				contentLabel=""
				onRequestClose={handleCloseModalView}
				shouldCloseOnOverlayClick={true}
				className="rcModal withActions bigger"
				overlayClassName="rcOverlay"
			>
				<span onClick={handleCloseModalView} className="rcModalClose"><i className="fas fa-times"/> Zamknij</span>

				<KnowledgeView handleClose={handleCloseModalView} knowledgeId={knowledgeId} />
			</ReactModal>
		</div>
	);
}
