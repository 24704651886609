import axios from "axios";

export const FETCH_COMPANIES_BEGIN = 'FETCH_COMPANIES_BEGIN';
export const FETCH_COMPANIES_SUCCESS = 'FETCH_COMPANIES_SUCCESS';
export const FETCH_COMPANIES_FAILURE = 'FETCH_COMPANIES_FAILURE';

export function actionFetchCompaniesBegin() {
	return {
		type: FETCH_COMPANIES_BEGIN
	};
}

export function actionFetchCompaniesSuccess(data) {
	return {
		type: FETCH_COMPANIES_SUCCESS,
		list: data
	};
}

export function actionFetchCompaniesFailure(error) {
	console.log('Error ', error);
	return {
		type: FETCH_COMPANIES_FAILURE,
		error: error
	};
}

export function actionFetchCompanies() {
	return dispatch => {
		dispatch(actionFetchCompaniesBegin());

		return axios.get(API_URL + '/definition/company/list')
			.then(function(response) {
				if (Array.isArray(response.data.data.list)) {
					dispatch(actionFetchCompaniesSuccess(response.data.data.list));
				} else {
					dispatch(actionFetchCompaniesFailure());
				}
			}).catch(function(error) {
				dispatch(actionFetchCompaniesFailure(error));
			});
	};
}