import React, {useEffect} from 'react';
import Select from "./select";
import {Option} from "rc-select";
import {connect, useDispatch} from "react-redux";
import {actionFetchTaskStatuses} from "../state/actionsTaskStatuses";
import {getStatusColor} from "../project/projectUtils";

const mapStateToProps = function(state) {
	return {
		taskStatuses: state.taskStatuses.list,
		loading: state.taskStatuses.loading,
	};
};

function SelectTaskStatus(props) {
	const dispatcher = useDispatch();

	useEffect(() => {
		if (props.loading === false && (props.taskStatuses == null || props.taskStatuses.length === 0)) {
			dispatcher(actionFetchTaskStatuses());
		}
	}, []);

	let {taskStatuses, dispatch, ...otherProps} = props;

	return (
		<Select
			loading={props.loading}
			optionLabelProp="label"
			{...otherProps}
		>
			{props.taskStatuses && props.taskStatuses.map(function(item) {
				let color = getStatusColor(item.id);
				let labelSmall = (<div><span className="colorDot small" style={{backgroundColor: color}}/>{item.name}</div>);
				let label = (<div><span className="colorDot" style={{backgroundColor: color}}/>{item.name}</div>);
				return <Option value={parseInt(item.id)} key={'taskStatus' + item.id} label={labelSmall}>{labelSmall}</Option>
			})}
		</Select>
	);
}

export default connect(mapStateToProps)(SelectTaskStatus);