import React from 'react';
import {ErrorMessage, Form, Formik} from "formik";
import SelectPerson from "../form/selectPerson";
import CheckBox from "../form/checkBox";
import {ClientStatuses} from '../state/actionsClientStatuses';
import Field from "../form/field";
import SelectProjectType from "../form/selectProjectType";
import SelectAgreementTypes from "../form/selectAgreementTypes";

export default function FilterClient(props) {
	const statuses=ClientStatuses
	function handleSubmit(values) {
		if (typeof props.onChange === 'function') {
			props.onChange(values);
		}
		if (typeof props.handleClose === 'function') {
			props.handleClose();
		}
	}

	return (
		<Formik
			initialValues={props.filters ? props.filters : {}}
			onSubmit={handleSubmit}
		>
			{(propsFormik) => {
				const {values, touched, errors, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, resetForm, setFieldValue, setFieldTouched} = propsFormik;

				function handleMultipleCheckboxChange(name, value) {
					let newValues = [];
					if (values.hasOwnProperty(name) && Array.isArray(values[name])) {
						newValues = [...values[name]];
					}
					if (newValues.indexOf(value) === -1) {
						newValues.push(value);
					} else {
						newValues.splice(newValues.indexOf(value), 1);
					}
					setFieldValue(name, newValues);
				}
				return (
					<Form>
						<div className="rcModelContents">
							<h3 className="top0 bottom24">Filtry</h3>
							{
								console.log(props)
							}
							<div className="row with two columns">
								<div className="formItem column">
									<label htmlFor="name">Nazwa</label>
									<Field className="fullWidth" type="tel" name="name"/>
									<ErrorMessage component="div" className="errorMessage" name="name"/>
								</div>
								<div className="formItem column">
									<label htmlFor="nip">NIP</label>
									<Field className="fullWidth" type="tel" name="nip"/>
									<ErrorMessage component="div" className="errorMessage" name="nip"/>
								</div>
							</div>
							<div className="formItem">
								<label htmlFor="projectTypes" className="bottom10">Usługa</label>
								<SelectProjectType
									name="projectTypes"
									onChange={setFieldValue}
									onBlur={setFieldTouched}
									value={values.projectTypes}
									className="fullWidth"
									mode="multiple"
								/>
							</div>
							<div className="formItem">
								<label htmlFor="persons" className="bottom10">Opiekun</label>
								<SelectPerson
									name="persons"
									onChange={setFieldValue}
									onBlur={setFieldTouched}
									value={values.persons}
									className="fullWidth"
									mode="multiple"
								/>
							</div>
							<div className="formItem">
								<label htmlFor="agreement" className="bottom10">Zgoda klienta</label>
								<SelectAgreementTypes
									name="agreement"
									onChange={setFieldValue}
									onBlur={setFieldTouched}
									value={values.persons}
									className="fullWidth"
									mode="multiple"
								/>
							</div>

							<div className="formItem">
							<label htmlFor="status" className="bottom10">Status</label>
							{statuses && statuses.map(function(item,key) {
								return (
									<div className="displayInlineBlock right16 bottom20" key={"status" + item}>
										<CheckBox
											name="status"
											defaultChecked={false}
											value={key}
											checked={values.status && values.status.includes(key)}
											onChange={handleMultipleCheckboxChange}
											onBlur={setFieldTouched}
										>
											{item}
										</CheckBox>
									</div>
								);
							})}
							</div>
							<div className="textCenter bottom20 top20">
								<a href="#" className="blue" onClick={(event) => {
									event.preventDefault();

									if (typeof props.onChange === 'function') {
										props.onChange({});
									}
									if (typeof props.handleClose === 'function') {
										props.handleClose();
									}
								}}>Wyczyść filtry</a>
							</div>

							{/*<Dump value={values}/>*/}
							{/*<Dump value={propsFormik}/>*/}
						</div>

						<div className="rcModalActions">
							<button>Zapisz</button>
						</div>
					</Form>
				)
			}}
		</Formik>
	);
}
