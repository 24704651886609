import React, {useEffect} from 'react';
import Select from "./select";
import {Option} from "rc-select";
import {connect, useDispatch} from "react-redux";
import {IssueTypes} from '../state/actionsIssueTypes';

const mapStateToProps = function(state) {
	return {
		types: IssueTypes,
	};
};

function SelectIssueType(props) {

	let {types,  ...otherProps} = props;

	return (
		<Select
			loading={props.loading}
			showSearch={true}
			showArrow={true}
			{...otherProps}
		>
			{props.types && props.types.map((item, index) => (
				<Option value={parseInt(item.id)} key={'type' + item.id}>{item.name}</Option>
			))}
		</Select>
	);
}

export default connect(mapStateToProps)(SelectIssueType);