import React, {useEffect, useImperativeHandle, forwardRef} from 'react';
import Select from "./select";
import {Option} from "rc-select";
import {connect, useDispatch} from "react-redux";
import {actionFetchClients} from "../state/actionsClients";

const mapStateToProps = function(state) {
	return {
		clients: state.clients.list,
		loading: state.clients.loading,
	};
};

const SelectOfferClient = forwardRef( (props, ref) =>  {
	const dispatcher = useDispatch();

	useEffect(() => {
		if (props.loading === false && (props.clients == null || props.clients.length === 0)) {
			dispatcher(actionFetchClients());
		}
	}, []);

	useImperativeHandle(ref, () => ({
		updateClientsList() {
			console.log('update clients list');
			dispatcher(actionFetchClients());
		}
	}))

	let {clients, dispatch, ...otherProps} = props;

	useEffect(() => {
		console.log('value has cheange');
	}, [props.value]);

	return (
		<Select
			loading={props.loading}
			optionLabelProp="label"
			showSearch={true}
			{...otherProps}
		>
			{props.clients && props.clients.map((item, index) => {
				let color = (item.color ? item.color : 'grey');
				let labelSmall = (<div><span className="colorDot small" style={{backgroundColor: color}}/>{item.name}</div>);
				let label = (<div><span className="colorDot" style={{backgroundColor: color}}>{item.name.charAt(0)}</span>{item.name}</div>);
				return (<Option value={parseInt(item.id)} key={'optionClient' + item.id} label={labelSmall}>{labelSmall}</Option>);
			})}
		</Select>
	);
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(SelectOfferClient);