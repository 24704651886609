import React from "react"
import {isColorDark} from "../utils";

export default function Pill(props) {

	let bgColor = (props.color ? props.color : '#eeeeee');
	let color = '#273142';
	if (isColorDark(bgColor)) {
		color = '#ffffff';
	}

	return ((props.value) ? <span className="pill" style={{backgroundColor: bgColor, color: color}}>{props.value}</span> : <></>);
}