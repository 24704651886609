import React, {useEffect} from 'react';
import Select from "./select";
import {Option} from "rc-select";
import {connect, useDispatch} from "react-redux";
import {actionFetchReceptionTypes} from "../state/actionsReceptionTypes";

const mapStateToProps = function(state) {
	return {
		receptionTypes: state.receptionTypes.list,
		loading: state.receptionTypes.loading,
	};
};

function SelectReceptionType(props) {
	const dispatcher = useDispatch();

	useEffect(() => {
		if (props.loading === false && (props.receptionTypes == null || props.receptionTypes.length === 0)) {
			dispatcher(actionFetchReceptionTypes());
		}
	}, []);

	let {receptionTypes, dispatch, ...otherProps} = props;

	return (
		<Select
			loading={props.loading}
			{...otherProps}
		>
			{props.receptionTypes && props.receptionTypes.map(function(item) {
				return <Option value={parseInt(item.id)} key={'receptionTypes' + item.id}>{item.name}</Option>
			})}
		</Select>
	);
}

export default connect(mapStateToProps)(SelectReceptionType);