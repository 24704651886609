import {FETCH_CALENDAR_TYPES_BEGIN, FETCH_CALENDAR_TYPES_SUCCESS, FETCH_CALENDAR_TYPES_FAILURE} from './actionsCalendarTypes';

const reducerCalendarTypes = (state = {list: [], listVacation: [], loading: false}, action) => {

	switch (action.type) {
		case FETCH_CALENDAR_TYPES_BEGIN:
			return {
				...state,
				loading: true,
				error: null, // also, reset any errors
			};

		case FETCH_CALENDAR_TYPES_SUCCESS:
			// replace the items with the ones from the server
			return {
				...state,
				loading: false,
				list: action.list,
				listVacation: action.listVacation,
			};

		case FETCH_CALENDAR_TYPES_FAILURE:
			// save the error
			return {
				...state,
				error: action.error,
				loading: false,
				list: [],
			};

		default:
			return state
	}
};

export default reducerCalendarTypes