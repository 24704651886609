import React, {useEffect} from 'react';
import Select from "./select";
import {Option} from "rc-select";
import {connect, useDispatch} from "react-redux";
import {actionFetchProvinces} from "../state/actionsProvinces";

const mapStateToProps = function(state) {
	return {
		provinces: state.provinces.list,
		loading: state.provinces.loading,
	};
};

function SelectProvince(props) {
	const dispatcher = useDispatch();

	useEffect(() => {
		if (props.loading === false && (props.provinces == null || props.provinces.length === 0)) {
			dispatcher(actionFetchProvinces());
		}
	}, []);

	let {provinces, dispatch, ...otherProps} = props;

	return (
		<Select
			loading={props.loading}
			placeholder="Wybierz województwo"
			{...otherProps}
		>
			{props.provinces && props.provinces.map(function(item) {
				return <Option value={parseInt(item.id)} key={'province' + item.id}>{item.name}</Option>
			})}
		</Select>
	);
}

export default connect(mapStateToProps)(SelectProvince);