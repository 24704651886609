import React, {useEffect} from 'react';
import Select from "./select";
import {Option} from "rc-select";
import {connect, useDispatch} from "react-redux";
import {actionFetchProjectTypes} from "../state/actionsProjectTypes";

const mapStateToProps = function(state) {
	return {
		projectTypes: state.projectTypes.list,
		loading: state.projectTypes.loading,
	};
};

function SelectProjectType(props) {
	const dispatcher = useDispatch();

	useEffect(() => {
		if (props.loading === false && (props.projectTypes == null || props.projectTypes.length === 0)) {
			dispatcher(actionFetchProjectTypes());
		}
	}, []);

	let {projectTypes, dispatch, ...otherProps} = props;
	let data = props.projectTypes;

	return (
		<Select
			loading={props.loading}
			{...otherProps}
		>
			{data && data.map(function(item) {
				let label = (<div><span className="colorDot small" style={{backgroundColor: item.color}}/>{item.name}</div>);
				return (<Option value={parseInt(item.id)} key={'projectType' + item.id} label={label}>{label}</Option>);
			})}
		</Select>
	);
}

export default connect(mapStateToProps)(SelectProjectType);