export const ProjectStatuses = [
    {
        id: 0,
        name: 'Oczekujące',
    },
    {
        id: 1,
        name: 'Do realizacji',
    },
    {
        id: 2,
        name: 'W realizacji',
    },
    {
        id: 9,
        name: 'Zrealizowany',
    },
    {
        id: 10,
        name: 'Anulowany',
    }
]