import React from 'react'
import axios from 'axios'
import {Form, Formik} from "formik"
import {useHistory} from "react-router-dom"
import BackButton from "../component/backButton";
import {objectClone} from "../utils";
import GuestForm, {validateGuest} from "./guestForm";
import {formikErrorHelper} from "../form/formUtils";
import moment from "moment";

export default function GuestAdd(props) {
	const history = useHistory();

	function handleSubmit(values, bag) {
		let toSave = objectClone(values);

		if (toSave.hasOwnProperty('client') && toSave.client) {
			toSave.client = parseInt(toSave.client);
			if (toSave.hasOwnProperty('clientName')) {
				delete toSave.clientName;
			}
		}

		if (toSave.hasOwnProperty('clientName') && toSave.clientName != "") {
			if (toSave.hasOwnProperty('client')) {
				delete toSave.client;
			}
		}

		if (toSave.hasOwnProperty('user')) {
			toSave['user'] = parseInt(toSave['user']);
		}

		toSave.category = "guest";

		axios.post(API_URL + '/reception/create', toSave)
			.then(function(response) {
				bag.setSubmitting(false);
				history.goBack();
			}).catch(function(error) {
				formikErrorHelper(error, bag);
			});
	}

	return (
		<Formik
			initialValues={{
				fullName: "",
				client: null,
				clientName: "",
				operationDate: moment(),
				user: null,
				description: "",
			}}
			validate={validateGuest}
			onSubmit={handleSubmit}
		>
			{(propsFormik) => {
				const {values, touched, errors, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched, status} = propsFormik;

				return (
					<Form>
						<div className="headerWithButtons">
							<h1>Dodaj gościa</h1>
							<div className="right">
								<BackButton>Anuluj</BackButton>
								<button type="submit" disabled={isSubmitting} className="left32 withChevron">
									Dodaj&nbsp;<i className="fas fa-chevron-right"/>
								</button>
							</div>
						</div>

						<div className="box">
							<GuestForm {...propsFormik}/>
						</div>

						{status && <div className="errorMessage">{status}</div>}

						{/*<Dump value={values} />*/}
					</Form>
				)
			}}
		</Formik>
	);
}
