import React from 'react';
import Select from "./select";
import {Option} from "rc-select";

export default function SelectRole(props) {

	let {tags, dispatch, ...otherProps} = props;

	return (
		<Select
			mode="multiple"
			{...otherProps}
		>
			<Option value="ROLE_ADMIN">Admin</Option>
			<Option value="ROLE_MANAGER">Manager</Option>
			<Option value="ROLE_USER">User</Option>
			<Option value="ROLE_RECEPTION">Reception</Option>
		</Select>
	);
}
