import React, {useEffect} from 'react';
import Select from "./select";
import {Option} from "rc-select";
import {connect, useDispatch} from "react-redux";
import {actionFetchProcedures} from "../state/actionsProcedures";

const mapStateToProps = function(state) {
	return {
		procedures: state.procedures.list,
		loading: state.procedures.loading,
	};
};

function SelectProcedure(props) {
	const dispatcher = useDispatch();

	useEffect(() => {
		if (props.loading === false && (props.procedures == null || props.procedures.length === 0)) {
			dispatcher(actionFetchProcedures());
		}
	}, []);

	let {procedures, dispatch, ...otherProps} = props;

	return (
		<Select
			loading={props.loading}
			{...otherProps}
		>
			{props.procedures && props.procedures.map(function(item) {
				return <Option value={parseInt(item.id)} key={'procedures' + item.id}>{item.name}</Option>
			})}
		</Select>
	);
}

export default connect(mapStateToProps)(SelectProcedure);