import React, {Component} from 'react'
import {render, unmountComponentAtNode} from 'react-dom'

export default class ConfirmAlert extends Component {

	static defaultProps = {
		buttons: [
			{
				label: 'Cancel',
				className: null,
				onClick: () => null
			},
			{
				label: 'Confirm',
				className: null,
				onClick: () => null
			}
		],
		childrenElement: () => null,
		closeOnClickOutside: true,
		closeOnEscape: true,
		willUnmount: () => null,
		onClickOutside: () => null,
		onKeypressEscape: () => null
	};

	handleClickButton = button => {
		if (button.onClick) {
			button.onClick();
		}
		this.close();
	};

	handleClickOverlay = e => {
		const isClickOutside = e.target === this.overlay;

		if (this.props.closeOnClickOutside && isClickOutside) {
			this.props.onClickOutside();
			this.close();
		}
	};

	close = () => {
		removeBodyClass();
		removeElementReconfirm();
	};

	keyboardClose = event => {
		const isKeyCodeEscape = event.keyCode === 27;

		if (this.props.closeOnEscape && isKeyCodeEscape) {
			this.props.onKeypressEscape(event);
			this.close();
		}
	};

	componentDidMount = () => {
		document.addEventListener('keydown', this.keyboardClose, false);
	};

	componentWillUnmount = () => {
		document.removeEventListener('keydown', this.keyboardClose, false);
		this.props.willUnmount();
	};

	renderCustomUI = () => {
		const {title, message, customUI} = this.props;
		const dataCustomUI = {
			title,
			message,
			onClose: this.close
		};

		return customUI(dataCustomUI);
	};

	render() {
		return (
			<div
				className='react-confirm-alert-overlay'
				ref={dom => (this.overlay = dom)}
				onClick={this.handleClickOverlay}
			>
				<div className='react-confirm-alert'>
					{this.props.customUI ? (
						this.renderCustomUI()
					) : (
						<div className='react-confirm-alert-body'>
							{this.props.title && <h3>{this.props.title}</h3>}
							{this.props.message}
							{this.props.childrenElement()}
							<div className='react-confirm-alert-button-group'>
								{this.props.buttons.map((button, i) => (
									<button key={i} onClick={() => this.handleClickButton(button)} className={button.className}>
										{button.label}
									</button>
								))}
							</div>
						</div>
					)}
				</div>
			</div>
		)
	}
}

function createElementReconfirm(properties) {
	let divTarget = document.getElementById('react-confirm-alert');
	if (divTarget) {
		// Rerender - the mounted ReactConfirmAlert
		render(<ConfirmAlert {...properties} />, divTarget);
	} else {
		// Mount the ReactConfirmAlert component
		document.body.children[0].classList.add('react-confirm-alert-blur');
		divTarget = document.createElement('div');
		divTarget.id = 'react-confirm-alert';
		document.body.appendChild(divTarget);
		render(<ConfirmAlert {...properties} />, divTarget);
	}
}

function removeElementReconfirm() {
	const target = document.getElementById('react-confirm-alert');
	unmountComponentAtNode(target);
	target.parentNode.removeChild(target);
}

function addBodyClass() {
	document.body.classList.add('react-confirm-alert-body-element');
}

function removeBodyClass() {
	document.body.classList.remove('react-confirm-alert-body-element');
}

export function confirmAlert(properties) {
	addBodyClass();
	createElementReconfirm(properties);
}
