import axios from "axios";

export const FETCH_POSITIONS_BEGIN = 'FETCH_POSITIONS_BEGIN';
export const FETCH_POSITIONS_SUCCESS = 'FETCH_POSITIONS_SUCCESS';
export const FETCH_POSITIONS_FAILURE = 'FETCH_POSITIONS_FAILURE';

export function actionFetchPositionsBegin() {
	return {
		type: FETCH_POSITIONS_BEGIN
	};
}

export function actionFetchPositionsSuccess(data) {
	return {
		type: FETCH_POSITIONS_SUCCESS,
		list: data
	};
}

export function actionFetchPositionsFailure(error) {
	console.log('Error ', error);
	return {
		type: FETCH_POSITIONS_FAILURE,
		error: error
	};
}

export function actionFetchPositions() {
	return dispatch => {
		dispatch(actionFetchPositionsBegin());

		return axios.get(API_URL + '/definition/position/list')
			.then(function(response) {
				if (Array.isArray(response.data.data.list)) {
					dispatch(actionFetchPositionsSuccess(response.data.data.list));
				} else {
					dispatch(actionFetchPositionsFailure());
				}
			}).catch(function(error) {
				dispatch(actionFetchPositionsFailure(error));
			});
	};
}