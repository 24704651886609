import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactModal from 'react-modal';
import CalendarAbsenceAdd from "./calendarAbsenceAdd";
import CalendarDaily from "../component/calendar/calendarDaily";
import {addDays, endOfMonth, startOfMonth, parseISO, getDate, format, isWithinInterval} from "date-fns";
import MonthSelection from "../component/calendar/monthSelection";
import {getMonthName} from "../utils";
import CalendarMonthly from "../component/calendar/calendarMonthly";

export default function CalendarEventPage(props) {
	const [data, setData] = useState([]);
	const [prepared, setPrepared] = useState({});
	const [currentMonth, setCurrentMonth] = useState(new Date());
	const [currentDay, setCurrentDay] = useState(currentMonth);
	const [showModal, setShowModal] = useState(false);
	const [mode, setMode] = useState('monthly');

	useEffect(() => {
		async function fetchCalendars() {
			const response = await axios(API_URL + '/calendar/list');
			setData(response.data.data.list);
		}

		fetchCalendars();
	}, []);

	useEffect(() => {
		setPrepared(prepareData(data));
	}, [data, currentMonth]);

	function prepareData(data) {
		let toReturn = {};

		let day = startOfMonth(currentMonth);
		const monthEnd = endOfMonth(day);

		while (day <= monthEnd) {
			//console.log(day);
			toReturn[getDate(day)] = data.filter(function(item) {
				return isWithinInterval(day, { start: parseISO(item.dateFrom), end: parseISO(item.dateTo) })
			});

			day = addDays(day, 1);
		}

		return toReturn;
	}

	function onMonthChange(date) {
		setCurrentMonth(date);
	}

	function onDayChange(date) {
		setCurrentDay(date);
	}

	function handleCloseModal() {
		setShowModal(false);
	}

	function handleModeChange() {
		if (mode === 'monthly') {
			setMode('daily');
		} else {
			setMode('monthly');
		}
	}

	function renderDaily() {
		return (
			<div className="calendarDailyPage">
				<div className="calendarWrapper">
					<MonthSelection value={currentMonth} onDateChange={onMonthChange} />
					<CalendarDaily value={prepared} currentMonth={currentMonth} selectedDate={currentDay} onDateChange={onDayChange}/>
				</div>

				<div className="boxBorder">
					<header>
						<button className="floatRight plus" onClick={(event) => {}}><i className="fas fa-plus"/></button>
						<h3>{format(currentDay, 'd')} {getMonthName(format(currentDay, 'MM'))} {format(currentDay, 'yyyy')}</h3>
					</header>
					<main>
						{prepared && prepared.hasOwnProperty(getDate(currentDay)) && prepared[getDate(currentDay)].map(function(item) {
							return (
								<div
									className="calendarDailyEntry"
									key={'calEntry' + item.id}
									style={{backgroundColor: (item.type && item.type.color) ? item.type.color : '#666'}}
								>
									{item.type && item.type.name}
								</div>
							);
						})}
					</main>
				</div>
				{/*<Dump value={currentDay}/>*/}
			</div>
		);
	}

	function renderMonthly() {
		return (
			<div className="calendarMonthlyPage">
				<div className="calendarWrapper">
					<MonthSelection value={currentMonth} onDateChange={onMonthChange} />
					<CalendarMonthly value={prepared} currentMonth={currentMonth} selectedDate={currentDay} onDateChange={onDayChange}/>
				</div>
				{/*<Dump value={prepared}/>*/}
				{/*<Dump value={currentDay}/>*/}
			</div>
		);
	}

	return (
		<div>
			<div className="headerWithButtons">
				<h1>Kalendarz</h1>
				<div className="right">
					<button onClick={handleModeChange} className="button">
						{mode === 'monthly' ? 'Widok dzienny' : 'Widok miesiąca'}
					</button>
					<button onClick={() => setShowModal(true)} className="button withChevron left12">
						Dodaj wydarzenie&nbsp;<i className="fas fa-chevron-right"/>
					</button>
				</div>
			</div>

			{mode === 'daily' ? renderDaily() : renderMonthly()}

			<ReactModal
				isOpen={showModal}
				contentLabel=""
				onRequestClose={handleCloseModal}
				shouldCloseOnOverlayClick={true}
				className="rcModal"
				overlayClassName="rcOverlay"
			>
				<div className="rcModelContents">
					<CalendarAbsenceAdd closeModal={handleCloseModal}/>
					<span onClick={handleCloseModal} className="rcModalClose">&#10006;</span>
				</div>
			</ReactModal>
		</div>
	);
}
