import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Form, Formik} from "formik";
import {isEmptyObject, objectClone} from "../../utils";
import Spinner from "../../component/spinner";
import AdminPositionForm, {validatePosition} from "./adminPositionForm";
import {formikErrorHelper} from "../../form/formUtils";

export default function AdminPositionEdit(props) {

	const [item, setItem] = useState({});

	useEffect(() => {
		async function fetchPosition() {
			const response = await axios(API_URL + '/definition/position/' + props.itemId);
			//console.log(response.data.data);

			let position = response.data.data.position;
			if (position) {
				let foo = objectClone(position);

				if (position.hasOwnProperty('color') === false) {
					foo.color = '#FF0000';
				}

				setItem(foo);
			}
		}   

		fetchPosition();
	}, [props.itemId]);

	function handleSubmit(values, bag) {
		let toSave = {};

		Object.keys(values).map(function(index) {
			if (item[index] !== values[index]) {
				toSave[index] = values[index];
			}
		});

		axios.patch(API_URL + '/definition/position/' + props.itemId, toSave)
			.then(function(response) {
				bag.setSubmitting(false);
				//console.log(response);
				if (typeof props.handleRefresh === 'function') {
					props.handleRefresh();
				}
				if (typeof props.handleClose === 'function') {
					props.handleClose();
				}
			}).catch(function(error) {
				formikErrorHelper(error, bag);
			});
	}

	return (isEmptyObject(item) ? <div className="textCenter top96"><Spinner/></div> : renderFormik());

	function renderFormik() {
		return (
			<Formik
				initialValues={objectClone(item)}
				validate={validatePosition}
				onSubmit={handleSubmit}
			>
				{(propsFormik) => {
					const {values, touched, errors, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched} = propsFormik;

					return (
						<Form>
							<div className="rcModelContents">
								<h3 className="top0 bottom24">Edytuj stanowisko</h3>

								<AdminPositionForm {...propsFormik}/>

								{status && <div className="errorMessage">{status}</div>}

								{/*<Dump label="formik" value={propsFormik}/>*/}

								<div className="rcModalActions">
									<button type="submit" disabled={isSubmitting}>Zapisz</button>
								</div>
							</div>
						</Form>
					)
				}}
			</Formik>
		);
	}
}
