import axios from "axios";

export const FETCH_PEOPLE_BEGIN = 'FETCH_PEOPLE_BEGIN';
export const FETCH_PEOPLE_SUCCESS = 'FETCH_PEOPLE_SUCCESS';
export const FETCH_PEOPLE_FAILURE = 'FETCH_PEOPLE_FAILURE';

export function actionFetchPeopleBegin() {
	return {
		type: FETCH_PEOPLE_BEGIN
	};
}

export function actionFetchPeopleSuccess(data) {
	return {
		type: FETCH_PEOPLE_SUCCESS,
		list: data
	};
}

export function actionFetchPeopleFailure(error) {
	console.log('Error ', error);
	return {
		type: FETCH_PEOPLE_FAILURE,
		error: error
	};
}

export function actionFetchPeople() {
	return dispatch => {
		dispatch(actionFetchPeopleBegin());

		return axios.get(API_URL + '/profile/list')
			.then(function(response) {
				if (Array.isArray(response.data.data.list)) {
					dispatch(actionFetchPeopleSuccess(response.data.data.list));
				} else {
					dispatch(actionFetchPeopleFailure());
				}
			}).catch(function(error) {
				dispatch(actionFetchPeopleFailure(error));
			});
	};
}