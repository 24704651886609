import {createHashHistory} from "history";
import {applyMiddleware, compose, createStore} from "redux";
import reducerRoot from "./app/state/reducerRoot";
import thunk from "redux-thunk";
import {routerMiddleware} from "connected-react-router";

export const history = createHashHistory();

const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export default function configureStore(preloadedState) {
	return createStore(
		reducerRoot(history),
		preloadedState,
		composeEnhancers(applyMiddleware(thunk), applyMiddleware(routerMiddleware(history)))
		//applyMiddleware(thunk)
	);
}