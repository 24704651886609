import axios from "axios";

export const FETCH_AGREEMENT_TYPES_BEGIN = 'FETCH_AGREEMENT_TYPES_BEGIN';
export const FETCH_AGREEMENT_TYPES_SUCCESS = 'FETCH_AGREEMENT_TYPES_SUCCESS';
export const FETCH_AGREEMENT_TYPES_FAILURE = 'FETCH_AGREEMENT_TYPES_FAILURE';

export function actionFetchAgreementTypesBegin() {
	return {
		type: FETCH_AGREEMENT_TYPES_BEGIN
	};
}

export function actionFetchAgreementTypesSuccess(data) {
	return {
		type: FETCH_AGREEMENT_TYPES_SUCCESS,
		list: data
	};
}

export function actionFetchAgreementTypesFailure(error) {
	console.log('Error ', error);
	return {
		type: FETCH_AGREEMENT_TYPES_FAILURE,
		error: error
	};
}

export function actionFetchAgreementTypes() {
	return dispatch => {
		dispatch(actionFetchAgreementTypesBegin());

		return axios.get(API_URL + '/definition/agreementType/list')
			.then(function(response) {
				if (Array.isArray(response.data.data.list)) {
					dispatch(actionFetchAgreementTypesSuccess(response.data.data.list));
				} else {
					dispatch(actionFetchAgreementTypesFailure());
				}
			}).catch(function(error) {
				dispatch(actionFetchAgreementTypesFailure(error));
			});
	};
}