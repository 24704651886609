import React, {useEffect, useState} from 'react';
import OfferRow from "./offerRow";
import FilterOffer from "./filterOffer";
import Sortable from "../component/sortable";
import Spinner from "../component/spinner";
import axios from "axios";
import {Link} from "react-router-dom";
import {connect, useDispatch} from "react-redux";
import ReactModal from "react-modal";
import Dump from "../component/dump";
import {getNameFromId, objectClone} from "../utils";
import moment from "moment";
// import {actionFetchTaskStatuses} from "../state/actionsTaskStatuses";
import { actionFetchPeople } from "../state/actionsPeople";
import { actionFetchClients } from "../state/actionsClients";
import { actionFetchProjectTypes } from "../state/actionsProjectTypes";
import { OfferStatuses } from '../state/actionsOffer';

import PaginationLocale from 'rc-pagination/lib/locale/pl_PL';
import Pagination from 'rc-pagination';

const mapStateToProps = function(state) {
  return {
    projectTypes: state.projectTypes.list,
    projectTypesLoading: state.projectTypes.loading,
    clients: state.clients.list,
    clientsLoading: state.clients.loading,
    people: state.people.list,
    peopleLoading: state.people.loading,
    statuses: OfferStatuses,
    statusesLoading: false,
  };
};


const OfferList = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.projectTypesLoading === false && (props.projectTypes == null || props.projectTypes.length === 0)) {
      dispatch(actionFetchProjectTypes());
    }
    if (props.peopleLoading === false && (props.people == null || props.people.length === 0)) {
      dispatch(actionFetchPeople());
    }
    if (props.clientsLoading === false && (props.clients == null || props.clients.length === 0)) {
      dispatch(actionFetchClients());
    }
  }, []);

  const perPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [offersCount, setOffersCount] = useState(1);

  const [filters, setFilters] = useState({});
  const [search, setSearch] = useState("");
  const [offers, setOffers] = useState(null);
  const [offerId, setOfferId] = useState(null);

  const [orderColumn, setOrderColumn] = useState('created');
  const [orderDirection, setOrderDirection] = useState(true);

  const [showModalFilters, setShowModalFilters] = useState(false);

  const fetchOffers = async () => {
    let toSend = {
      page: currentPage,
      perPage: perPage
    };

    if (search) {
      toSend.search = search;
    }

    if (filters.hasOwnProperty('client')) {
      toSend['filter[client]'] = filters.client;
    }
    if (filters.hasOwnProperty('seller')) {
      toSend['filter[seller]'] = filters.seller;
    }
    if (filters.hasOwnProperty('type')) {
      toSend['filter[type]'] = filters.type;
    }
    if (filters.hasOwnProperty('status')) {
      toSend['filter[status]'] = filters.status;
    }
    if (filters.hasOwnProperty('dateFrom')) {
      toSend['filter[dateFrom]'] = moment(filters.dateFrom).format('YYYY-MM-DD');
    }
    if (filters.hasOwnProperty('dateTo')) {
      toSend['filter[dateTo]'] = moment(filters.dateTo).format('YYYY-MM-DD');
    }

    if (orderColumn && orderDirection !== null) {
      toSend.sort = orderColumn;
      if (orderDirection === true) {
        toSend.order = 'desc';
      }
      if (orderDirection === false) {
        toSend.order = 'asc';
      }
    }

    const response = await axios.get(API_URL + '/offer/list', {params: toSend});

    let data = response.data.data;
    if (Array.isArray(data.list)) {
      setOffers(data.list);
      setOffersCount(data.count);
    }
  }

  useEffect(() => {
    fetchOffers();
  }, [search, currentPage, perPage, orderDirection, orderColumn, filters]);

  const handleSortChange = (column, direction) => {
    setOrderDirection(direction);
    setOrderColumn(column);
  }

  const onPageChange = (page) => {
    setCurrentPage(page);
  }

  const handleCloseModalFilters = () => {
    setShowModalFilters(false);
  }

  const onFiltersChange = (newFilters) => {
    setFilters(newFilters);
  }

  const handleRefresh = () => {
    fetchOffers();
  }

  const getStatusName = (id) => {
    const names = props.statuses.filter(item => item.id == id);
    return names[0].name || '';
  }
  const getClientsName = (id) => {
    const names = props.clients.filter(item => item.id == id);
    return names[0].name || '';
  }
  const getSellerName = (id) => {
    const names = props.people.filter(item => item.id == id);
    return names[0].name || '';
  }
  const getTypeName = (id) => {
    const names = props.projectTypes.filter(item => item.id == id);
    return names[0].name || '';
  }

  return (
    <div>
      <div className="headerWithButtons">
        <div className="left">
          <h1>Oferty</h1>
          <Link to="/offer/add" className="button left32 withChevron">Dodaj&nbsp;<i className="fas fa-plus"/></Link>
          <button className="left24 grey withChevron" onClick={() => setShowModalFilters(true)}>
            Filtry&nbsp;<i className="fas"><span className="icon filter"/></i>
          </button>
        </div>
        <div className="right">
          <input type="search" className="pill" placeholder="Szukaj" value={search} onChange={event => setSearch(event.target.value) }/>
        </div>
      </div>
      <div className="filters">
      {filters.client &&
        <span className="filter">
          <i className="fas fa-times" onClick={() => {
            const newFilters = objectClone(filters);
            delete newFilters.client;
            setFilters(newFilters);
          }}/>
          Klient: {getClientsName(filters.client)}
        </span>
      }
      {filters.seller &&
        <span className="filter">
          <i className="fas fa-times" onClick={() => {
            const newFilters = objectClone(filters);
            delete newFilters.seller;
            setFilters(newFilters);
          }}/>
          Sprzedawca: {getSellerName(filters.seller)}
        </span>
      }
      {filters.type &&
        <span className="filter">
          <i className="fas fa-times" onClick={() => {
            const newFilters = objectClone(filters);
            delete newFilters.type;
            setFilters(newFilters);
          }}/>
          Usługa: {getTypeName(filters.type)}
        </span>
      }
      {filters.status &&
        <span className="filter">
          <i className="fas fa-times" onClick={() => {
            const newFilters = objectClone(filters);
            delete newFilters.status;
            setFilters(newFilters);
          }}/>
          Status: {getStatusName(filters.status)}
        </span>
      }
      </div>

      {/* <Dump value={props.clients} /> */}

      <div className="boxTable">
        <div className="boxHeader">
          <div className="boxCell"><Sortable onChange={handleSortChange} index="name" value={(orderColumn === 'name') ? orderDirection : null}>Nazwa Oferty</Sortable></div>
          <div className="boxCell"><Sortable onChange={handleSortChange} index="client" value={(orderColumn === 'client') ? orderDirection : null}>Klient</Sortable></div>
          <div className="boxCell"><Sortable onChange={handleSortChange} index="seller" value={(orderColumn === 'seller') ? orderDirection : null}>Sprzedawca</Sortable></div>
          <div className="boxCell"><Sortable onChange={handleSortChange} index="created" value={(orderColumn === 'created') ? orderDirection : null}>Data utworzenia</Sortable></div>
          <div className="boxCell"><Sortable onChange={handleSortChange} index="modified" value={(orderColumn === 'modified') ? orderDirection : null}>Data aktuaalizacji</Sortable></div>
          <div className="boxCell"><Sortable onChange={handleSortChange} index="submission" value={(orderColumn === 'submission') ? orderDirection : null}>Data złożenia</Sortable></div>
          <div className="boxCell">Status</div>
          <div className="boxCell"/>
        </div>

        {offers && offers.map(function(item, index) {
          return [
            <OfferRow
              key={'offer' + index}
              value={item}
              refresh={handleRefresh}
            />,
            <div key={'spacer' + index} className="boxRow spacer"/>
          ];
        })}
      </div>

      {(offers == null) ? <div className="textCenter"><Spinner/></div> : ''}

      <div className="textCenter">
        <Pagination
          onChange={onPageChange}
          pageSize={perPage}
          defaultCurrent={1}
          current={currentPage}
          total={offersCount}
          prevIcon={<i className="fas fa-chevron-left"/>}
          nextIcon={<i className="fas fa-chevron-right"/>}
          locale={PaginationLocale}
        />
      </div>

      <ReactModal
        isOpen={showModalFilters}
        contentLabel=""
        onRequestClose={handleCloseModalFilters}
        shouldCloseOnOverlayClick={true}
        className="rcModal withActions"
        overlayClassName="rcOverlay"
      >
        <span onClick={handleCloseModalFilters} className="rcModalClose"><i className="fas fa-times"/> Zamknij</span>

        <FilterOffer filters={filters} onChange={onFiltersChange} handleClose={handleCloseModalFilters}/>
      </ReactModal>

    </div>
  )
}


export default connect(mapStateToProps)(OfferList);