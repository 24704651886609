import React from 'react';
import RcCheckbox from "rc-checkbox";

export default function CheckBox(props) {

	function handleChange(event) {
		let value = event.target.checked;

		if (typeof props.value !== 'undefined') {
			value = props.value;
		}

		if (typeof props.onChange === 'function') {
			props.onChange(props.name, value);
		}
	}

	function handleBlur() {
		if (typeof props.onBlur === 'function') {
			props.onBlur(props.name);
		}
	}

	let {onBlur, onChange, children, ...otherProps} = props;

	return (
		<label>
			<RcCheckbox
				{...otherProps}
				onChange={handleChange}
				onBlur={handleBlur}
			/>
			&nbsp;&nbsp;{props.children}
		</label>
	);
}
