import React, {useEffect, useState} from 'react'
import axios from 'axios'
import {FieldArray, Form, Formik} from "formik"
import {useParams, useHistory} from "react-router-dom";
import BackButton from "../component/backButton";
import {isEmptyObject, objectClone} from "../utils";
import ManagePeople from "../form/managePeople";
import ClientPerson from "./clientPerson";
import ClientForm, {ClientFormNewContact, ClientFormNewAgreement, validateClient} from "./clientForm";
import {validateProfile} from "../profile/profileForm";
import Spinner from "../component/spinner";
import {formikErrorHelper, uploadFileHelper} from "../form/formUtils";
import ClientAgreementForm from "./clientAgreementForm";

import Dump from "../component/dump";

export default function ClientEdit(props) {
  const {id} = useParams();
  const history = useHistory();
  const managePeople = React.createRef();

  const [client, setClient] = useState({});

  useEffect(() => {
    async function fetchClient() {
      const response = await axios(API_URL + '/client/' + id);

      if (response.data.data.client) {
        let foo = objectClone(response.data.data.client);
        if (foo.hasOwnProperty('province') && foo.province.hasOwnProperty('id')) {
          foo.province = foo.province.id;
        }
        if (foo.hasOwnProperty('fvProvince') && foo.fvProvince.hasOwnProperty('id')) {
          foo.fvProvince = foo.fvProvince.id;
        }
        if (foo.hasOwnProperty('persons')) {
          foo.persons.map((item) => {
            if (item.hasOwnProperty('position') && item.position.hasOwnProperty('id')) {
              item.position = item.position.id;
            }
          });
        }
        if (foo.hasOwnProperty('tags')) {
          foo.tags = foo.tags.map((tag) => {
            if (tag.hasOwnProperty('id')) {
              tag = tag.id;
            }
            return tag;
          });
        }
        if (foo.hasOwnProperty('projectTypes')) {
          foo.projectTypes = foo.projectTypes.map((service) => {
            if (service.hasOwnProperty('id')) {
              service = service.id;
            }
            return service;
          });
        }
        if (response.data.data.client.hasOwnProperty('color') === false) {
          foo.color = '#FF0000';
        }
        if (foo.hasOwnProperty('agreements')) {
          foo.agreements.map((item) => {
            if (item.hasOwnProperty('type') && item.type.hasOwnProperty('id')) {
              item.type = item.type.id;
            }
          });
        }

        setClient(foo);
      }
    }

    fetchClient();
  }, [id]);

  function handleSubmit(values, bag) {
    let toSave = {};
    Object.keys(values).map(function(item) {
      if (client[item] !== values[item]) {
        toSave[item] = values[item];
      }
    });

    if (toSave.hasOwnProperty('province')) {
      toSave['province'] = parseInt(toSave['province']);
    }
    if (toSave.hasOwnProperty('fvProvince')) {
      toSave['fvProvince'] = parseInt(toSave['fvProvince']);
    }

    let savePromisesArray = [];

    const agreements = toSave.agreements;
    delete toSave.agreements;

    const newAgreements = toSave.newAgreements;
    delete toSave.newAgreements;

    if (toSave.hasOwnProperty('persons')) {
      savePromisesArray.concat(saveExistingClientPersons(toSave));
    }
    if (toSave.hasOwnProperty('newPeople')) {
      savePromisesArray.concat(saveNewClientPersons(toSave));
    }

    if (typeof newAgreements !== 'undefined') {
      savePromisesArray.concat(saveNewClientAgreements(newAgreements));
    }
    if (typeof agreements !== 'undefined') {
      savePromisesArray.concat(saveExistingClientAgreements(agreements));
    }

    Promise.all(savePromisesArray).then(() => {
      managePeople.current.save(id, () => {
        axios.patch(API_URL + '/client/' + id, toSave)
          .then(function(response) {
            bag.setSubmitting(false);
            history.goBack();
          }).catch(function(error) {
            formikErrorHelper(error, bag);
          });
      });
    });
  }

  function saveExistingClientPersons(toSave) {
    let savePromisesArray = [];

    toSave.persons.map((item) => {
      savePromisesArray.push(
        axios.patch(API_URL + '/clientPerson/' + item.id, item)
      );
    });
    delete toSave.persons;

    return savePromisesArray;
  }

  function saveNewClientPersons(toSave) {
    let savePromisesArray = [];

    toSave.newPeople.map((item) => {
      item.client = id;
      savePromisesArray.push(
        axios.post(API_URL + '/clientPerson/create', item)
      );
    });
    delete toSave.newPeople;

    return savePromisesArray;
  }

  const saveExistingClientAgreements = (toSave) => {
    const savePromisesArray = [];
    console.log(toSave);
    toSave.map((item) => {
      if (item.file instanceof File) {
        uploadFileHelper(item.file, (fileId) => {
            const fdata = {
              type: item.type,
              file: fileId,
              client: id,
            }
            savePromisesArray.push(
                axios.patch(API_URL + '/clientAgreement/edit/' + item.id, fdata)
            );
        })
      } else {
        const fdata = {
          type: item.type,
          file: item.file.id,
          client: id,
        }
        savePromisesArray.push(
          axios.patch(API_URL + '/clientAgreement/edit/' + item.id, fdata)
      );
      }
    });
  }

  const saveNewClientAgreements = (toSave) => {
    const savePromisesArray = [];

    toSave.map((item) => {
        item.client = id;
        uploadFileHelper(item.file, (fileId) => {
            const fdata = objectClone(item);
            fdata.file = fileId;
            savePromisesArray.push(
                axios.post(API_URL + '/clientAgreement/create', fdata)
            );
        })
    });
    return savePromisesArray;
  }


  function validate(values) {
    let errors = validateClient(values);

    errors.persons = [];
    values.persons.map((item, i) => {
      let result = validateProfile(item);

      if (result !== null && isEmptyObject(result) === false) {
        errors.persons[i] = result;
      }
    });
    // remove persons error object if all add new person forms passes validation
    if (errors.persons.length === 0) {
      delete errors.persons;
    }

    return errors;
  }

  return (isEmptyObject(client) ? <div className="textCenter"><Spinner/></div> : renderFormik());

  function renderFormik() {
    let initial = objectClone(client);
    delete initial.id;
    initial.newPeople = [];
    initial.newAgreements = [];
    // initial.agreements = ,
    return (
      <Formik
        // initialValues={initial}
        initialValues={{
          name: initial.name?initial.name:"",
          phone: initial.phone?initial.phone:"",
          email: initial.email?initial.email:"",
          city: initial.city?initial.city:"",
          street: initial.street?initial.street:"",
          postalCode: initial.postalCode?initial.postalCode:"",
          province: initial.province?initial.province:"",

          fvName: initial.fvName?initial.fvName:"",
          fvPhone: initial.fvPhone?initial.fvPhone:"",
          fvEmail: initial.fvEmail?initial.fvEmail:"",
          nip: initial.nip?initial.nip:"",
          fvCity: initial.fvCity?initial.fvCity:"",
          fvStreet: initial.fvStreet?initial.fvStreet:"",
          fvPostalCode: initial.fvPostalCode?initial.fvPostalCode:"",
          fvProvince: initial.fvProvince?initial.fvProvince:"",

          status: initial.status,
          projectTypes: initial.projectTypes,

          clientService:initial.clientService,
          newPeople: initial.newPeople,
          newPeopleInfo: [],
          persons: initial.persons,
          color: initial.color,
          newAgreements: initial.newAgreements,
          agreements: initial.agreements,
        }}

        validate={validate}
        onSubmit={handleSubmit}
      >
        {(propsFormik) => {
          const {values, touched, errors, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched, status} = propsFormik;
          return (
            <Form>
              <div className="headerWithButtons">
                <h1>Edytuj klienta</h1>
                <div className="right">
                  <BackButton>Anuluj</BackButton>
                  <button type="submit" disabled={isSubmitting} className="left32 withChevron">
                    Zapisz&nbsp;<i className="fas fa-chevron-right"/>
                  </button>
                </div>
              </div>

              <div className="box">
                <ClientForm {...propsFormik}/>
              </div>

              <div>
                <h3 className="top0 bottom24 displayInlineBlock">Osoby kontaktowe</h3>
                <FieldArray
                  name="persons"
                  render={(arrayHelpers) => {
                    return (
                      <span>
                        {values.persons.map((data, i) => (
                          <ClientPerson
                            positions={props.positions}
                            setFieldValue={setFieldValue}
                            arrayHelpers={arrayHelpers}
                            values={values}
                            data={data}
                            key={i}
                            index={i}
                            name="persons"
                          />
                        ))}
                      </span>
                    );
                  }}
                />
                <ClientFormNewContact {...propsFormik}/>
              </div>

              {status && <div className="errorMessage">{status}</div>}

              <ManagePeople ref={managePeople} value={client.clientService.map(function(item) { return item.id})}/>

              {/*<Dump value={values} />*/}

              <div>
                <h3 className="top0 bottom24 displayInlineBlock">Zgody klienta</h3>
                <FieldArray
                  name="agreements"
                  render={(arrayHelpers) => {
                    return (
                      <span>
                        {values.agreements.map((data, i) => (
                          <ClientAgreementForm
                            setFieldValue={setFieldValue}
                            arrayHelpers={arrayHelpers}
                            values={values}
                            data={data}
                            key={i}
                            index={i}
                            name="agreements"
                          />
                        ))}
                      </span>
                    );
                  }}
                />
                <ClientFormNewAgreement {...propsFormik}/>
              </div>

            </Form>
          )
        }}
      </Formik>
    );
  }
}
