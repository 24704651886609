import React, {useContext} from "react";
import PopupMenu from "../component/popupMenu";
import {parseISO} from 'date-fns'
import {confirmDelete, formatDate, getFilenameFromPath, getFileUrl, nl2br} from "../utils";
import axios from "axios";
import {AccessContext} from "../access";
import PillName from "../component/pillName";
import ReactTooltip from 'react-tooltip';

export default function DocumentRow(props) {
	let item = props.value;
	const access = useContext(AccessContext);

	function handleDelete() {
		axios.delete(API_URL + '/document/' + item.id)
			.then(function(response) {
				if (typeof props.refresh === 'function') {
					props.refresh();
				}
			});
	}

	function isEditable(item) {
		return access.admin || access.manager || access.id === item.user.id;
	}

	let filename = getFilenameFromPath(item.file.path);
	let fileUrl = getFileUrl(item.file.id, filename);
	filename = <>{filename}</>;

	return (
		<>
			<div className="boxRow">
				<div className="boxCell textBold">
					<a href={fileUrl} target="_blank">
						{(item.description) ? <span data-tip={nl2br(item.description)}>{filename}</span> : filename}
					</a>
				</div>
				<div className="boxCell"><PillName value={item.type}/></div>
				<div className="boxCell">{item.client ? item.client.name : ''}</div>
				<div className="boxCell">{formatDate(parseISO(item.modified))}</div>
				<div className="boxCell">{item.user.name}</div>
				<div className="boxCell textRight">
					<PopupMenu style={{right: '-12px'}}>
						<li><b><a href={fileUrl} target="_blank">Pobierz</a></b></li>
						{!isEditable(item) ? '' : <li onClick={() => props.handleOpenModalEdit(item.id)}>Edytuj</li>}
						{!isEditable(item) ? '' : <li onClick={() => confirmDelete(handleDelete)} className="red">Usuń</li>}
					</PopupMenu>
				</div>
			</div>
			<ReactTooltip place="bottom" multiline={true}/>
		</>
	);
}