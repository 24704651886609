import axios from 'axios';
import {actionAuthed, actionNotAuthed} from "./state/actionsAuth";
import { push } from 'connected-react-router';

const requestService = {

	setupInterceptors: (store) => {

		axios.interceptors.response.use(function(response) {
			// zwracamy odpowiedź jak nie ma błędów, i wysulamy akcje ze wiadomo ze uzytkownik jest zalogowany
			store.dispatch(actionAuthed());
			return response;
		}, function(error) {
			//console.log(error);
			//console.log(error.hasOwnProperty('response'));

			if (error.hasOwnProperty('response') && typeof error.response != 'undefined') {
				if (error.response.status === 401) {
					store.dispatch(actionNotAuthed())
					store.dispatch(push('/login'));
				}
				if (error.response.status === 403) {
					console.log(error);
				}
			}
			return Promise.reject(error);
		});

		axios.interceptors.request.use(function(config) {
			config.headers['access-token'] = store.getState().auth.accessToken;
			return config;
		});
	}
};

export default requestService;