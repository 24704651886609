import axios from "axios";

export const FETCH_PROCEDURES_BEGIN = 'FETCH_PROCEDURES_BEGIN';
export const FETCH_PROCEDURES_SUCCESS = 'FETCH_PROCEDURES_SUCCESS';
export const FETCH_PROCEDURES_FAILURE = 'FETCH_PROCEDURES_FAILURE';

export function actionFetchProceduresBegin() {
	return {
		type: FETCH_PROCEDURES_BEGIN
	};
}

export function actionFetchProceduresSuccess(data) {
	return {
		type: FETCH_PROCEDURES_SUCCESS,
		list: data
	};
}

export function actionFetchProceduresFailure(error) {
	console.log('Error ', error);
	return {
		type: FETCH_PROCEDURES_FAILURE,
		error: error
	};
}

export function actionFetchProcedures() {
	return dispatch => {
		dispatch(actionFetchProceduresBegin());

		return axios.get(API_URL + '/definition/procedure/list')
			.then(function(response) {
				if (Array.isArray(response.data.data.list.list)) {
					dispatch(actionFetchProceduresSuccess(response.data.data.list.list));
				} else {
					dispatch(actionFetchProceduresFailure());
				}
			}).catch(function(error) {
				dispatch(actionFetchProceduresFailure(error));
			});
	};
}