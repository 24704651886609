import React from "react"
import PopupMenu from "../component/popupMenu";
import {parseISO} from 'date-fns'
import {confirmDelete, findObjectWithPropertyValueInArray, formatDate} from "../utils";
import axios from "axios";
import {Link} from "react-router-dom";
import PillName from "../component/pillName";
import PillStatus from "../component/pillStatus";
import Dump from "../component/dump";

export default function OfferRow(props) {

	let item = props.value;

	if (item.services && Array.isArray(item.services) && item.services.length > 0) {
		item.service = item.services.pop();
		delete item.services;
	}

	function handleDelete() {
		axios.delete(API_URL + '/offer/' + item.id)
			.then(function(response) {
				if (typeof props.refresh === 'function') {
					props.refresh();
				}
			});
	}

	return (
		<div className="boxRow">
			<div className="boxCell textBold">
        <Link to={"/offer/view/" + item.id}>
					{item.name}
				</Link>
			</div>
			<div className="boxCell">{item.client ? item.client.name : ''}</div>
			<div className="boxCell">{item.seller ? item.seller.name : ''}</div>
			<div className="boxCell" style={{minWidth: 155}}>{formatDate(parseISO(item.created))}</div>
			<div className="boxCell" style={{minWidth: 155}}>{formatDate(parseISO(item.modified))}</div>
			<div className="boxCell" style={{minWidth: 155}}>{formatDate(parseISO(item.submission))}</div>
			<div className="boxCell" style={{minWidth: 160}}><PillStatus value={item.status}/></div>

			<div className="boxCell textRight padRight8">
				<PopupMenu>
					<li><Link to={"/offer/view/" + item.id}>Zobacz</Link></li>
					{[0,3].includes(item.status.id) &&
						<li><Link to={"/offer/edit/" + item.id}>Edytuj</Link></li>
					}
					<li onClick={() => confirmDelete(handleDelete)} className="red">Usuń</li>
				</PopupMenu>
			</div>
		</div>
	);
}