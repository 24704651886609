import React, {useEffect, useState} from 'react'
import ProjectRow from "./projectRow";
import Sortable from "../component/sortable";
import Spinner from "../component/spinner";
import PaginationLocale from 'rc-pagination/lib/locale/pl_PL';
import Pagination from 'rc-pagination';
import ReactModal from "react-modal";
import ProjectView from "./projectView";
import axios from "axios";
import {Link} from "react-router-dom";
import {connect, useDispatch} from "react-redux";
import {actionFetchTaskStatuses} from "../state/actionsTaskStatuses";
import {actionFetchPeople} from "../state/actionsPeople";
import {actionFetchClients} from "../state/actionsClients";
import {actionFetchCompanies} from "../state/actionsCompanies";
import FilterProject from "./filterProject";
import {getNameFromId, objectClone} from "../utils";
import moment from "moment";
import classNames from "classnames";
import Select from "../form/select";
import {Option} from "rc-select";

const mapStateToProps = function(state) {
	return {
		taskStatuses: state.taskStatuses.list,
		taskStatusesLoading: state.taskStatuses.loading,
		clients: state.clients.list,
		clientsLoading: state.clients.loading,
		companies: state.companies.list,
		companiesLoading: state.companies.loading,
		people: state.people.list,
		peopleLoading: state.people.loading,
	};
};

function ProjectList(props) {
	const dispatch = useDispatch();

	useEffect(() => {
		if (props.taskStatusesLoading === false && (props.taskStatuses == null || props.taskStatuses.length === 0)) {
			dispatch(actionFetchTaskStatuses());
		}
	}, []);

	useEffect(() => {
		if (props.peopleLoading === false && (props.people == null || props.people.length === 0)) {
			dispatch(actionFetchPeople());
		}
	}, []);

	useEffect(() => {
		if (props.clientsLoading === false && (props.clients == null || props.clients.length === 0)) {
			dispatch(actionFetchClients());
		}
	}, []);

	useEffect(() => {
		if (props.companiesLoading === false && (props.companies == null || props.companies.length === 0)) {
			dispatch(actionFetchCompanies());
		}
	}, []);

	const perPage = 10;
	const [currentPage, setCurrentPage] = useState(1);
	const [projectsCount, setProjectsCount] = useState(1);

	const [filters, setFilters] = useState({});
	const [search, setSearch] = useState("");
	const [projects, setProjects] = useState(null);
	const [projectId, setProjectId] = useState(null);

	const [orderColumn, setOrderColumn] = useState('created');
	const [orderDirection, setOrderDirection] = useState(true);

	const [showModalView, setShowModalView] = useState(false);
	const [showModalFilters, setShowModalFilters] = useState(false);

	const [showEndangered, setShowEndangered] = useState(false);
	const [endangeredDays, setEndangeredDays] = useState(14);

	async function fetchProjects() {
		let toSend = {
			page: currentPage,
			perPage: perPage
		};

		if (search) {
			toSend.search = search;
		}

		if (filters.hasOwnProperty('client')) {
			toSend['filter[client]'] = filters.client;
		}
		if (filters.hasOwnProperty('company')) {
			toSend['filter[company]'] = filters.company;
		}
		if (filters.hasOwnProperty('userModified')) {
			toSend['filter[userModified]'] = filters.userModified;
		}
		if (filters.hasOwnProperty('status')) {
			toSend['filter[status]'] = filters.status;
		}
		if (filters.hasOwnProperty('type')) {
			toSend['filter[type]'] = filters.type;
		}
		if (filters.hasOwnProperty('dateFrom')) {
			toSend['filter[dateFrom]'] = moment(filters.dateFrom).format('YYYY-MM-DD');
		}
		if (filters.hasOwnProperty('dateTo')) {
			toSend['filter[dateTo]'] = moment(filters.dateTo).format('YYYY-MM-DD');
		}
		if (filters.hasOwnProperty('completedFrom')) {
			toSend['filter[completedFrom]'] = moment(filters.completedFrom).format('YYYY-MM-DD');
		}
		if (filters.hasOwnProperty('completedTo')) {
			toSend['filter[completedTo]'] = moment(filters.completedTo).format('YYYY-MM-DD');
		}
		if (filters.hasOwnProperty('jobsEndedFrom')) {
			toSend['filter[jobsEndedFrom]'] = moment(filters.jobsEndedFrom).format('YYYY-MM-DD');
		}
		if (filters.hasOwnProperty('jobsEndedTo')) {
			toSend['filter[jobsEndedTo]'] = moment(filters.jobsEndedTo).format('YYYY-MM-DD');
		}
		if (showEndangered) {
			toSend['filter[finalDate]'] = 1;
			toSend['filter[dateRealizationLimit]'] = moment().add(endangeredDays, 'days').format('YYYY-MM-DD');
		}

		if (orderColumn && orderDirection !== null) {
			toSend.sort = orderColumn;
			if (orderDirection === true) {
				toSend.order = 'desc';
			}
			if (orderDirection === false) {
				toSend.order = 'asc';
			}
		}

		const response = await axios.get(API_URL + '/project/list', {params: toSend});

		let data = response.data.data.list;
		if (Array.isArray(data.list)) {
			setProjects(data.list);
			setProjectsCount(data.count);
		}
	}

	useEffect(() => {
		fetchProjects();
	}, [search, currentPage, perPage, orderDirection, orderColumn, filters, showEndangered, endangeredDays]);

	function handleSortChange(column, direction) {
		setOrderDirection(direction);
		setOrderColumn(column);
	}

	function onPageChange(page) {
		setCurrentPage(page);
	}

	function handleCloseModalFilters() {
		setShowModalFilters(false);
	}

	function handleCloseModalView() {
		setShowModalView(false);
	}

	function handleOpenModalView(id) {
		setProjectId(id);
		setShowModalView(true);
	}

	function onFiltersChange(newFilters) {
		setFilters(newFilters);
	}

	function handleRefresh() {
		fetchProjects();
	}

	return (
		<div>
			<div className="headerWithButtons">
				<div className="left">
					<h1>Projekty{showEndangered ? ' zagrożone' : ''}</h1>
					{showEndangered ?
						<Select
							style={{width: 80}}
							className="left24 noMinimumWidth"
							name="endangeredDays"
							onChange={(name, value) => setEndangeredDays(value)}
							value={endangeredDays}
						>
							<Option value={7}>7 dni</Option>
							<Option value={14}>14 dni</Option>
						</Select> : ''}
					<Link to="/project/add" className="button left32 withChevron">Dodaj&nbsp;<i className="fas fa-plus"/></Link>
					<button className="left24 grey withChevron" onClick={() => setShowModalFilters(true)}>
						Filtry&nbsp;<i className="fas"><span className="icon filter"/></i>
					</button>
				</div>
				<div className="right">
					<button className={classNames('right8', 'pill', (showEndangered ? 'green' : 'red'))} onClick={() => setShowEndangered(!showEndangered)}>
						{showEndangered ? 'Wszystkie' : 'Zagrożone'}
					</button>
					<input type="search" className="pill" placeholder="Szukaj" value={search} onChange={event => setSearch(event.target.value) }/>
				</div>
			</div>

			<div className="filters">
				{filters.userModified ? (
					<span className="filter">
						<i className="fas fa-times" onClick={() => {
							let newFilters = objectClone(filters);
							delete newFilters.userModified;
							setFilters(newFilters);
						}}/>
						{getNameFromId(props.people, filters.userModified)}
					</span>
				) : ''}

				{filters.client ? (
					<span className="filter">
						<i className="fas fa-times" onClick={() => {
							let newFilters = objectClone(filters);
							delete newFilters.client;
							setFilters(newFilters);
						}}/>
						{getNameFromId(props.clients, filters.client)}
					</span>
				) : ''}

				{filters.company ? (
					<span className="filter">
						<i className="fas fa-times" onClick={() => {
							let newFilters = objectClone(filters);
							delete newFilters.company;
							setFilters(newFilters);
						}}/>
						{getNameFromId(props.companies, filters.company)}
					</span>
				) : ''}
			</div>

			<div className="boxTable">
				<div className="boxHeader">
					<div className="boxCell"><Sortable onChange={handleSortChange} index="name" value={(orderColumn === 'name') ? orderDirection : null}>Nazwa Projektu</Sortable></div>
					<div className="boxCell"><Sortable onChange={handleSortChange} index="type" value={(orderColumn === 'type') ? orderDirection : null}>Typ usługi</Sortable></div>
					<div className="boxCell"><Sortable onChange={handleSortChange} index="client" value={(orderColumn === 'client') ? orderDirection : null}>Klient</Sortable></div>
					<div className="boxCell"><Sortable onChange={handleSortChange} index="company" value={(orderColumn === 'company') ? orderDirection : null}>Spółka</Sortable></div>
					<div className="boxCell"><Sortable onChange={handleSortChange} index="modifiedDate" value={(orderColumn === 'modifiedDate') ? orderDirection : null}>Data aktualizacji</Sortable></div>
					<div className="boxCell"><Sortable onChange={handleSortChange} index="modifiedBy" value={(orderColumn === 'modifiedBy') ? orderDirection : null}>Przez kogo</Sortable></div>
					<div className="boxCell"><Sortable onChange={handleSortChange} index="created" value={(orderColumn === 'created') ? orderDirection : null}>Utworzono</Sortable></div>
					<div className="boxCell"><Sortable onChange={handleSortChange} index="jobsEnded" value={(orderColumn === 'jobsEnded') ? orderDirection : null}>Zakończono</Sortable></div>
					<div className="boxCell"><Sortable onChange={handleSortChange} index="completed" value={(orderColumn === 'completed') ? orderDirection : null}>Zrealizowano</Sortable></div>
					<div className="boxCell">Status</div>
					<div className="boxCell"/>
				</div>

				{projects && projects.map(function(item, index) {
					return [
						<ProjectRow
							key={'project' + index}
							value={item}
							handleOpenModalView={handleOpenModalView}
							refresh={handleRefresh}
							taskStatuses={props.taskStatuses}
						/>,
						<div key={'spacer' + index} className="boxRow spacer"/>
					];
				})}
			</div>

			{(projects == null) ? <div className="textCenter"><Spinner/></div> : ''}

			<div className="textCenter">
				<Pagination
					onChange={onPageChange}
					pageSize={perPage}
					defaultCurrent={1}
					current={currentPage}
					total={projectsCount}
					//showTotal={(total) => `Total ${total} items`}
					prevIcon={<i className="fas fa-chevron-left"/>}
					nextIcon={<i className="fas fa-chevron-right"/>}
					locale={PaginationLocale}
				/>
			</div>

			{/*<Dump label="column" value={orderColumn}/>*/}
			{/*<Dump label="direction" value={orderDirection}/>*/}
			{/*<Dump label="filters" value={filters}/>*/}
			{/*<Dump label="showEndangered" value={showEndangered}/>*/}
			{/*<Dump label="endangeredDays" value={endangeredDays}/>*/}

			<ReactModal
				isOpen={showModalView}
				contentLabel=""
				onRequestClose={handleCloseModalView}
				shouldCloseOnOverlayClick={true}
				className="rcModal withActions bigger"
				overlayClassName="rcOverlay"
			>
				<span onClick={handleCloseModalView} className="rcModalClose"><i className="fas fa-times"/> Zamknij</span>

				<ProjectView handleClose={handleCloseModalView} projectId={projectId} />
			</ReactModal>

			<ReactModal
				isOpen={showModalFilters}
				contentLabel=""
				onRequestClose={handleCloseModalFilters}
				shouldCloseOnOverlayClick={true}
				className="rcModal withActions"
				overlayClassName="rcOverlay"
			>
				<span onClick={handleCloseModalFilters} className="rcModalClose"><i className="fas fa-times"/> Zamknij</span>

				<FilterProject filters={filters} onChange={onFiltersChange} handleClose={handleCloseModalFilters}/>
			</ReactModal>
		</div>
	);
}

export default connect(mapStateToProps)(ProjectList);