import React, {useEffect, useState} from "react"
import {addDays} from 'date-fns'
import axios from 'axios'
import ReactModal from "react-modal"
import CalendarAbsenceEdit from "./calendarAbsenceEdit";
import CalendarAbsenceAdd from "./calendarAbsenceAdd"
import CalendarAbsence from "../component/calendar/calendarAbsence";
import MonthSelection from "../component/calendar/monthSelection";
import FilterDepartments from "../component/filterDepartments";

export default function CalendarAbsencePage(props) {
	const [showModalAdd, setShowModalAdd] = useState(false);
	const [showModalEdit, setShowModalEdit] = useState(false);
	const [edit, setEdit] = useState(null);

	let [days, setDays] = useState(null);
	let [legend, setLegend] = useState({});
	let [currentMonth, setCurrentMonth] = useState(addDays(new Date(), 1));

	const [filterSelectedDepartment, setFilterSelectedDepartment] = useState(null);

	useEffect(() => {
		loadData(currentMonth.getFullYear(), currentMonth.getMonth() + 1);
	}, [currentMonth, filterSelectedDepartment]);

	function handleOpenModalEdit(item) {
		setEdit(item);
		setShowModalEdit(true);
	}

	function handleCloseModalEdit() {
		setShowModalEdit(false);
	}

	function handleCloseModalAdd() {
		setShowModalAdd(false);
	}

	function handleRefresh() {
		loadData(currentMonth.getFullYear(), currentMonth.getMonth() + 1);
	}

	function onMonthChange(date) {
		setCurrentMonth(date);
	}

	function loadData(year, month) {
		setDays([]);

		async function fetchData() {
			let toSend = {};

			if (filterSelectedDepartment) {
				toSend["filter[department]"] = [filterSelectedDepartment];
			}

			const response = await axios(API_URL + '/calendar/vacations', {params: toSend});

			let toReturn = [];
			let legendToReturn = {};

			let data = {};
			response.data.data.list.map(function(item) {
				if (item.user && item.user.hasOwnProperty('id')) {
					if (data.hasOwnProperty(item.user.id) === false) {
						data[item.user.id] = {user: item.user, monthlyData: []}
					}

					if (legendToReturn.hasOwnProperty(item.type.name) === false) {
						legendToReturn[item.type.name] = item.type.color;
					}

					data[item.user.id].monthlyData.push(item);
				}
			});

			Object.keys(data).map(function(key) {
				toReturn.push(data[key]);
			});

			setDays(toReturn);
			setLegend(legendToReturn);
		}

		fetchData();
	}

	return (
		<span>
			<div className="headerWithButtons">
				<h1>Kalendarz nieobecności</h1>
				<div className="right">
					<button onClick={function() {
						setShowModalAdd(true);
					}} className="button withChevron">Dodaj nieobecność&nbsp;<i className="fas fa-plus"/></button>
				</div>
			</div>

			<FilterDepartments onChange={value => setFilterSelectedDepartment(value)} />

			<MonthSelection value={currentMonth} onDateChange={onMonthChange}/>
			<CalendarAbsence value={days} currentDate={currentMonth} handleOpenModalEdit={handleOpenModalEdit}/>

			<div className="legend top24">
				{Object.keys(legend).map(function(item) {
					return (
						<span className="right16" key={item}>
							<span className="colorDot small" style={{backgroundColor: legend[item]}}/> {item}
						</span>
					);
				})}
			</div>

			<ReactModal
				isOpen={showModalAdd}
				contentLabel=""
				onRequestClose={handleCloseModalAdd}
				shouldCloseOnOverlayClick={true}
				className="rcModal"
				overlayClassName="rcOverlay"
			>
				<span onClick={handleCloseModalAdd} className="rcModalClose"><i className="fas fa-times"/> Zamknij</span>

				<CalendarAbsenceAdd handleRefresh={handleRefresh} handleClose={handleCloseModalAdd}/>
			</ReactModal>

			<ReactModal
				isOpen={showModalEdit}
				contentLabel=""
				onRequestClose={handleCloseModalEdit}
				shouldCloseOnOverlayClick={true}
				className="rcModal"
				overlayClassName="rcOverlay"
			>
				<span onClick={handleCloseModalEdit} className="rcModalClose"><i className="fas fa-times"/> Zamknij</span>

				<CalendarAbsenceEdit handleRefresh={handleRefresh} handleClose={handleCloseModalEdit} value={edit}/>
			</ReactModal>

			{/*<Dump value={edit}/>*/}
		</span>
	);
}
