import React, {useContext} from "react"
import {Link} from "react-router-dom"
import PopupMenu from "../component/popupMenu";
import axios from "axios";
import {AccessContext} from "../access";
import Avatar from "../component/avatar";
import ReactTooltip from "react-tooltip";
import {confirmDelete, nl2br} from "../utils";

export default function TeamMember(props) {
	const access = useContext(AccessContext);
	let person = props.person;

	function handleDelete() {
		axios.delete(API_URL + '/profile/' + person.id)
			.then(function(response) {
				if (typeof props.refresh === 'function') {
					props.refresh();
				}
			});
	}

	// let clients = '';
	// if (props.hasOwnProperty('showClients') && props.showClients !== false) {
	// 	clients = person.clients && person.clients.map(function(item, index) {
	// 		let color = (item.color ? item.color : 'grey');
	// 		return <div key={"client" + item.id} className="clientDot" style={{ backgroundColor: color}}>{item.name.charAt(0)}</div>
	// 	});
	// }

	function renderDetails() {
		let toReturn = (
			<>
				<Avatar picture={person.picture} size="large"/>
				<div className="name">{person.firstName + ' ' + person.lastName}</div>
				{person.position && person.position.path ? <div className="font14 bottom10">{person.position.path}</div> : ''}
				<div className="department">{(person.department && person.department.name) ? person.department.name : <>&nbsp;</>}</div>
			</>
		);

		if (props.hasOwnProperty('link') && props.link) {
			toReturn = <Link to={props.link}>{toReturn}</Link>
		}

		return toReturn;
	}

	return (
		<div className="teamMember">
			{!access.manager ? '' : (
				<PopupMenu>
					<li><Link to={"/team/edit/" + person.id}>Edytuj</Link></li>
					<li onClick={() => confirmDelete(handleDelete)} className="red">Usuń</li>
				</PopupMenu>
			)}

			{renderDetails()}

			<div className={'contact' + (props.showClients === false ? ' bottom0' : '')}>
				<a href={"mailto:" + person.email}>
					<div className="contactDot email" data-tip={nl2br(person.email)}><i className="fas fa-envelope"/></div><span> {person.email}</span>
				</a>
				<a href={'tel:' + person.phone}>
					<div className="contactDot phone" data-tip={nl2br(person.phone)}><i className="fas fa-phone"/></div><span> {person.phone}</span>
				</a>
			</div>

			<ReactTooltip place="bottom" multiline={true}/>

			{/*{clients}*/}
		</div>
	);
}