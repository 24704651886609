import React from 'react';
import axios from 'axios';
import {Form, Formik} from "formik";
import AdminProjectTypeForm, {validateProjectType} from "./adminProjectTypeForm";
import {formikErrorHelper} from "../../form/formUtils";

export default function AdminProjectTypeAdd(props) {

	function handleSubmit(values, bag) {
		axios.post(API_URL + '/definition/projectType/create', values)
			.then(function(response) {
				bag.setSubmitting(false);

				if (typeof props.handleRefresh === 'function') {
					props.handleRefresh();
				}
				if (typeof props.handleClose === 'function') {
					props.handleClose();
				}
			}).catch(function(error) {
				formikErrorHelper(error, bag);
			});
	}

	return (
		<Formik
			initialValues={{
				name: "",
				color: '#FF0000'
			}}
			validate={validateProjectType}
			onSubmit={handleSubmit}
		>
			{(propsFormik) => {
				const {values, touched, errors, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched} = propsFormik;

				return (
					<Form>
						<div className="rcModelContents">
							<h3 className="top0 bottom24">Dodaj nowy typ Usługi</h3>

							<AdminProjectTypeForm {...propsFormik}/>

							{/*<Dump label="formik" value={propsFormik}/>*/}

							{status && <div className="errorMessage">{status}</div>}

							<div className="rcModalActions">
								<button type="submit" disabled={isSubmitting}>Dodaj</button>
							</div>
						</div>
					</Form>
				)
			}}
		</Formik>
	);
}
