export const IssueTypes = [{
        name: 'Help desk - sprzęt',
        id: 1
    },
    {
        name: 'Help desk - uprawnienia',
        id: 2
    },
    {
        name: 'Help desk - inne',
        id: 3
    },
    {
        name: 'Oferty',
        id: 4
    },
    {
        name: 'Prezentacje',
        id: 5
    },
    {
        name: 'Umowy',
        id: 6
    },
    {
        name: 'Bazy danych',
        id: 7
    },
    {
        name: 'Rozliczenia klienta',
        id: 8
    },
    {
        name: 'Symulacje cen',
        id: 9
    },
    {
        name: 'Wyceny',
        id: 10
    },
    {
        name: 'Opinie/rekomendacje',
        id: 11
    },
    {
        name: 'Raporty',
        id: 12
    },
    {
        name: 'CRM',
        id: 13
    },
    {
        name: 'Skanowanie',
        id: 14
    },
    {
        name: 'Faktury',
        id: 15
    },
    {
        name: 'Obsługa klienta',
        id: 16
    },
    {
        name: 'Sprzedaż',
        id: 17
    },
    {
        name: 'Administracja',
        id: 18
    },
    {
        name: 'Inne',
        id: 0
    },
];