import React from 'react';
import ReactDOM from 'react-dom';

import App from './app/app';
import ReactModal from 'react-modal';

import {Provider} from 'react-redux';
import {HelmetProvider } from 'react-helmet-async';
import requestService from './app/requestService';
import configureStore from './configureStore';
import {isPushNotificationSupported, registerServiceWorker} from "./app/pushNotifications";

if (DEBUG === 'false') {
	console.log = function() {};
}

if (isPushNotificationSupported()) {
	registerServiceWorker().then(function(registration) {
		registration.update();
		console.log('sw update');
	});
}

const store = configureStore({auth: {accessToken: localStorage.getItem('accessToken'), isAuthenticated: null}});

requestService.setupInterceptors(store);

ReactModal.setAppElement('#root');
ReactDOM.render(<HelmetProvider><Provider store={store}><App/></Provider></HelmetProvider>, document.getElementById('root'));