import React, {useEffect, useState} from 'react'
import axios from 'axios'
import {Link} from "react-router-dom"
import TeamMember from "./teamMember"
import FilterDepartments from "../component/filterDepartments"
import {useDispatch, connect} from "react-redux"
import {actionFetchDepartments} from "../state/actionsDepartaments";
import {findObjectWithPropertyValueInArray, isEmptyObject} from "../utils";
import Spinner from "../component/spinner";

const mapStateToProps = function(state) {
	return {departments: state.departments.list};
};

function TeamStructure(props) {
	const [search, setSearch] = useState("");
	const [people, setPeople] = useState({});

	const [filterSelectedDepartment, setFilterSelectedDepartment] = useState(null);

	const dispatch = useDispatch();
	useEffect(() => {
		if (props.departments.length === 0) {
			dispatch(actionFetchDepartments());
		}
	}, []);


	async function fetchPeople() {
		const response = await axios.get(API_URL + '/profile/list', {params: {search: search}});
		//console.log(response);
		if (Array.isArray(response.data.data.list)) {
			let toReturn = {};

			props.departments.map(function(itemDepartment) {
				toReturn[itemDepartment.name] = response.data.data.list.filter(function(person) {
					//console.log((person.department && person.department.id === itemDepartment.id));

					if (person.department && person.department.id === itemDepartment.id) {
						return person;
					}
				});
			});

			toReturn['none'] = response.data.data.list.filter(function(person) {
				if (person.hasOwnProperty('department') === false || person.department === null || person.department === '') {
					return person;
				}
			});

			setPeople(toReturn);
		}
	}

	useEffect(() => {
		fetchPeople();
	}, [props.departments, search]);

	function renderDepartment(name, people) {
		if (people.length === 0) {
			return '';
		}

		let list = people.map(function(person) {
			return (
				<div className="column" key={"person" + person.id}>
					<TeamMember link={"/team/view/" + person.id} showClients={true} refresh={fetchPeople} person={person}/>
				</div>
			);
		});

		return (
			<span key={"department" + name}>
				<h2>{name !== 'none' ? name : 'Bez działu'}</h2>
				<div className="row with five columns">{list}</div>
			</span>
		);
	}

	return (
		<div>
			<div className="headerWithButtons">
				<div className="left">
					<h1>Struktura Firmy</h1>
				</div>

				<div className="right">
					<Link to="/team/calendar" className="button grey pill right8">Kalendarz nieobecności</Link>
					<input type="search" className="pill" placeholder="Szukaj" value={search} onChange={event => setSearch(event.target.value)}/>
				</div>
			</div>

			<FilterDepartments onChange={value => setFilterSelectedDepartment(value)}/>

			{Object.keys(people).filter(function(item) {
				//console.log(filterSelectedDepartment);
				if (filterSelectedDepartment === null) {
					return item;
				}

				let dept = findObjectWithPropertyValueInArray(props.departments, filterSelectedDepartment);
				// console.log(dept && dept.name === item);
				if (dept && dept.name === item) {
					return item;
				}
			}).map(function(item) {
				return renderDepartment(item, people[item]);
			})}

			{(isEmptyObject(people)) ? <div className="textCenter"><Spinner /></div> : ''}
		</div>
	);
}

export default connect(mapStateToProps)(TeamStructure);

