import axios from "axios";

export const FETCH_INTERVALS_BEGIN = 'FETCH_INTERVALS_BEGIN';
export const FETCH_INTERVALS_SUCCESS = 'FETCH_INTERVALS_SUCCESS';
export const FETCH_INTERVALS_FAILURE = 'FETCH_INTERVALS_FAILURE';

export function actionFetchIntervalsBegin() {
	return {
		type: FETCH_INTERVALS_BEGIN
	};
}

export function actionFetchIntervalsSuccess(data) {
	return {
		type: FETCH_INTERVALS_SUCCESS,
		list: data
	};
}

export function actionFetchIntervalsFailure(error) {
	console.log('Error ', error);
	return {
		type: FETCH_INTERVALS_FAILURE,
		error: error
	};
}

export function actionFetchIntervals() {
	return dispatch => {
		dispatch(actionFetchIntervalsBegin());

		return axios.get(API_URL + '/project/recurrentIntervals')
			.then(function(response) {
				//console.log(response.data);
				if (Array.isArray(response.data.data.list)) {
					dispatch(actionFetchIntervalsSuccess(response.data.data.list));
				} else {
					dispatch(actionFetchIntervalsFailure());
				}
			}).catch(function(error) {
				dispatch(actionFetchIntervalsFailure(error));
			});
	};
}