import React from "react";
import PopupMenu from "../component/popupMenu";
import {parseISO} from 'date-fns';
import {confirmDelete, formatDate} from "../utils";
import axios from "axios";
import PillStatus from "../component/pillStatus";
import {renderPeopleWithOverflow} from "./utilIssue";
import ReactTooltip from "react-tooltip";

export default function IssueRow(props) {

	let item = props.value;

	function handleDelete() {
		axios.delete(API_URL + '/issue/' + item.id)
			.then(function(response) {
				if (typeof props.refresh === 'function') {
					props.refresh();
				}
			});
	}

	return (
		<>
			<div className="boxRow">
				<div className="boxCell textBold">
					<a href="#" onClick={(event) => { event.preventDefault(); props.handleOpenModalView(item.id); }}>
						{item.name}
					</a>
				</div>
				<div className="boxCell">{item.client ? item.client.name : ''}</div>
				<div className="boxCell">{formatDate(parseISO(item.dateRealization))}</div>
				<div className="boxCell">{item.orderedBy ? item.orderedBy.name : ''}</div>
				<div className="boxCell">{renderPeopleWithOverflow(item.users)}</div>
				<div className="boxCell">{formatDate(parseISO(item.created))}</div>
				<div className="boxCell"><PillStatus value={item.status}/></div>

				<div className="boxCell textRight padRight8">
					<PopupMenu>
						<li onClick={() => props.handleOpenModalView(item.id)}>Zobacz</li>
						<li onClick={() => props.handleOpenModalEdit(item.id)}>Edytuj</li>
						<li onClick={() => confirmDelete(handleDelete)} className="red">Usuń</li>
					</PopupMenu>
				</div>
			</div>
			<ReactTooltip place="bottom" multiline={true}/>
		</>
	);
}