import React from 'react';
import {ErrorMessage} from "formik";
import Field from "../form/field";
import Select from "../form/select";
import {Option} from "rc-select";
import SelectClient from "../form/selectClient";
import SelectPerson from "../form/selectPerson";
import SelectReceptionType from "../form/selectReceptionType";
import SelectCompany from "../form/selectCompany";
import {getClientAddressById} from "./receptionUtils";
import DatePicker from "../form/datePicker";
import FileView from "../component/fileView";
import FileUpload from "../component/fileUpload";

export default function PackageForm(props) {

	const {values, touched, errors, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, handleReset, setFieldValue, setFieldTouched} = props;

	function onClientChange(name, value = null) {
		if (value != null) {
			getClientAddressById(value).then((result) => {
				setFieldValue('clientCity', result.city);
				setFieldValue('clientAddress', result.street);
				setFieldValue('clientPostal', result.postalCode);
			});
		}
		setFieldValue(name, value);
	}

	return (
		<>
			<div className="row with three columns">
				<div className="formItem column">
					<label htmlFor="description">Opis przesyłki</label>
					<Field className="fullWidth" type="text" name="description"/>
					<ErrorMessage component="div" className="errorMessage" name="description"/>
				</div>

				<div className="formItem column">
					<label htmlFor="type">Rodzaj</label>
					<SelectReceptionType
						className="fullWidth"
						name="type"
						onChange={setFieldValue}
						onBlur={setFieldTouched}
						value={values.type}
					/>
					<ErrorMessage component="div" className="errorMessage" name="type"/>
				</div>

				<div className="formItem column">
					<label htmlFor="direction">Typ przesyłki</label>
					<Select
						className="fullWidth"
						name="direction"
						onChange={setFieldValue}
						onBlur={setFieldTouched}
						value={values.direction}
					>
						<Option value="inbound">Przychodząca</Option>
						<Option value="outbound">Wychodząca</Option>
					</Select>
					<ErrorMessage component="div" className="errorMessage" name="direction"/>
				</div>

				<div className="formItem column">
					<label htmlFor="taskNumber">Numer nadania</label>
					<Field className="fullWidth" type="text" name="taskNumber"/>
					<ErrorMessage component="div" className="errorMessage" name="taskNumber"/>
				</div>

				<div className="formItem column">
					<label htmlFor="company">Spółka</label>
					<SelectCompany
						className="fullWidth"
						name="company"
						onChange={setFieldValue}
						onBlur={setFieldTouched}
						value={values.company}
					/>
					<ErrorMessage component="div" className="errorMessage" name="company"/>
				</div>

				<div className="formItem column">
					<label htmlFor="province">Od / do osoby w zespole</label>
					<SelectPerson
						className="fullWidth"
						name="user"
						onChange={setFieldValue}
						onBlur={setFieldTouched}
						value={values.user}
						allowClear={true}
					/>
					<ErrorMessage component="div" className="errorMessage" name="user"/>
				</div>

				<div className="formItem column">
					<label htmlFor="implementationDate">Data wysyłki / odbioru</label>
					<DatePicker
						name="operationDate"
						onChange={setFieldValue}
						onBlur={setFieldTouched}
						value={values.operationDate}
						className="fullWidth"
					/>
					<ErrorMessage component="div" className="errorMessage" name="operationDate"/>
				</div>
			</div>

			<h4>Wyszukaj nadawcę/odbiorcę w bazie Klientów lub wprowadź nowe dane:</h4>

			<div className="row with three columns top20">
				<div className="formItem column">
					<label htmlFor="client">Wyszukaj nazwę firmy</label>
					<SelectClient
						className="fullWidth"
						name="client"
						onChange={onClientChange}
						onBlur={setFieldTouched}
						value={values.client}
						allowClear={true}
						disabled={values.client == null && (values.clientName || values.clientCity || values.clientPostal || values.clientAddress)}
						placeholder="Szukaj"
					/>
					<ErrorMessage component="div" className="errorMessage" name="client"/>
				</div>

				<div className="formItem column">
					<label htmlFor="clientName">Wprowadź nazwę firmy</label>
					<Field className="fullWidth" type="text" name="clientName" disabled={values.client != null}/>
					<ErrorMessage component="div" className="errorMessage" name="clientName"/>
				</div>

				<div className="formItem column">
					<label htmlFor="clientAddress">Ulica</label>
					<Field className="fullWidth" type="text" name="clientAddress" disabled={values.client != null}/>
					<ErrorMessage component="div" className="errorMessage" name="clientAddress"/>
				</div>

				<div className="formItem column">
					<label htmlFor="clientCity">Miasto</label>
					<Field className="fullWidth" type="text" name="clientCity" disabled={values.client != null}/>
					<ErrorMessage component="div" className="errorMessage" name="clientCity"/>
				</div>

				<div className="formItem column">
					<label htmlFor="clientPostal">Kod pocztowy</label>
					<Field type="text" name="clientPostal" maxLength="6" style={{minWidth: 60}} disabled={values.client != null}/>
					<ErrorMessage component="div" className="errorMessage" name="clientPostal"/>
				</div>
			</div>

			<div className="row with four columns">
				{values.files && values.files.map(function(file, index) {
					return (
						<div className="formItem column" key={file.id}>
							{(index === 0) ? <label>Załączone pliki (np. instrukcje)</label> : <label>&nbsp;</label>}
							<FileView fileId={file.id} path={file.path} handleDelete={() => props.handleFileDelete(file.id, values.files, setFieldValue)}/>
						</div>
					);
				})}
				{props.uploadedFiles.map(function(file) {
					return (
						<div className="formItem column" key={file.id}>
							<label>&nbsp;</label>
							<FileView fileId={file.id} path={file.path} handleDelete={() => props.handleFileDelete(file.id, values.files, setFieldValue)}/>
						</div>
					)
				})}
			</div>

			<FileUpload handleFile={props.handleFileAdd}/>
		</>
	)
}

export function validatePackage(values) {
	let errors = {};

	if (!values.description) {
		errors.description = 'Opis przesyłki jest wymagany';
	}
	if (!values.direction) {
		errors.direction = 'Typ przesyłki jest wymagany';
	}
	if (!values.type) {
		errors.type = 'Rodzaj jest wymagany';
	}
	if (!values.company) {
		errors.company = 'Spółka jest wymagana';
	}


	if (values.clientName == "" && values.clientCity == "" && values.clientAddress == "" && values.clientPostal == "" && !values.client) {
		errors.client = 'Nadawca / Odbiorca jest wymagany';
	}

	if (values.client == null && values.clientName == "") {
		errors.clientName = 'Nazwa firmy jest wymagana';
	}
	if (values.client == null && values.clientCity == "") {
		errors.clientCity = 'Miasto jest wymagane';
	}
	if (values.client == null && values.clientAddress == "") {
		errors.clientAddress = 'Ulica jest wymagana';
	}
	if (values.client == null && values.clientPostal == "") {
		errors.clientPostal = 'Kod pocztowy jest wymagany';
	}

	return errors;
}