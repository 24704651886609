import React from "react";
import {Link} from "react-router-dom";

export default function PasswordRecoveryMessage(props) {

	return (
		<div className="loginWrapper">
			<h2>Przypomnij hasło</h2>
			<div className="loginBox">
				<h3>Na Twój adres e - mail wysłaliśmy wiadomość z instrukcją utworzenia nowego hasła.</h3>
			</div>
			<br/>
			<Link to="/login">&lt; Powrót</Link>
		</div>
	);
}
