import React from 'react';

export default function AdminServiceForm(props) {

	const {values, touched, errors, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, handleReset, setFieldValue, setFieldTouched} = props;

	return (
		<>
		</>
	)
}

export function validateService(values) {
	let errors = {};
	if (!values.name) {
		errors.name = 'Nazwa jest wymagana';
	}
	if (!values.department) {
		errors.department = 'Dział jest wymagany';
	}
	if (!values.type) {
		errors.type = 'Typ jest wymagany';
	}
	return errors;
}
