import axios from "axios";

export const FETCH_TASK_STATUSES_BEGIN = 'FETCH_TASK_STATUSES_BEGIN';
export const FETCH_TASK_STATUSES_SUCCESS = 'FETCH_TASK_STATUSES_SUCCESS';
export const FETCH_TASK_STATUSES_FAILURE = 'FETCH_TASK_STATUSES_FAILURE';

export function actionFetchTaskStatusesBegin() {
	return {
		type: FETCH_TASK_STATUSES_BEGIN
	};
}

export function actionFetchTaskStatusesSuccess(data) {
	return {
		type: FETCH_TASK_STATUSES_SUCCESS,
		list: data
	};
}

export function actionFetchTaskStatusesFailure(error) {
	console.log('Error ', error);
	return {
		type: FETCH_TASK_STATUSES_FAILURE,
		error: error
	};
}

export function actionFetchTaskStatuses() {
	return dispatch => {
		dispatch(actionFetchTaskStatusesBegin());

		return axios.get(API_URL + '/definition/task/statuses')
			.then(function(response) {
				if (Array.isArray(response.data.data.list)) {
					dispatch(actionFetchTaskStatusesSuccess(response.data.data.list));
				} else {
					dispatch(actionFetchTaskStatusesFailure());
				}
			}).catch(function(error) {
				dispatch(actionFetchTaskStatusesFailure(error));
			});
	};
}