import React from 'react';
import {ErrorMessage} from "formik";
import Field from "../../form/field";

export default function AdminAgreementTypeForm(props) {

	const {values, errors, handleChange, handleBlur, setFieldValue, setFieldTouched} = props;

	return (
		<>
			<div className="formItem">
				<label htmlFor="name">Nazwa</label>
				<Field className="fullWidth" type="text" name="name"/>
				<ErrorMessage component="div" className="errorMessage" name="name"/>
			</div>
      <div className="formItem">
        <label htmlFor="description">Treść zgody</label>
        <Field className="fullWidth" component="textarea" name="description" rows="4"/>
        <ErrorMessage component="div" className="errorMessage" name="description"/>
      </div>
		</>
	)
}

export function validateAgreementType(values) {
	let errors = {};
	if (!values.name) {
		errors.name = 'Nazwa jest wymagana';
	}

	return errors;
}
