import React from 'react';
import {ErrorMessage, Form, Formik} from "formik";
import CheckBox from "../../form/checkBox";
import { ServicesStatuses } from '../../state/actionsServices';

export default function FilterAdminService(props) {
	const statuses=ServicesStatuses
	function handleSubmit(values) {
		if (typeof props.onChange === 'function') {
			props.onChange(values);
		}
		if (typeof props.handleClose === 'function') {
			props.handleClose();
		}
	}

	return (
		<Formik
			initialValues={props.filters ? props.filters : {}}
			onSubmit={handleSubmit}
		>
			{(propsFormik) => {
				const {values, touched, errors, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, resetForm, setFieldValue, setFieldTouched} = propsFormik;

				function handleMultipleCheckboxChange(name, value) {
					let newValues = [];
					if (values.hasOwnProperty(name) && Array.isArray(values[name])) {
						newValues = [...values[name]];
					}
					if (newValues.indexOf(value) === -1) {
						newValues.push(value);
					} else {
						newValues.splice(newValues.indexOf(value), 1);
					}
					setFieldValue(name, newValues);
				}
				return (
					<Form>
						<div className="rcModelContents">
							<h3 className="top0 bottom24">Filtry</h3>
							<div className="formItem">
							<label htmlFor="status" className="bottom10">Status</label>
							{statuses && statuses.map(function(item,key) {
								return (
									<div className="displayInlineBlock right16 bottom20" key={"status" + item.id}>
										<CheckBox
											name="status"
											defaultChecked={false}
											value={item.id}
											checked={values.status && values.status.includes(item.id)}
											onChange={handleMultipleCheckboxChange}
											onBlur={setFieldTouched}
										>
											{item.name}
										</CheckBox>
									</div>
								);
							})}
							</div>
							<div className="textCenter bottom20 top20">
								<a href="#" className="blue" onClick={(event) => {
									event.preventDefault();

									if (typeof props.onChange === 'function') {
										props.onChange({});
									}
									if (typeof props.handleClose === 'function') {
										props.handleClose();
									}
								}}>Wyczyść filtry</a>
							</div>

						</div>

						<div className="rcModalActions">
							<button>Zapisz</button>
						</div>
					</Form>
				)
			}}
		</Formik>
	);
}
