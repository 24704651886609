import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Form, Formik} from "formik";
import {useHistory, useParams} from "react-router-dom";
import {findIndexOfObjectWithPropertyValueInArray, isEmptyObject, objectClone} from "../utils";
import OfferForm, {validateOffer} from "./offerForm";
import Spinner from "../component/spinner";
import {connect, useDispatch} from "react-redux";
import {actionFetchPeople} from "../state/actionsPeople";
import {actionFetchProcedures} from "../state/actionsProcedures";
import BackButton from "../component/backButton";
import moment from 'moment';
import {formikErrorHelper} from "../form/formUtils";

const mapStateToProps = function(state) {
  return {
    procedures: state.procedures.list,
    loading: state.procedures.loading,
  };
};

const OfferEdit = (props) => {
  const {id} = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const [offer, setOffer] = useState({});
  const [loading, setLoading] = useState(true);
  // const [service, setService] = useState({});
  const [serviceError, setServiceError] = useState({});;
  const [uploadedFiles, setUploadedFiles] = useState([]);

  useEffect(() => {
    async function fetchOffer() {
      const response = await axios(API_URL + '/offer/' + id);
      //console.log(response.data.data);
      if (response.data.data.offer) {
        let foo = objectClone(response.data.data.offer);
        if (foo.hasOwnProperty('projectTypes') && Array.isArray(foo.projectTypes) ) {
          foo.projectTypes = foo.projectTypes.map((projectType) => {
            if (projectType.hasOwnProperty('id')) {
              projectType = projectType.id;
            }
            return projectType;
          });
        }
        setOffer(foo);
      }
    }
    fetchOffer();
  }, []);

  const handleSubmit = (values, bag) => {
    const toSend = {};
    Object.keys(values).map(function(item) {
      if (offer[item] !== values[item]) {
        toSend[item] = values[item];
      }
    });
    delete toSend.created;
    delete toSend.comment;

    const error = {};

    const finish = () => {
      axios.patch(API_URL + '/offer/' + id, toSend)
        .then(function(response) {
          bag.setSubmitting(false);
          if (toSend.status === 4)
            history.push('/offer/copy/' + id + '')
          else
            history.goBack();

        }).catch(function(error) {
          formikErrorHelper(error, bag);
        });
    }

    let promises = [];
    promises.concat(addFiles(uploadedFiles, offer.id));
    if ([1,3,4].includes(values.status)) {
      promises.concat(addComment(values, offer.id));
    }

    Promise.all(promises).then(() => {
      finish();
    });
  }

  function handleFileAdd(uploadedFile) {
    setUploadedFiles([...uploadedFiles, uploadedFile]);
  }

  function handleFileDelete(fileId, valueFiles, setFieldValue) {
    let index = findIndexOfObjectWithPropertyValueInArray(uploadedFiles, fileId);

    if (index !== null && index !== -1) {
      let newUploadedFiles = [...uploadedFiles];
      newUploadedFiles.splice(index, 1);
      setUploadedFiles(newUploadedFiles);
    } else {
      let index = findIndexOfObjectWithPropertyValueInArray(valueFiles, fileId);
      if (index !== null && index !== -1) {
        axios.delete(API_URL + '/offer/file/' + offer.id + '/' + fileId)
          .then(function() {
            valueFiles.splice(index, 1);
            setFieldValue('files', valueFiles);
          });
      }
    }
  }

  function addFiles(uploadedFiles, offerId) {
    let promisesArray = [];

    if (uploadedFiles && Array.isArray(uploadedFiles) && uploadedFiles.length > 0) {
      uploadedFiles.map((item) => {
        promisesArray.push(
          axios.post(API_URL + '/offer/file/' + offerId + '/' + item.id)
        );
      });
    }

    return promisesArray;
  }

  function addComment(values, offerId) {
    let promisesArray = [];

    promisesArray.push(
      axios.post(API_URL + '/offer/comment/' + offerId + '', {
        comment: values.comment,
        offerStatus: values.status,
      })
    );

    return promisesArray;
  }

  return (isEmptyObject(offer) ? <div className="textCenter top96"><Spinner/></div> : renderFormik());

  function renderFormik() {
    let initial = objectClone(offer);
    delete initial.id;
    initial.created = moment(initial.created).format('YYYY-MM-DD'),
    initial.submission = moment(initial.submission)
    if (initial.hasOwnProperty('status') && initial.status.hasOwnProperty('id')) {
      initial.status = initial.status.id;
    } else {
      initial.status = 0;
    }

    return (
      <Formik
        initialValues={initial}
        validate={function(values) {
          let errors = validateOffer(values);
          if (uploadedFiles.length === 0 && values.files.length === 0) {
            errors.files = 'Plik jest wymagany!';
          }
          return errors;
        }}
        onSubmit={handleSubmit}
      >
        {(propsFormik) => {
          const {values, touched, errors, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched} = propsFormik;

          return (
            <>
              <Form>
                <div className="headerWithButtons">
                  <h1>Edycja Oferty</h1>
                  <div className="right">
                    <BackButton>Anuluj</BackButton>
                    <button type="submit" disabled={isSubmitting} className="left32 withChevron">
                      Zapisz&nbsp;<i className="fas fa-chevron-right"/>
                    </button>
                  </div>
                </div>

                <OfferForm
                  {...propsFormik}
                  handleFileAdd={handleFileAdd}
                  handleFileDelete={handleFileDelete}
                  uploadedFiles={uploadedFiles}
                  editMode={true}
                  copyMode={false}
                  comments={offer.comments}
                />
              </Form>

              {/*<Dump value={values} />*/}
              {/*<Dump value={offer} />*/}
              {/*<Dump value={service} />*/}
            </>
          )
        }}
      </Formik>
    );
  }

}

export default connect(mapStateToProps)(OfferEdit);