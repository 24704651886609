import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {formatDate, isEmptyObject, objectClone} from "../utils";
import Spinner from "../component/spinner";
import {parseISO} from "date-fns";
import FileView from "../component/fileView";
import {getClientAddressById} from "./receptionUtils";

export default function PackageView(props) {

	const [parcel, setParcel] = useState({});

	useEffect(() => {
		async function fetchPackage() {
			const response = await axios(API_URL + '/reception/' + props.packageId);
			//console.log(response);

			if (response.data.data.reception) {
				let foo = objectClone(response.data.data.reception);

				if (foo.client != null) {
					getClientAddressById(foo.client.id).then((result) => {
						foo.clientCity = result.city;
						foo.clientAddress = result.street;
						foo.clientPostal = result.postalCode;
						setParcel(foo);
					});
				} else {
					setParcel(foo);
				}
			}
		}

		fetchPackage();
	}, [props.packageId]);

	return (isEmptyObject(parcel) ? <div className="textCenter top96"><Spinner/></div> : renderForm());

	function renderForm() {
		return (
			<form onSubmit={(event) => { if (typeof props.handleClose === 'function') { props.handleClose(); }} }>
				<div className="rcModelContents">
					<h3 className="top0 bottom24">{parcel.description}</h3>

					<div className="row with three columns">
						<div className="formItem column">
							<label htmlFor="type">Rodzaj</label>
							<input type="text" readOnly value={parcel.type ? parcel.type.name : ''} className="fullWidth"/>
						</div>

						<div className="formItem column">
							<label htmlFor="direction">Typ przesyłki</label>
							<input type="text" readOnly value={parcel.direction === 'outbound' ? 'Wychodząca' : (parcel.direction === 'inbound') ? 'Przychodząca' : ''} className="fullWidth"/>
						</div>

						<div className="formItem column">
							<label htmlFor="taskNumber">Numer nadania</label>
							<input type="text" readOnly value={parcel.taskNumber} className="fullWidth"/>
						</div>

						<div className="formItem column">
							<label htmlFor="company">Spółka</label>
							<input type="text" readOnly value={parcel.company ? parcel.company.name : ''} className="fullWidth"/>
						</div>

						<div className="formItem column">
							<label htmlFor="province">Od / do osoby w zespole</label>
							<input type="text" readOnly value={parcel.user ? parcel.user.name : ''} className="fullWidth"/>
						</div>

						<div className="formItem column">
							<label htmlFor="implementationDate">Data wysyłki / odbioru</label>
							<input type="text" readOnly value={formatDate(parseISO(parcel.operationDate))} className="fullWidth"/>
						</div>
					</div>

					<div className="row with three columns top20">
						<div className="formItem column">
							<label htmlFor="client">Nazwa firmy</label>
							<input type="text" readOnly value={parcel.client ? parcel.client.name : parcel.clientName} className="fullWidth"/>
						</div>

						<div className="formItem column">
							<label htmlFor="clientAddress">Ulica</label>
							<input type="text" readOnly value={parcel.clientAddress} className="fullWidth"/>
						</div>

						<div className="formItem column">
							<label htmlFor="clientCity">Miasto</label>
							<input type="text" readOnly value={parcel.clientCity} className="fullWidth"/>
						</div>

						<div className="formItem column">
							<label htmlFor="clientPostal">Kod pocztowy</label>
							<input type="text" readOnly value={parcel.clientPostal} className="fullWidth"/>
						</div>
					</div>

					<div className="row with four columns">
						{parcel.files && parcel.files.map(function(file, index) {
							return (
								<div className="formItem column" key={file.id}>
									{(index === 0) ? <label>Załączone pliki</label> : <label>&nbsp;</label>}
									<FileView fileId={file.id} path={file.path}/>
								</div>
							);
						})}
					</div>

					<div className="rcModalActions">
						<button type="submit" className="left32">Zamknij</button>
					</div>
				</div>
			</form>
		);
	}
}
