import React, {useEffect} from 'react';
import Select from "./select";
import {Option} from "rc-select";
import {connect, useDispatch} from "react-redux";
import {actionFetchCompanies} from "../state/actionsCompanies";

const mapStateToProps = function(state) {
	return {
		companies: state.companies.list,
		loading: state.companies.loading,
	};
};

function SelectCompany(props) {
	const dispatcher = useDispatch();

	useEffect(() => {
		if (props.loading === false && (props.companies == null || props.companies.length === 0)) {
			dispatcher(actionFetchCompanies());
		}
	}, []);

	let {companies, dispatch, ...otherProps} = props;
	let data = props.companies;

	return (
		<Select
			loading={props.loading}
			showSearch={true}
			{...otherProps}
		>
			{data && data.map(function(item) {
				let label = (<div><span className="colorDot small" style={{backgroundColor: item.color}}/>{item.name}</div>);
				return (<Option value={parseInt(item.id)} key={'company' + item.id} label={label}>{label}</Option>);
			})}

		</Select>
	);
}

export default connect(mapStateToProps)(SelectCompany);