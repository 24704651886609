import React, {useEffect} from 'react';
import {Form, Formik} from "formik";
import CheckBox from "../form/checkBox";
import {connect, useDispatch} from "react-redux";
import SelectClient from "../form/selectClient";
import SelectCompany from "../form/selectCompany";
import {actionFetchReceptionTypes} from "../state/actionsReceptionTypes";
import DatePicker from "../form/datePicker";

const mapStateToProps = function(state) {
	return {
		receptionTypes: state.receptionTypes.list,
	};
};

function FilterPackage(props) {

	const dispatch = useDispatch();

	useEffect(() => {
		if (props.receptionTypes.length === 0) {
			dispatch(actionFetchReceptionTypes());
		}
	}, []);

	function handleSubmit(values) {
		if (typeof props.onChange === 'function') {
			props.onChange(values);
		}
		if (typeof props.handleClose === 'function') {
			props.handleClose();
		}
	}

	return (
		<Formik
			initialValues={props.filters ? props.filters : {}}
			onSubmit={handleSubmit}
		>
			{(propsFormik) => {
				const {values, touched, errors, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, resetForm, setFieldValue, setFieldTouched} = propsFormik;

				function handleMultipleCheckboxChange(name, value) {
					let newValues = [];
					if (values.hasOwnProperty(name) && Array.isArray(values[name])) {
						newValues = [...values[name]];
					}
					if (newValues.indexOf(value) === -1) {
						newValues.push(value);
					} else {
						newValues.splice(newValues.indexOf(value), 1);
					}
					setFieldValue(name, newValues);
				}

				return (
					<Form>
						<div className="rcModelContents">
							<h3 className="top0 bottom24">Filtry</h3>

							<div className="row with two columns">
								<div className="column">
									<label className="bottom10">Typ dokumentu</label><br/>
									{props.receptionTypes && props.receptionTypes.map(function(item) {
										return (
											<div className="bottom10" key={"type" + item.name + item.id}>
												<CheckBox
													name="type"
													defaultChecked={false}
													value={item}
													checked={values.type && values.type.includes(item)}
													onChange={handleMultipleCheckboxChange}
													onBlur={setFieldTouched}
												>
													{item.name}
												</CheckBox>
											</div>
										);
									})}
								</div>
								<div className="column">
									<div className="formItem">
										<label htmlFor="client" className="bottom10">Wybierz Klienta</label>
										<SelectClient
											name="client"
											onChange={setFieldValue}
											onBlur={setFieldTouched}
											value={values.client}
											className="fullWidth"
										/>
									</div>

									<div className="formItem">
										<label htmlFor="company" className="bottom10">Wybierz Spółkę</label>
										<SelectCompany
											name="company"
											onChange={setFieldValue}
											onBlur={setFieldTouched}
											value={values.company}
											className="fullWidth"
										/>
									</div>

									<div className="formItem">
										<label htmlFor="dateFrom" className="bottom10">Wybierz zakres dat</label>
										<DatePicker
											name="dateFrom"
											onChange={setFieldValue}
											onBlur={setFieldTouched}
											value={values.dateFrom}
											placeholder="Data od"
											style={{width: 157, minWidth: 120}}
										/>
										&nbsp;&nbsp;&nbsp;
										<DatePicker
											name="dateTo"
											onChange={setFieldValue}
											onBlur={setFieldTouched}
											value={values.dateTo}
											placeholder="Data do"
											style={{width: 157, minWidth: 120}}
										/>
									</div>
								</div>
							</div>
							<div className="textCenter bottom20 top20">
								<a href="#" className="blue" onClick={(event) => {
									event.preventDefault();

									if (typeof props.onChange === 'function') {
										props.onChange({});
									}
									if (typeof props.handleClose === 'function') {
										props.handleClose();
									}
								}}>Wyczyść filtry</a>
							</div>

							{/*<Dump value={values}/>*/}
							{/*<Dump value={propsFormik}/>*/}
						</div>

						<div className="rcModalActions">
							<button>Zapisz</button>
						</div>
					</Form>
				)
			}}
		</Formik>
	);
}

export default connect(mapStateToProps)(FilterPackage);
