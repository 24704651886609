import axios from "axios";

export const FETCH_ISSUE_STATUSES_BEGIN = 'FETCH_ISSUE_STATUSES_BEGIN';
export const FETCH_ISSUE_STATUSES_SUCCESS = 'FETCH_ISSUE_STATUSES_SUCCESS';
export const FETCH_ISSUE_STATUSES_FAILURE = 'FETCH_ISSUE_STATUSES_FAILURE';

export function actionFetchIssueStatusesBegin() {
	return {
		type: FETCH_ISSUE_STATUSES_BEGIN
	};
}

export function actionFetchIssueStatusesSuccess(data) {
	return {
		type: FETCH_ISSUE_STATUSES_SUCCESS,
		list: data
	};
}

export function actionFetchIssueStatusesFailure(error) {
	console.log('Error ', error);
	return {
		type: FETCH_ISSUE_STATUSES_FAILURE,
		error: error
	};
}

export function actionFetchIssueStatuses() {
	return dispatch => {
		dispatch(actionFetchIssueStatusesBegin());

		return axios.get(API_URL + '/issue/statuses')
			.then(function(response) {
				//console.log(response.data.data.statuses);
				if (Array.isArray(response.data.data.statuses)) {
					dispatch(actionFetchIssueStatusesSuccess(response.data.data.statuses));
				} else {
					dispatch(actionFetchIssueStatusesFailure());
				}
			}).catch(function(error) {
				dispatch(actionFetchIssueStatusesFailure(error));
			});
	};
}