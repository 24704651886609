import axios from "axios";

export const FETCH_PROFILE_BEGIN = 'FETCH_PROFILE_BEGIN';
export const FETCH_PROFILE_SUCCESS = 'FETCH_PROFILE_SUCCESS';
export const FETCH_PROFILE_FAILURE = 'FETCH_PROFILE_FAILURE';

export function actionFetchProfileBegin() {
	return {
		type: FETCH_PROFILE_BEGIN
	};
}

export function actionFetchProfileSuccess(data) {
	return {
		type: FETCH_PROFILE_SUCCESS,
		me: data
	};
}

export function actionFetchProfileFailure(error) {
	console.log('Error ', error);
	return {
		type: FETCH_PROFILE_FAILURE,
		error: error
	};
}

export function actionFetchProfile() {
	return dispatch => {
		dispatch(actionFetchProfileBegin());

		return axios.get(API_URL + '/profile/me')
			.then(function(response) {
				if (response.data.data.user) {
					dispatch(actionFetchProfileSuccess(response.data.data.user));
				} else {
					dispatch(actionFetchProfileFailure());
				}
			}).catch(function(error) {
				dispatch(actionFetchProfileFailure(error));
			});
	};
}