import React from 'react';
import {Form, Formik} from "formik";
import SelectPerson from "../form/selectPerson";
import SelectClient from "../form/selectClient";
import SelectOfferStatus from '../form/selectOfferStatus';
import SelectProjectType from '../form/selectProjectType';
import DatePicker from '../form/datePicker'

const FilterOffer = (props) => {

  const handleSubmit = (values) => {
    if (typeof props.onChange === 'function') {
      props.onChange(values);
    }
    if (typeof props.handleClose === 'function') {
      props.handleClose();
    }
  }

  return (
		<Formik
			initialValues={props.filters ? props.filters : {}}
			onSubmit={handleSubmit}
		>
			{(propsFormik) => {
				const {values, touched, errors, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, resetForm, setFieldValue, setFieldTouched} = propsFormik;

				return (
					<Form>
						<div className="rcModelContents">
							<h3 className="top0 bottom24">Filtry</h3>

							<div className="row with two columns">
								<div className="column">
									<div className="formItem">
										<label htmlFor="client" className="bottom10">Wybierz Klienta</label>
										<SelectClient
											name="client"
											onChange={setFieldValue}
											onBlur={setFieldTouched}
											value={values.client}
											className="fullWidth"
										/>
									</div>
									<div className="formItem">
										<label htmlFor="type" className="bottom10">Usługa</label>
										<SelectProjectType
											name="type"
											onChange={setFieldValue}
											onBlur={setFieldTouched}
											value={values.type}
											className="fullWidth"
										/>
									</div>
									<div className="formItem">
										<label htmlFor="dateFrom" className="bottom10">Data od</label>
										<DatePicker
											name="dateFrom"
											onChange={setFieldValue}
											onBlur={setFieldTouched}
											value={values.dateFrom}
											className="fullWidth"
										/>
									</div>
								</div>
								<div className="column">
									<div className="formItem">
										<label htmlFor="seller" className="bottom10">Utworzony przez</label>
										<SelectPerson
											name="seller"
											onChange={setFieldValue}
											onBlur={setFieldTouched}
											value={values.seller}
											className="fullWidth"
										/>
									</div>
									<div className="formItem">
										<label htmlFor="status" className="bottom10">Status</label>
										<SelectOfferStatus
											name="status"
											onChange={setFieldValue}
											onBlur={setFieldTouched}
											value={values.status}
											className="fullWidth"
										/>
									</div>
									<div className="formItem">
										<label htmlFor="dateTo" className="bottom10">Data do</label>
										<DatePicker
											name="dateTo"
											onChange={setFieldValue}
											onBlur={setFieldTouched}
											value={values.dateTo}
											className="fullWidth"
										/>
									</div>
								</div>
							</div>
							<div className="textCenter bottom20 top20">
								<a href="#" className="blue" onClick={(event) => {
									event.preventDefault();

									if (typeof props.onChange === 'function') {
										props.onChange({});
									}
									if (typeof props.handleClose === 'function') {
										props.handleClose();
									}
								}}>Wyczyść filtry</a>
							</div>

							{/*<Dump value={values}/>*/}
							{/*<Dump value={propsFormik}/>*/}
						</div>

						<div className="rcModalActions">
							<button type="submit">Zapisz</button>
						</div>
					</Form>
				)
			}}
		</Formik>
  )
}

export default FilterOffer;
