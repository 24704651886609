import React from 'react';
import SelectDocumentType from "../form/selectDocumentType";
import SelectClient from "../form/selectClient";
import {ErrorMessage} from "formik";

export default function DocumentForm(props) {

	const {values, errors, handleChange, handleBlur, setFieldValue, setFieldTouched} = props;

	return (
		<>
			<div className="formItem column">
				<label htmlFor="type">Rodzaj dokumentu</label>
				<SelectDocumentType
					name="type"
					onChange={setFieldValue}
					onBlur={setFieldTouched}
					value={values.type}
					className="fullWidth"
				/>
				<ErrorMessage component="div" className="errorMessage" name="type"/>
			</div>
			<div className="formItem column">
				<label htmlFor="client" className="bottom10">Wybierz Klienta</label>
				<SelectClient
					name="client"
					onChange={setFieldValue}
					onBlur={setFieldTouched}
					value={values.client}
					className="fullWidth"
					allowClear={true}
				/>
				<ErrorMessage component="div" className="errorMessage" name="client"/>
			</div>
		</>
	)
}

export function validateDocument(values) {
	let errors = {};
	if (!values.type) {
		errors.type = 'Typ jest wymagany';
	}
	if (!values.file) {
		errors.file = 'Plik jest wymagany';
	}
	return errors;
}