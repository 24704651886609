import React from "react"
import PopupMenu from "../component/popupMenu";
import {parseISO} from 'date-fns'
import {formatHours, formatDate} from "../utils";
import PillStatus from "../component/pillStatus";
import {Link} from "react-router-dom";

export default function TaskRow(props) {
	let item = props.value;

	console.log(item);

	let url = '/task/' + item.project.id + '/' + item.task.id + '/' + item.procedure + '/' + item.service;

	return (
		<>
			<div className="boxRow">
				<div className="boxCell textCenter">{props.lp}</div>
				<div className="boxCell textBold"><Link to={url}>{item.task.name}</Link></div>
				<div className="boxCell">{item.project ? item.project.name : ''}</div>
				<div className="boxCell">{item.client ? item.client.name : ''}</div>

				<div className="boxCell">{formatDate(parseISO(item.dateRealizationLimit))}</div>
				<div className="boxCell">{item.orderedBy ? item.orderedBy.name : ''}</div>
				<div className="boxCell">{formatDate(parseISO(item.project.created))}</div>
				<div className="boxCell" style={{minWidth: 125}}><PillStatus value={item.status}/></div>

				<div className="boxCell textRight padRight8">
					<PopupMenu>
						<li><Link to={url}>Zobacz szczegóły</Link></li>
					</PopupMenu>
				</div>
			</div>
		</>
	);
}