import React from 'react';
import {ErrorMessage} from "formik";
import SelectPerson from "../form/selectPerson";
import RangeDatePicker from "../form/rangeDatePicker";
import SelectCalendarType from "../form/selectCalendarType";

export default function CalendarAbsenceForm(props) {

	const {values, errors, handleChange, handleBlur, setFieldValue, setFieldTouched} = props;

	return (
		<>
			<div className="displayInlineBlock valignTop">
				<SelectPerson
					name="owner"
					onChange={setFieldValue}
					onBlur={setFieldTouched}
					value={values.owner}
					placeholder="Wybierz osobę"
				/>
				<ErrorMessage component="div" className="errorMessage" name="owner"/>
			</div>

			<div className="displayInlineBlock valignTop left12">
				<RangeDatePicker
					name="range"
					onChange={setFieldValue}
					onBlur={setFieldTouched}
					value={values.range}
				/>
				<ErrorMessage component="div" className="errorMessage" name="range"/>
			</div>

			<div className="displayInlineBlock valignTop left12">
				<SelectCalendarType
					name="type"
					onChange={setFieldValue}
					onBlur={setFieldTouched}
					value={values.type}
					placeholder="Typ wydarzenia"
					style={{width: 220}}
				/>
				<ErrorMessage component="div" className="errorMessage" name="type"/>
			</div>
		</>
	)
}

export function validateCalendarAbsence(values) {
	let errors = {};
	if (!values.type) {
		errors.type = 'Typ jest wymagany';
	}
	if (!values.owner) {
		errors.owner = 'Osoba jest wymagana';
	}
	return errors;
}