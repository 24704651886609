import React, {useEffect} from 'react';
import Select from "./select";
import {Option} from "rc-select";
import {connect, useDispatch} from "react-redux";
import {actionFetchDepartments} from "../state/actionsDepartaments";

const mapStateToProps = function(state) {
	return {
		departments: state.departments.list,
		loading: state.departments.loading,
	};
};

function SelectDepartment(props) {
	const dispatcher = useDispatch();

	useEffect(() => {
		if (props.loading === false && (props.departments == null || props.departments.length === 0)) {
			dispatcher(actionFetchDepartments());
		}
	}, []);

	let {departments, dispatch, ...otherProps} = props;
	let data = props.departments;

	return (
		<Select
			loading={props.loading}
			{...otherProps}
		>
			{data && data.map(function(item) {
				let label = (<div><span className="colorDot small" style={{backgroundColor: item.color}}/>{item.name}</div>);
				return (<Option value={parseInt(item.id)} key={'department' + item.id} label={label}>{label}</Option>);
			})}
		</Select>
	);
}

export default connect(mapStateToProps)(SelectDepartment);