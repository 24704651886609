import axios from "axios";

export function uploadFileHelper(file, callback) {
	let toSendFile = new FormData();
	toSendFile.append('file', file);
	toSendFile.append('storage', 's3');

	axios.post(API_URL + '/media/upload', toSendFile)
		.then(function(response) {
			let id = response.data.data.file.id;
			let fileName = response.data.data.file.fileName;
			let filePath = response.data.data.file.filePath;

			if (typeof callback == 'function') {
				callback(id, fileName, filePath);
			}
		}).catch(function(error) {
			console.log(error);
		});
}

export function formikErrorHelper(error, bag) {
	if (error.response && error.response.status === 400) {
		if (error.response.data.hasOwnProperty('errors') && typeof error.response.data.errors === 'object') {
			Object.keys(error.response.data.errors).map(function(item) {
				bag.setFieldError(item, error.response.data.errors[item]);
			});
		}
	}

	bag.setSubmitting(false);
}