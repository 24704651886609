import React from "react";
import {ErrorMessage} from "formik";
import Field from "../form/field";
import axios from "axios";
import SelectAgreementTypes from "../form/selectAgreementTypes";
import {filenameForLabel, formatDate, getFilenameFromPath, isEmptyObject, objectClone} from "../utils";

import Dump from "../component/dump";


export default function ClientAgreementForm(props) {

  const {index, name, values, setFieldValue, setFieldTouched, arrayHelpers, errors} = props;

	const prefix = name + '.' + index;

  const handleRmove = (event) => {
    event.preventDefault();
    //
		if (props.data.hasOwnProperty('id')) {
			axios.delete(API_URL + '/clientAgreement/' + props.data.id)
				.then(function(response) {
					arrayHelpers.remove(index);
				});
		} else {
			arrayHelpers.remove(index);
		}
  }

  return (
		<div className="box">
			<a href="#" className="floatRight red font24" onClick={handleRmove}><i className="fas fa-times"/></a>
      {/* <h3 className="top0 bottom32">Zgoda</h3> */}
      <div className="formItem">
        <label htmlFor={`${prefix}.type`}>Typ zgody</label>
        <SelectAgreementTypes
          className="fullWidth"
          name={`${prefix}.type`}
          onChange={setFieldValue}
          onBlur={setFieldTouched}
          value={values[name][index]['type']}
        />
        <ErrorMessage component="div" className="errorMessage" name={`${prefix}.type`}/>
      </div>

      <div className="formItem">
        <label>Plik zawierający zgodę klienta</label>
        <input type="text" value={filenameForLabel(values[name][index]['file'])} readOnly className="right24"/>
        <input id={`${prefix}.file`} name={`${prefix}.file`} type="file" onChange={(event) => {
          setFieldValue(`${prefix}.file`, event.currentTarget.files[0]);
        }} />
        <label htmlFor={`${prefix}.file`}>Dodaj plik</label>
        <ErrorMessage component="div" className="errorMessage" name={`${prefix}.file`}/>
      </div>
    </div>
	);
}