import React, {useEffect} from 'react';
import Select from "./select";
import {Option} from "rc-select";
import {connect, useDispatch} from "react-redux";
import {actionFetchTasks} from "../state/actionsTasks";

const mapStateToProps = function(state) {
	return {
		tasks: state.tasks.list,
		loading: state.tasks.loading,
	};
};

function SelectTask(props) {
	const dispatcher = useDispatch();

	useEffect(() => {
		if (props.loading === false && (props.tasks == null || props.tasks.length === 0)) {
			dispatcher(actionFetchTasks());
		}
	}, []);

	let {tasks, dispatch, ...otherProps} = props;

	return (
		<Select
			loading={props.loading}
			showSearch={true}
			{...otherProps}
		>
			{props.tasks && props.tasks.map(function(item) {
				return <Option value={parseInt(item.id)} key={'task' + item.id}>{item.name}</Option>
			})}
		</Select>
	);
}

export default connect(mapStateToProps)(SelectTask);