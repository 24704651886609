import React, {useEffect, useState} from 'react'
import Spinner from "../../component/spinner";
import ReactModal from "react-modal";
import AdminCompanyAdd from "./adminCompanyAdd";
import AdminCompanyEdit from "./adminCompanyEdit";
import axios from "axios";
import PopupMenu from "../../component/popupMenu";
import {connect, useDispatch} from "react-redux";
import {actionFetchCompanies} from "../../state/actionsCompanies";
import {confirmDelete} from "../../utils";

const mapStateToProps = function(state) {
	return {
		companies: state.companies.list,
		loading: state.companies.loading,
	};
};

function AdminCompanyList(props) {
	const dispatch = useDispatch();

	useEffect(() => {
		if (props.loading === false && (props.companies == null || props.companies.length === 0)) {
			dispatch(actionFetchCompanies());
		}
	}, []);

	const [itemId, setItemId] = useState(null);

	const [showModalAdd, setShowModalAdd] = useState(false);
	const [showModalEdit, setShowModalEdit] = useState(false);

	function handleCloseModalAdd() {
		setShowModalAdd(false);
	}

	function handleCloseModalEdit() {
		setShowModalEdit(false);
	}

	function handleOpenModalEdit(id) {
		setItemId(id);
		setShowModalEdit(true);
	}

	function handleRefresh() {
		dispatch(actionFetchCompanies());
	}

	function handleDelete(id) {
		axios.delete(API_URL + '/definition/company/' + id)
			.then(function(response) {
				handleRefresh();
			});
	}

	return (
		<div>
			<div className="headerWithButtons">
				<div className="left">
					<h1>Spółki</h1>
					<button className="left12 plus" onClick={() => setShowModalAdd(true)}><i className="fas fa-plus"/></button>
				</div>
			</div>

			{props.loading === false && props.companies && props.companies.map(function(item) {
				let color = (item.color ? item.color : 'grey');

				return (
					<div key={"item" + item.id} className="box smallerPadding">
						<div className="floatRight">
							<PopupMenu style={{right: '-12px'}}>
								<li onClick={() => handleOpenModalEdit(item.id)}>Edytuj</li>
								<li onClick={() => confirmDelete(() => handleDelete(item.id))} className="red">Usuń</li>
							</PopupMenu>
						</div>
						<div className="colorDot right4" style={{backgroundColor: color}}>{item.name.toUpperCase().charAt(0)}</div>
						<a href="#" onClick={(event) => { event.preventDefault(); handleOpenModalEdit(item.id)}}>{item.name}</a>
					</div>
				);
			})}

			{(props.loading === true) ? <div className="textCenter"><Spinner/></div> : ''}

			<ReactModal
				isOpen={showModalAdd}
				contentLabel=""
				onRequestClose={handleCloseModalAdd}
				shouldCloseOnOverlayClick={true}
				className="rcModal withActions"
				overlayClassName="rcOverlay"
			>
				<span onClick={handleCloseModalAdd} className="rcModalClose"><i className="fas fa-times"/> Zamknij</span>

				<AdminCompanyAdd handleRefresh={handleRefresh} handleClose={handleCloseModalAdd}/>
			</ReactModal>

			<ReactModal
				isOpen={showModalEdit}
				contentLabel=""
				onRequestClose={handleCloseModalEdit}
				shouldCloseOnOverlayClick={true}
				className="rcModal withActions"
				overlayClassName="rcOverlay"
			>
				<span onClick={handleCloseModalEdit} className="rcModalClose"><i className="fas fa-times"/> Zamknij</span>

				<AdminCompanyEdit handleRefresh={handleRefresh} handleClose={handleCloseModalEdit} itemId={itemId} />
			</ReactModal>
		</div>
	);
}

export default connect(mapStateToProps)(AdminCompanyList);
