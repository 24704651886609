import React from 'react';
import Picker from 'rc-calendar/lib/Picker';
import Calendar from 'rc-calendar/lib/Calendar';
import TimePickerPanel from 'rc-time-picker/lib/Panel';
import locale from 'rc-calendar/lib/locale/pl_PL';
import moment from 'moment';
import 'moment/locale/pl';

export default function DateTimePicker(props) {
	moment.locale('pl');

	const formatDate = 'YYYY-MM-DD';
	const formatTime = 'HH:mm';
	const formatDateTime = formatDate + ' ' + formatTime;

	function handleChange(value) {
		if (typeof props.onChange == 'function') {
			props.onChange(props.name, value);
		}
	}

	function toDateTime(v) {
		return v ? v.format(formatDateTime) : '';
	}

	const calendar = (
		<Calendar
			showToday={false}
			showOk={false}
			showWeekNumber={false}
			locale={locale}
			timePicker={<TimePickerPanel format={formatTime} showSecond={false} minuteStep={1} />}
			showTime={{ format: formatTime }}
			format={formatDateTime}
		/>
	);

	let {value, onBlur, onChange, className, ...otherProps} = props;

	return (
		<>
			<Picker
				value={value}
				showDateInput={false}
				onChange={handleChange}
				calendar={calendar}
			>
				{() => {
					return (
						<input
							type="text"
							className={'datePicker ' + className}
							readOnly
							tabIndex="-1"
							value={toDateTime(value) || ''}
							{...otherProps}
						/>
	                );
				}}
			</Picker>
			{/*<Dump value={otherProps}/>*/}
		</>);
}
