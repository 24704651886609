import {filenameForLabel} from "../utils";
import React from "react";
import {uploadFileHelper} from "../form/formUtils";

export default function FileUpload(props) {

	function handleFile(file) {
		if (file instanceof File) {
			uploadFileHelper(file, function(uploadedFileId, fileName, filePath) {
				if (typeof props.handleFile === 'function') {
					props.handleFile({
						id: uploadedFileId,
						name: fileName,
						path: filePath
					});
				}
			});
		}
	}

	return (
		<>
			<div className="formItem">
				{props.file ? <input type="text" value={filenameForLabel(props.file)} readOnly className="right24"/> : ''}
				<input name="file" id="file" type="file" onChange={(event) => handleFile(event.currentTarget.files[0])} />
				<label htmlFor="file">Dodaj plik</label>
			</div>

			{/*<Dump value={props}/>*/}
		</>
	);
}

