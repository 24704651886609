import React, {useEffect, useState, useRef} from 'react';
import {connect, useDispatch, useSelector} from "react-redux";
import axios from 'axios';
import {Form, Formik} from "formik";
import {useHistory, useParams} from "react-router-dom";
import {findIndexOfObjectWithPropertyValueInArray, isEmptyObject, objectClone} from "../utils";
import OfferForm, {validateOffer} from "./offerForm";
import Spinner from "../component/spinner";
import {actionFetchPeople} from "../state/actionsPeople";
import {actionFetchProcedures} from "../state/actionsProcedures";
import BackButton from "../component/backButton";
import moment from 'moment';
import {formikErrorHelper} from "../form/formUtils";
import ReactModal from 'react-modal';
import OfferClientAdd from './offerClientAdd';
import Dump from "../component/dump";

const mapStateToProps = function(state) {
  return {
    procedures: state.procedures.list,
    loading: state.procedures.loading,
  };
};

const OfferCopy = (props) => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const [offer, setOffer] = useState({});
  const [loading, setLoading] = useState(true);
  const me = useSelector(state => state.profile.me);

  const [projectTypes, setProjectTypes] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const [showAddClientModal, setShowAddClientModal] = useState(false);
  const [newClient, setNewClient] = useState(null);

  const [service, setService] = useState(null);
  const [serviceError, setServiceError] = useState({});

  const offerFormRef = useRef();

  useEffect(() => {
    if ( !isEmptyObject(me) ) {
      setLoading(false);
    }
  }, [me]);

  useEffect(() => {
    async function fetchOffer() {
      const response = await axios(API_URL + '/offer/' + id);
      //console.log(response.data.data);
      if (response.data.data.offer) {
        let foo = objectClone(response.data.data.offer);
        if (foo.hasOwnProperty('projectTypes') && Array.isArray(foo.projectTypes) ) {
          foo.projectTypes = foo.projectTypes.map((projectType) => {
            if (projectType.hasOwnProperty('id')) {
              projectType = projectType.id;
            }
            return projectType;
          });
        }
        setOffer(foo);
      }
    }
    fetchOffer();
  }, []);


  const handleFileAdd = (uploadedFile) => {
    setUploadedFiles([...uploadedFiles, uploadedFile]);
  }

  const handleFileDelete = (fileId) => {
    let index = findIndexOfObjectWithPropertyValueInArray(uploadedFiles, fileId);

    if (index !== null && index !== -1) {
      let newUploadedFiles = [...uploadedFiles];
      newUploadedFiles.splice(index, 1);
      setUploadedFiles(newUploadedFiles);
    }
  }

  const addFiles = (uploadedFiles, projectId) => {
    let promisesArray = [];

    if (uploadedFiles && Array.isArray(uploadedFiles) && uploadedFiles.length > 0) {
      uploadedFiles.map((item) => {
        promisesArray.push(
          axios.post(API_URL + '/offer/file/' + projectId + '/' + item.id)
        );
      });
    }

    return promisesArray;
  }

  const handleSubmit = (values, bag) => {
    let toSend = objectClone(values);
    let error = {};
    delete toSend.created;

    if (toSend.hasOwnProperty('previous') && toSend.previous.hasOwnProperty('id')) {
      toSend.previous = toSend.previous.id;
    }
    if (toSend.hasOwnProperty('client') && toSend.client.hasOwnProperty('id')) {
      toSend.client = toSend.client.id;
    }

    const finish = () => {
      bag.setSubmitting(false);
      history.push('/offer');
    }

    axios.post(API_URL + '/offer/create', toSend)
      .then(function(response) {
        let newId = response.data.id;
        let promises = [];
        promises.concat(addFiles(uploadedFiles, newId));

        Promise.all(promises).then(function() {
          finish();
        });
      }).catch(function(error) {
        formikErrorHelper(error, bag);
      });
  }

  const renderFormik = () => {
    let initial = objectClone(offer);
    // delete initial.id;

    return (
      <Formik
        initialValues={{
          name: initial.name + ' - złożona ponownie',
          client: initial.client,
          seller: me.id,
          projectTypes: initial.projectTypes,
          description: initial.description,
          created: moment().format('YYYY-MM-DD'),
          submission: moment(),
          previous: { id: initial.id, name: initial.name }
        }}
        validate={validateOffer}
        onSubmit={handleSubmit}
      >
        {(propsFormik) => {
          const {values, touched, errors, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched} = propsFormik;

          return (
            <>
              <Form>
                <div className="headerWithButtons">
                  <h1>Ponowne dodawanie oferty</h1>
                  <div className="right">
                    <BackButton>Anuluj</BackButton>
                    <button type="submit" disabled={isSubmitting} className="left32 withChevron">
                      Zapisz&nbsp;<i className="fas fa-chevron-right"/>
                    </button>
                  </div>
                </div>

                <OfferForm
                  {...propsFormik}
                  handleFileAdd={handleFileAdd}
                  handleFileDelete={handleFileDelete}
                  uploadedFiles={uploadedFiles}
                  editMode={false}
                  copyMode={true}
                />
              </Form>

              {/* <Dump value={values} /> */}
              {/* <Dump value={offer} /> */}
              {/* <Dump value={initial} /> */}
              {/* <Dump value={service} /> */}
            </>
          )
        }}
      </Formik>
    );
  }

  return (isEmptyObject(offer) ? <div className="textCenter top96"><Spinner/></div> : renderFormik());
}

export default connect(mapStateToProps)(OfferCopy);