import React, {useEffect, useState} from "react"
import {connect, useDispatch} from "react-redux";
import {actionFetchDepartments} from "../state/actionsDepartaments";

const mapStateToProps = function(state) {
	return {
		departments: state.departments.list
	};
};

function FilterDepartments(props) {
	const [selected, setSelected] = useState(null);
	const dispatch = useDispatch();

	useEffect(() => {
		if (props.departments.length === 0) {
			dispatch(actionFetchDepartments());
		}
	}, []);

	function handleClick(value) {
		setSelected(value);

		if (props.hasOwnProperty('onChange') && 'function' === typeof props.onChange) {
			props.onChange(value);
		}
	}

	return (
		<div className="filter bottom40">
			<button className={"pill right8 " + (selected === null ? 'grey' : 'transparent')} onClick={() => handleClick(null)}>
				Wszystkie działy
			</button>
			{props.departments.map(function(item) {
				return (
					<button
						className={"pill right8 top2 bottom2 " + (selected === item.id ? 'grey' : 'transparent')}
						key={'department' + item.id}
						onClick={() => handleClick(item.id)}
					>
						{item.name}
					</button>
				);
			})}
		</div>
	);
}

export default connect(mapStateToProps)(FilterDepartments);