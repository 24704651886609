import {AUTHED, LOGIN, LOGOUT, NOT_AUTHED} from './actionsAuth';

const reducerAuth = (state = {accessToken: null, isAuthenticated: null}, action) => {

	switch (action.type) {
		case LOGIN:
			localStorage.setItem('accessToken', action.token);
			return {
				...state,
				accessToken: action.token,
				isAuthenticated: true
			};

		case LOGOUT:
			localStorage.removeItem('accessToken');
			return {
				...state,
				accessToken: null,
				isAuthenticated: null
			};

		case AUTHED:
			return {
				...state,
				isAuthenticated: true
			};

		case NOT_AUTHED:
			return {
				...state,
				isAuthenticated: false
			};

		default:
			return state
	}
};

export default reducerAuth