import {combineReducers} from "redux"
import reducerAuth from "./reducerAuth"
import reducerDepartments from "./reducerDepartments"
import reducerPositions from "./reducerPositions"
import reducerProvinces from "./reducerProvinces"
import reducerDocumentTypes from "./reducerDocumentTypes"
import reducerPeople from "./reducerPeople";
import reducerClients from "./reducerClients";
import reducerCalendarTypes from "./reducerCalendarTypes";
import reducerTaskTypes from "./reducerTaskTypes";
import reducerServices from "./reducerServices";
import reducerTasks from "./reducerTasks";
import reducerProcedures from "./reducerProcedures";
import reducerProjectTypes from "./reducerProjectTypes";
import reducerAgreementTypes from "./reducerAgreementTypes";
import reducerCompanies from "./reducerCompanies";
import reducerProfile from "./reducerProfile";
import reducerTaskStatuses from "./reducerTaskStatuses";
import reducerTags from "./reducerTags";
import reducerKnowledgeTypes from "./reducerKnowledgeTypes";
import reducerIssueStatuses from "./reducerIssueStatuses";
import reducerIntervals from "./reducerIntervals";
import reducerReceptionTypes from "./reducerReceptionTypes";
import reducerKnowledgeBaseTypes from "./reducerKnowledgeBaseTypes";
import reducerNotificationCount from "./reducerNotifications";
import {connectRouter} from 'connected-react-router';
import {LOGOUT} from './actionsAuth';

const rootReducer = (history) => {
	const appReducer = combineReducers({
		router: connectRouter(history),
		auth: reducerAuth,
		departments: reducerDepartments,
		positions: reducerPositions,
		provinces: reducerProvinces,
		documentTypes: reducerDocumentTypes,
		people: reducerPeople,
		clients: reducerClients,
		calendarTypes: reducerCalendarTypes,
		taskTypes: reducerTaskTypes,
		taskStatuses: reducerTaskStatuses,
		services: reducerServices,
		tasks: reducerTasks,
		procedures: reducerProcedures,
		projectTypes: reducerProjectTypes,
		agreementTypes: reducerAgreementTypes,
		companies: reducerCompanies,
		profile: reducerProfile,
		tags: reducerTags,
		knowledgeTypes: reducerKnowledgeTypes,
		issueStatuses: reducerIssueStatuses,
		intervals: reducerIntervals,
		receptionTypes: reducerReceptionTypes,
		knowledgeBase: reducerKnowledgeBaseTypes,
		notifications: reducerNotificationCount
	});

	return (state, action) => {
		if (action.type === LOGOUT) {
			localStorage.removeItem('accessToken');
			state = undefined;
		}

		return appReducer(state, action);
	};
};

export default rootReducer;
