import React, {useState} from "react"

export default function FilterDirection(props) {
	const [selected, setSelected] = useState(null);

	function handleClick(value) {
		setSelected(value);

		if (props.hasOwnProperty('onChange') && 'function' === typeof props.onChange) {
			props.onChange(value);
		}
	}

	return (
		<div className="filter bottom40 floatLeft">
			<button className={"pill right8 " + (selected === null ? 'grey' : 'transparent')} onClick={() => handleClick(null)}>
				Wszystkie
			</button>
			<button
				className={"pill right8 top2 bottom2 " + (selected === "inbound" ? 'grey' : 'transparent')}
				onClick={() => handleClick('inbound')}
			>
				Przychodzące
			</button>
			<button
				className={"pill right8 top2 bottom2 " + (selected === "outbound" ? 'grey' : 'transparent')}
				onClick={() => handleClick('outbound')}
			>
				Wychodzące
			</button>
		</div>
	);
}
