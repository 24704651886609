import React, {useEffect} from 'react';
import Select from "./select";
import {Option} from "rc-select";
import {connect, useDispatch} from "react-redux";
import {ProjectStatuses} from '../state/actionsProjectStatuses';

const mapStateToProps = function(state) {
	return {
		statuses: ProjectStatuses,
	};
};

function SelectIssueType(props) {

	let {statuses,  ...otherProps} = props;

	return (
		<Select
			loading={props.loading}
			showSearch={true}
			showArrow={true}
			{...otherProps}
		>
			{props.statuses && props.statuses.map((item, index) => (
				<Option value={parseInt(item.id)} key={'type' + item.id}>{item.name}</Option>
			))}
		</Select>
	);
}

export default connect(mapStateToProps)(SelectIssueType);