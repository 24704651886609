import React from "react";
import PopupMenu from "../component/popupMenu";
import axios from "axios";
import PillStatus from "../component/pillStatus";
import {confirmDelete, getNotificationUrl} from "../utils";
import {Link} from "react-router-dom";

export default function NotificationRow(props) {

	let item = props.value;

	function handleDelete() {
		axios.delete(API_URL + '/notification/' + item.id)
			.then(function(response) {
				if (typeof props.refresh === 'function') {
					props.refresh();
				}
			});
	}

	function handleChangeStatus() {
		axios.patch(API_URL + '/notification/' + item.id, {status: (item.status.id === 1) ? 0 : 1})
			.then(function(response) {
				if (typeof props.refresh === 'function') {
					props.refresh();
				}
			});
	}

	function renderLink(children) {
		if (item.hasOwnProperty('params')) {
			let url = getNotificationUrl(item.params);
			if (url) {
				return <Link to={url}>{children}</Link>
			}
		}

		return children;
	}

	return (
		<>
			<div className="boxRow">
				<div className="boxCell">{renderLink(<span dangerouslySetInnerHTML={{ __html: item.content }}/>)}</div>
				<div className="boxCell">{item.status ? <PillStatus value={item.status}/> : ''}</div>

				<div className="boxCell textRight padRight8">
					<PopupMenu>
						<li onClick={() => handleChangeStatus()}>{(item.status && item.status.id === 1) ? "Oznacz jako nieprzeczytane" : "Oznacz jako przeczytane"}</li>
						<li onClick={() => confirmDelete(handleDelete)} className="red">Usuń</li>
					</PopupMenu>
				</div>
			</div>
		</>
	);
}