import React from 'react';
import {ErrorMessage} from "formik";
import Field from "../../form/field";

export default function AdminKnowledgeBaseTypeForm(props) {

	const {values, errors, handleChange, handleBlur, setFieldValue, setFieldTouched} = props;

	return (
		<>
			<div className="formItem">
				<label htmlFor="name">Nazwa</label>
				<Field className="fullWidth" type="text" name="name"/>
				<ErrorMessage component="div" className="errorMessage" name="name"/>
			</div>
		</>
	)
}

export function validateReceptionType(values) {
	let errors = {};
	if (!values.name) {
		errors.name = 'Nazwa jest wymagana';
	}
	return errors;
}
