import React, {useState} from "react";
import usePushNotifications from "../usePushNotifications";
import Dump from "../component/dump";
import axios from "axios";
import ab2str from 'arraybuffer-to-string';
import {useSelector} from "react-redux";

export default function PushNotification() {
	const {isConsentGranted, isPushNotificationSupported, userSubscription, askUserPermission, sendSubscriptionToServer} = usePushNotifications();

	const pushSettings = useSelector(state => state.profile.me.pushSettings);

	const [payload, setPayload] = useState('');

	function sendTestPush(event) {
		event.preventDefault();

		axios.post(API_URL + '/notification/testPush', {
			url: userSubscription.endpoint,
			p256dh: ab2str(userSubscription.getKey('p256dh'), 'base64'),
			auth: ab2str(userSubscription.getKey('auth'), 'base64'),
			payload: payload
		});
	}

	return (
		<>
			<p className="bottom24">Pushe {!isPushNotificationSupported && "NIE"} sa wspierane przez przeglądarke.</p>

			<button
				className="right32"
				disabled={!isPushNotificationSupported || isConsentGranted}
				onClick={askUserPermission}
			>
				{isConsentGranted ? "Zgoda na powiadomienia" : "Zapytaj o zgode na powiadomienia"}
			</button>

			<button disabled={!userSubscription} onClick={sendSubscriptionToServer}>Wyślij subskrypcje na serwer</button>

			<Dump label="userSubscription" value={userSubscription}/>
			<Dump label="pushSettings na serwerze" value={pushSettings}/>

			<p className="top64 bottom16">Wyślij testowy Push z serwera:</p>
			<form>
				<textarea value={payload} onChange={(event) => setPayload(event.target.value)}/>
				<br/>
				<button className="top12" onClick={sendTestPush}>Wyślij</button>
			</form>
		</>
	);
}
