import React from 'react';
import {formatDate, isEmptyObject} from "../utils";
import {parseISO} from "date-fns";
import FileView from "../component/fileView";

export default function ServiceView(props) {

	const service = props.service;

	return (isEmptyObject(service) ? <div className="textCenter top96"><Spinner/></div> : renderForm());


	function renderForm() {
		return (
			<form onSubmit={(event) => { if (typeof props.handleClose === 'function') { props.handleClose(); }} }>
				<div className="rcModelContents">
					<h3 className="top0 bottom24">{service.name}</h3>

					<div className="row with three columns">
						<div className="formItem column">
							<label>Przypisanie do działu</label>
							{(service.department) ? <span className="pill eggplant">{service.department.name}</span> : ''}
						</div>
						<div className="formItem column">
							<label>Typ</label>
							<input type="text" readOnly value={service.type ? service.type.name : ''} className="fullWidth"/>
						</div>

						<div className="formItem column">
							<label>Data ostatniej zmiany</label>
							<input type="text" readOnly value={formatDate(parseISO(service.modified))} className="fullWidth"/>
						</div>
					</div>

					<div className="formItem">
						<label htmlFor="description">Opis usługi</label>
						<textarea className="fullWidth" name="description" value={service.description} rows="4" readOnly/>
					</div>

					<div className="row with four columns">
						{service.files && service.files.map(function(file, index) {
							return (
								<div className="formItem column" key={file.id}>
									{(index === 0) ? <label>Załączone pliki (np. instrukcje)</label> : <label>&nbsp;</label>}
									<FileView fileId={file.id} path={file.path}/>
								</div>
							);
						})}
					</div>

					<div className="formItem bottom0">
						<label>Procedury w usłudze</label>
					</div>

					<div className="row with four columns bottom12">
						{service.procedures.map((item) => {
							return (
								<div className="column boxItem bottom12" key={"procedure" + item.id}>{item.name}</div>
							);
						})}
					</div>

					<div className="rcModalActions">
						<button type="submit">Zamknij</button>
					</div>
				</div>
			</form>
		);
	}
}
