import axios from "axios";

export const FETCH_KNOWLEDGE_BASE_TYPES_BEGIN = 'FETCH_KNOWLEDGE_BASE_TYPES_BEGIN';
export const FETCH_KNOWLEDGE_BASE_TYPES_SUCCESS = 'FETCH_KNOWLEDGE_BASE_TYPES_SUCCESS';
export const FETCH_KNOWLEDGE_BASE_TYPES_FAILURE = 'FETCH_KNOWLEDGE_BASE_TYPES_FAILURE';

export function actionFetchKnowledgeBaseTypesBegin() {
	return {
		type: FETCH_KNOWLEDGE_BASE_TYPES_BEGIN
	};
}

export function actionFetchKnowledgeBaseTypesSuccess(data) {
	return {
		type: FETCH_KNOWLEDGE_BASE_TYPES_SUCCESS,
		list: data
	};
}

export function actionFetchKnowledgeBaseTypesFailure(error) {
	console.log('Error ', error);
	return {
		type: FETCH_KNOWLEDGE_BASE_TYPES_FAILURE,
		error: error
	};
}

export function actionFetchKnowledgeBaseTypes() {
	return dispatch => {
		dispatch(actionFetchKnowledgeBaseTypesBegin());

		return axios.get(API_URL + '/definition/knowledgeBaseType/list')
			.then(function(response) {
				if (Array.isArray(response.data.data.list)) {
					dispatch(actionFetchKnowledgeBaseTypesSuccess(response.data.data.list));
				} else {
					dispatch(actionFetchKnowledgeBaseTypesFailure());
				}
			}).catch(function(error) {
				dispatch(actionFetchKnowledgeBaseTypesFailure(error));
			});
	};
}