import React, {useEffect} from 'react';
import Select from "./select";
import {Option} from "rc-select";
import {connect, useDispatch} from "react-redux";
import {actionFetchServices} from "../state/actionsServices";

const mapStateToProps = function(state) {
	return {
		services: state.services.list,
		loading: state.services.loading,
	};
};

function SelectService(props) {
	const dispatcher = useDispatch();

	useEffect(() => {
		if (props.loading === false && (props.services == null || props.services.length === 0)) {
			dispatcher(actionFetchServices());
		}
	}, []);

	let {services, dispatch, ...otherProps} = props;

	return (
		<Select
			loading={props.loading}
			showSearch={true}
			{...otherProps}
		>
			{props.services && props.services.map(function(item) {
				return <Option value={parseInt(item.id)} key={'service' + item.id}>{item.name}</Option>
			})}
		</Select>
	);
}

export default connect(mapStateToProps)(SelectService);