import React from "react"
import {Link} from "react-router-dom"
import PopupMenu from "../component/popupMenu";
import axios from "axios";
import {parseISO} from 'date-fns'
import {confirmDelete, formatDate} from "../utils";

export default function GuestRow(props) {
	let item = props.value;

	function handleDelete() {
		axios.delete(API_URL + '/reception/' + item.id)
			.then(function(response) {
				if (typeof props.refresh === 'function') {
					props.refresh();
				}
			});
	}

	return (
		<div className="boxRow">
			<div className="boxCell textBold">
				<a href="#" onClick={(event) => { event.preventDefault(); props.handleOpenModalView(item.id); }}>
					{item.fullName}
				</a>
			</div>
			<div className="boxCell">{item.user ? item.user.name : ''}</div>
			<div className="boxCell">{item.client ? item.client.name : item.clientName}</div>
			<div className="boxCell">{formatDate(parseISO(item.operationDate))}</div>
			<div className="boxCell textRight">
				<PopupMenu style={{right: '-12px'}}>
					<li onClick={() => props.handleOpenModalView(item.id)}>Podgląd</li>
					<li><Link to={"/guest/edit/" + item.id}>Edytuj</Link></li>
					<li onClick={() => confirmDelete(handleDelete)} className="red">Usuń</li>
				</PopupMenu>
			</div>
		</div>
	);
}