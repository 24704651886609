import React from 'react';
import {Form, Formik} from "formik";
import CheckBox from "../form/checkBox";

export default function TaskSettings(props) {

	let documentTypes = [
		{id: 1, name: 'Lp.'},
		{id: 2, name: 'Istotność'},
		{id: 3, name: 'Klient'},
		{id: 4, name: 'Zlecający'},
		{id: 5, name: 'Skrócony opis zadania'},
		{id: 6, name: 'Prognozowany czas realizacji'},
		{id: 7, name: 'Prognozowana data wykonania'},
		{id: 8, name: 'Data i godzina graniczna wykonania'},
		{id: 9, name: 'Status'},
	];

	function handleSubmit(values) {
		if (typeof props.onChange === 'function') {
			props.onChange(values);
		}
		if (typeof props.handleClose === 'function') {
			props.handleClose();
		}
	}

	return (
		<Formik
			initialValues={props.filters ? props.filters : {}}
			onSubmit={handleSubmit}
		>
			{(propsFormik) => {
				const {values, touched, errors, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, resetForm, setFieldValue, setFieldTouched} = propsFormik;

				function handleMultipleCheckboxChange(name, value) {
					let newValues = [];
					if (values.hasOwnProperty(name) && Array.isArray(values[name])) {
						newValues = [...values[name]];
					}
					if (newValues.indexOf(value) === -1) {
						newValues.push(value);
					} else {
						newValues.splice(newValues.indexOf(value), 1);
					}
					setFieldValue(name, newValues);
				}

				return (
					<Form>
						<div className="rcModelContents">
							<h3 className="top0 bottom24">Ustawienia rubryk</h3>

							{documentTypes && documentTypes.map(function(item) {
								return (
									<div className="displayInlineBlock right16 bottom20" key={"type" + item.name}>
										<CheckBox
											name="type"
											defaultChecked={true}
											value={item}
											checked={values.type && values.type.includes(item)}
											onChange={handleMultipleCheckboxChange}
											onBlur={setFieldTouched}
										>
											{item.name}
										</CheckBox>
									</div>
								);
							})}

							{/*<Dump value={values}/>*/}
							{/*<Dump value={propsFormik}/>*/}
						</div>

						<div className="rcModalActions">
							<button>Zapisz</button>
						</div>
					</Form>
				)
			}}
		</Formik>
	);
}
