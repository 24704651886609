import React from 'react';
import Field from "../../form/field";
import {ErrorMessage} from "formik";
import ColorPicker from "../../component/colorPicker";

export default function AdminDepartmentForm(props) {

	const {values, errors, handleChange, handleBlur, setFieldValue, setFieldTouched} = props;

	function renderDepartmentNames(deps = []) {
		let toReturn = '';

		deps.map(function(item) {
			toReturn = toReturn + item.name + ', ';
		});

		return toReturn.slice(0, -2);
	}

	return (
		<>
			<div className="formItem">
				<label htmlFor="name">Nazwa</label>
				<Field className="fullWidth" type="text" name="name"/>
				<ErrorMessage component="div" className="errorMessage" name="name"/>
			</div>

			<div className="formItem">
				<label htmlFor="companies">{values.companies && values.companies.length > 1 ? 'Przypisany do Spółek' : 'Przypisany do Spółki'}</label>
				<input
					type="text"
					name="companies"
					className="fullWidth"
					readOnly
					value={renderDepartmentNames(values.companies)}
				/>
				<ErrorMessage component="div" className="errorMessage" name="companies"/>
			</div>

			<div className="formItem">
				<label htmlFor="color">Kolor</label>
				<ColorPicker
					color={values.color}
					onChange={(color) => setFieldValue('color', color)}
				/>
				<ErrorMessage component="div" className="errorMessage" name="color"/>
			</div>
		</>
	)
}

export function validateDepartmentType(values) {
	let errors = {};
	if (!values.name) {
		errors.name = 'Nazwa jest wymagana';
	}
	if (!values.color) {
		errors.color = 'Kolor jest wymagany';
	}
	return errors;
}
