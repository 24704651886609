import {FETCH_DEPARTMENTS_BEGIN, FETCH_DEPARTMENTS_SUCCESS, FETCH_DEPARTMENTS_FAILURE} from './actionsDepartaments';

const reducerDepartments = (state = {list: [], loading: false}, action) => {

	switch (action.type) {
		case FETCH_DEPARTMENTS_BEGIN:
			return {
				...state,
				loading: true,
				error: null // also, reset any errors
			};

		case FETCH_DEPARTMENTS_SUCCESS:
			// replace the items with the ones from the server
			return {
				...state,
				loading: false,
				list: action.list,
			};

		case FETCH_DEPARTMENTS_FAILURE:
			// save the error
			return {
				...state,
				error: action.error,
				loading: false,
				list: []
			};

		default:
			return state
	}
};

export default reducerDepartments