import React from 'react'
import axios from 'axios'
import {Form, Formik, isEmptyArray} from "formik"
// import {useHistory} from "react-router-dom"
import ManagePeople from "../form/managePeople";
import {objectClone} from "../utils";
// import ClientForm, {ClientFormNewContact, validateClient} from "../client/clientForm";
import ClientForm, {ClientFormNewContact, ClientFormNewAgreement, validateClient} from "../client/clientForm";
import {formikErrorHelper} from "../form/formUtils";

import Dump from "../component/dump";
import { useEffect } from 'react';

export default function OfferClientAdd(props) {

    const [newClientId, setNewClientId] = React.useState(null);
    const [errorMessage, setErrorMessage] = React.useState("");
    const handleClick = () => {
        if (managePeople.current != null) {
            if (managePeople.current.state.toAdd == isEmptyArray()) {
                setErrorMessage("Klient musi posiadać opiekuna, dodaj go")
            } else {
                setErrorMessage("")
            }
        } else {
            setErrorMessage("")
        }
    }
    // const history = useHistory();
    let managePeople = React.createRef();
    useEffect(() => {
      // setProjectTypes(props.projectTypes);
      managePeople.current.setState({toAdd: [ props.seller ]})
    }, []);

    // function saveClientPersons(toSave, id) {
    //     let savePromisesArray = [];
    //     toSave.map((item) => {
    //       item.client = id;
    //       savePromisesArray.push(
    //         axios.post(API_URL + '/clientPerson/create', item)
    //       );
    //     });
    //     Promise.all(savePromisesArray).then(() => {
    //       props.handleAddNewClient(id);
    //     });
    // }

    const saveClientPersons = (toSave, id) => {
      const savePromisesArray = [];

      toSave.map((item) => {
          item.client = id;
          savePromisesArray.push(
              axios.post(API_URL + '/clientPerson/create', item)
          );
      });
      // Promise.all(savePromisesArray).then(() => history.goBack());
      return savePromisesArray;
    }

    const saveClientAgreements = (toSave, id) => {
      const savePromisesArray = [];

      toSave.map((item) => {
          item.client = id;
          uploadFileHelper(item.file, (fileId) => {
              const fdata = objectClone(item);
              fdata.file = fileId;
              savePromisesArray.push(
                  axios.post(API_URL + '/clientAgreement/create', fdata)
              );
          })
      });
      // Promise.all(savePromisesArray).then(() => history.goBack());
      return savePromisesArray;
    }

    const saveDepencies = (id, newPeople, newAgreements) => {
      const savePromisesArray = [];
      const personsPromises = saveClientPersons(newPeople, id);
      const agreementsPromises = saveClientAgreements(newAgreements, id);
      savePromisesArray.concat(personsPromises);
      savePromisesArray.concat(agreementsPromises);
      Promise.all(savePromisesArray).then(() => {
        props.handleAddNewClient(id);
      });
    }

    function handleSubmit(values, bag) {
      let toSave = objectClone(values);

      if (toSave.hasOwnProperty('province')) {
        toSave['province'] = parseInt(toSave['province']);
      }
      if (toSave.hasOwnProperty('fvProvince')) {
        toSave['fvProvince'] = parseInt(toSave['fvProvince']);
      }

      // let newPeople = toSave.newPeople;
      // delete toSave.newPeople;
      const newPeople = toSave.newPeople;
      const newAgreements = toSave.newAgreements;
      delete toSave.newPeople;
      delete toSave.newAgreements;

      if (managePeople.current != null) {
        if (managePeople.current.state.toAdd.length!=0){
          axios.post(API_URL + '/client/create', toSave)
            .then(function (response) {
              bag.setSubmitting(false);
              let clientId = response.data.id;
              if (clientId) {
                managePeople.current.save(clientId, () => {
                  // saveClientPersons(newPeople, clientId);
                  saveDepencies(clientId, newPeople, newAgreements);
                });
              }
            }).catch(function (error) {
              formikErrorHelper(error, bag);
            });
        }
      }
    }

    return (
      <Formik
          initialValues={{
              name: "",
              phone: "",
              email: "",
              city: "",
              street: "",
              postalCode: "",
              province: "",
              fvName: "",
              fvPhone: "",
              fvEmail: "",
              nip: "",
              fvCity: "",
              fvStreet: "",
              fvPostalCode: "",
              fvProvince: "",
              status: 2,
              projectTypes: props.projectTypes,
              newPeople: [],
              newPeopleInfo: [],
              color: '#FF0000',

              newAgreements: [],
              agreements: [],
          }}
          validate={validateClient}
          onSubmit={handleSubmit}
      >
          {(propsFormik) => {
              const {
                  values,
                  touched,
                  errors,
                  dirty,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  setFieldTouched,
                  status
              } = propsFormik;
              return (
                <>
                  <Form>
                      <div className="box">
                          <h1>Dodaj klienta</h1>
                          {errorMessage && (
                            <h1 className="error errorMessage" > {errorMessage} </h1>
                          )}
                      </div>

                      <div className="box">
                          <ClientForm {...propsFormik}/>
                      </div>

                      <div>
                          <h3 className="top0 left24 right24 bottom24 displayInlineBlock">Osoby kontaktowe</h3>
                          <ClientFormNewContact {...propsFormik} className="left24 right24" />
                      </div>

                      {status && <div className="errorMessage">{status}</div>}

                      <div className='left24 right24 bottom24'>
                        <ManagePeople ref={managePeople} />
                      </div>

                      <div>
                          <h3 className="top0 left24 right24 bottom24 displayInlineBlock">Zgody klienta</h3>
                          <ClientFormNewAgreement {...propsFormik} className="left24 right24" />
                      </div>

                      <div className='textRight right24 bottom24'>
                        <button type="submit" onClick={handleClick} className="left32 withChevron">
                            Dodaj&nbsp;<i className="fas fa-chevron-right"/>
                        </button>
                      </div>
                  </Form>
                  {/* <Dump value={props} /> */}
                  {/* <Dump value={values} /> */}
                </>
              )
          }}
      </Formik>
    );
}
