import React, {useContext, useState} from 'react';
import axios from "axios";
import PopupMenu from "./popupMenu";
import CommentEdit from "./commentEdit";
import {AccessContext} from "../access";
import {confirmDelete} from "../utils";

export default function CommentView(props) {
	const access = useContext(AccessContext);
	const [text, setText] = useState(props.text);
	const [mode, setMode] = useState("view");

	function handleDelete() {
		axios.delete(API_URL + '/comment/' + props.commentId)
			.then(function(response) {
				if (typeof props.refresh === 'function') {
					props.refresh();
				}
			});
	}

	function handleEdit() {
		setMode('edit');
	}

	function handleEditFinished(text) {
		setText(text);
		setMode('view');
	}

	function isEditable() {
		return access.admin || access.manager || access.id === props.posterId;
	}

	function renderView() {
		return (
			<div className="boxItem small">
				{!isEditable() ? '' :
					<PopupMenu>
						<li onClick={handleEdit}>Edytuj</li>
						<li onClick={() => confirmDelete(handleDelete)} className="red">Usuń</li>
					</PopupMenu>
				}
				{text}
			</div>
		);
	}

	function renderEdit() {
		return (<CommentEdit text={props.text} commentId={props.commentId} handleEditFinished={handleEditFinished}/>);
	}

	return (
		<div className="comment bottom16">
			<div className="bottom8">
				{props.poster ? <b>{props.poster}</b> : ''}
				{props.poster && props.date ? <>&nbsp;&nbsp;&nbsp;</> : ''}
				{props.date ? <i className="font12 fontLight">{props.date}</i> : ''}
			</div>

			{mode === 'view' ? renderView()	: '' }
			{mode === 'edit' ? renderEdit() : '' }

			{/*{<Dump value={props} />}*/}
			{/*<Dump value={mode}/>*/}
		</div>
	);
}
