import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory} from "react-router-dom";
import Menu from "./menu";
import Dump from "./component/dump";
import axios from "axios";
import {actionLogout} from "./state/actionsAuth";
import {actionFetchProfile} from "./state/actionsProfile";
import {actionFetchNotificationCount} from "./state/actionsNotifications";
import Avatar from "./component/avatar";
import Spinner from "./component/spinner";
import {Helmet} from 'react-helmet-async';

export default function Layout(props) {
	const history = useHistory();
	const dispatch = useDispatch();

	const me = useSelector(state => state.profile.me);
	const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
	const notificationCount = useSelector(state => state.notifications.count);

	useEffect(() => {
		if (props.type !== 'boxOnly') {
			dispatch(actionFetchProfile());
			dispatch(actionFetchNotificationCount());

		}
	}, [props.type]);

	function handleLogout() {
		axios.post(API_URL + '/auth/logout', [])
			.finally(function() {
				dispatch(actionLogout());
				history.push("/login");
			});
	}

	function renderBoxOnly() {
		return (
			<article id="container" className="centerVertically">
				<div className="contentWrapper">
					<div className="content">{props.children}</div>
				</div>
			</article>
		);
	}

	function renderLoader() {
		return (
			<article id="container">
				<div className="textCenter"><Spinner/></div>
			</article>
		);
	}

	function renderLayout() {
		return (
			<article id="container">
				<aside>
					<Menu/>
					<Dump value={API_URL} label=""/>
				</aside>

				<header className="top">
					<span className="right">
						{(!me.id) ? '' : <>
							<Link to="/notifications">Powiadomienia {notificationCount ? <span className="notificationCount">{notificationCount}</span> : ''}</Link>
							|
							<Link to={"/profile/view/" + me.id} className="left12">Twój profil</Link>
							<Link to={"/profile/view/" + me.id}><Avatar picture={me.picture} cssClass="left4"/></Link>
							|
							<span className="logout link" onClick={handleLogout}>Wyloguj się</span>
						</>}
					</span>
				</header>

				<main>{props.children}</main>
			</article>
		);
	}

	function render() {
		if (props.type === 'boxOnly') {
			return renderBoxOnly();
		}

		if (isAuthenticated == null || isAuthenticated === 'null' || isAuthenticated === false) {
			return renderLoader();
		}

		return renderLayout();
	}

	return (
		<>
			<Helmet>
				<title>{props.title ? props.title + ' - ' + APP_NAME : APP_NAME}</title>
			</Helmet>
			{render()}
		</>
	);
}
