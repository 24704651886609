import React, {useContext, useEffect, useState} from 'react'
import axios from 'axios'
import {Link} from "react-router-dom"
import TeamMember from "./teamMember"
import FilterDepartments from "../component/filterDepartments"
import Spinner from "../component/spinner";
import {AccessContext} from "../access";

export default function TeamMembers(props) {
	const access = useContext(AccessContext);

	const [search, setSearch] = useState("");
	const [people, setPeople] = useState(null);
	const [filterSelectedDepartment, setFilterSelectedDepartment] = useState(null);

	async function fetchPeople() {
		const response = await axios.get(API_URL + '/profile/list', {params: {search: search}});
		//console.log(response);
		if (Array.isArray(response.data.data.list)) {
			setPeople(response.data.data.list);
		}
	}

	useEffect(() => {
		fetchPeople();
	}, [search]);

	return (
		<div>
			<div className="headerWithButtons">
				<div className="left">
					<h1>Zespół</h1>
					{access.admin === false ? '' : (
						<Link to="/team/add" className="button left32 withChevron">
							Dodaj pracownika&nbsp;<i className="fas fa-plus"/>
						</Link>
					)}
				</div>

				<div className="right">
					<Link to="/team/calendar" className="button green pill right8">Kalendarz nieobecności</Link>
					<Link to="/team/structure" className="button grey pill right8">Struktura firmy</Link>
					<input type="search" className="pill" placeholder="Szukaj" value={search} onChange={event => setSearch(event.target.value)}/>
				</div>
			</div>

			<FilterDepartments onChange={value => setFilterSelectedDepartment(value)} />

			<div className="row with five columns">
				{people && people.filter(function(item) {
					if (filterSelectedDepartment == null) { //return all, no filter
						return item;
					}
					if (item.department && item.department.id == filterSelectedDepartment) {
						return item;
					}
				}).map(function(item) {
					//console.log(item);
					return (
						<div className="column" key={item.id}>
							<TeamMember link={"/team/view/" + item.id} person={item} showClients={true} refresh={fetchPeople}/>
						</div>
					);
				})}
			</div>

			{(people == null) ? <div className="textCenter"><Spinner /></div> : ''}

			{/*<Dump value={people}/>*/}
		</div>
	);
}
