import React, {useContext} from "react";
import {parseISO} from 'date-fns'
import {confirmDelete, formatDate, getFilenameFromPath, getFileUrl, nl2br} from "../utils";
import PillName from "../component/pillName";

const ClientAgreementRow = (props) => {
	let item = props.value;
	let filename = getFilenameFromPath(item.file.path);
	let fileUrl = getFileUrl(item.file.id, filename);
	filename = <>{filename}</>;

	const handleViewClick = (e) => {
		e.preventDefault();
		props.handleViewAgreement(item)
	};

	return (
		<>
			<div className="boxRow">
				<div className="boxCell textBold">
					<a href="#" onClick={handleViewClick}>
						{item.type.name}
					</a>
				</div>
				<div className="boxCell">
					<a href={fileUrl} target="_blank">
						{filename}
					</a>
				</div>
				<div className="boxCell">{formatDate(parseISO(item.modified))}</div>
			</div>
		</>
	);
}

export default ClientAgreementRow;