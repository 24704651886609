import axios from "axios";

export const FETCH_CALENDAR_TYPES_BEGIN = 'FETCH_CALENDAR_TYPES_BEGIN';
export const FETCH_CALENDAR_TYPES_SUCCESS = 'FETCH_CALENDAR_TYPES_SUCCESS';
export const FETCH_CALENDAR_TYPES_FAILURE = 'FETCH_CALENDAR_TYPES_FAILURE';

export function actionFetchCalendarTypesBegin() {
	return {
		type: FETCH_CALENDAR_TYPES_BEGIN
	};
}

export function actionFetchCalendarTypesSuccess(data) {
	return {
		type: FETCH_CALENDAR_TYPES_SUCCESS,
		list: data.filter(item => !item.isVacation),
		listVacation: data.filter(item => item.isVacation)
	};
}

export function actionFetchCalendarTypesFailure(error) {
	console.log('Error ', error);
	return {
		type: FETCH_CALENDAR_TYPES_FAILURE,
		error: error
	};
}

export function actionFetchCalendarTypes() {
	return dispatch => {
		dispatch(actionFetchCalendarTypesBegin());

		return axios.get(API_URL + '/calendar/types/list')
			.then(function(response) {
				if (Array.isArray(response.data.data.types)) {
					dispatch(actionFetchCalendarTypesSuccess(response.data.data.types));
				} else {
					dispatch(actionFetchCalendarTypesFailure());
				}
			}).catch(function(error) {
				dispatch(actionFetchCalendarTypesFailure(error));
			});
	};
}