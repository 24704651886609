import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {getNameFromId, objectClone} from "../utils";

import Spinner from "../component/spinner";
import Sortable from "../component/sortable";
import Pagination from 'rc-pagination';
import PaginationLocale from 'rc-pagination/lib/locale/pl_PL';
import ReactModal from "react-modal";

import DocumentAdd from "./documentAdd";
import DocumentEdit from "./documentEdit";
import DocumentRow from "./documentRow";
import FilterDocument from "./filterDocument";

import {connect, useDispatch} from "react-redux";
import {actionFetchClients} from "../state/actionsClients";
import {actionFetchPeople} from "../state/actionsPeople";

const mapStateToProps = function(state) {
	return {
		clients: state.clients.list,
		clientsLoading: state.clients.loading,
		people: state.people.list,
		peopleLoading: state.people.loading,
	};
};

function DocumentList(props) {
	const dispatch = useDispatch();

	useEffect(() => {
		if (props.clientsLoading === false && (props.clients == null || props.clients.length === 0)) {
			dispatch(actionFetchClients());
		}
	}, []);

	useEffect(() => {
		if (props.peopleLoading === false && (props.people == null || props.people.length === 0)) {
			dispatch(actionFetchPeople());
		}
	}, []);

	const perPage = 10;
	const [currentPage, setCurrentPage] = useState(1);
	const [documentsCount, setDocumentsCount] = useState(1);

	const [filters, setFilters] = useState({});
	const [search, setSearch] = useState("");
	const [documents, setDocuments] = useState(null);
	const [documentEditId, setDocumentEditId] = useState(null);

	const [orderColumn, setOrderColumn] = useState('modifiedDate');
	const [orderDirection, setOrderDirection] = useState(true);

	const [showModalFilters, setShowModalFilters] = useState(false);
	const [showModalAdd, setShowModalAdd] = useState(false);
	const [showModalEdit, setShowModalEdit] = useState(false);

	async function fetchDocuments() {
		let toSend = {
			page: currentPage,
			perPage: perPage
		};

		if (search) {
			toSend.search = search;
		}

		if (orderColumn && orderDirection !== null) {
			toSend.sort = orderColumn;
			if (orderDirection === true) {
				toSend.order = 'desc';
			}
			if (orderDirection === false) {
				toSend.order = 'asc';
			}
		}

		if (filters.hasOwnProperty('type') && filters.type.length > 0) {
			toSend['filter[type]'] = filters.type.map((item) => item.id);
		}
		if (filters.hasOwnProperty('client')) {
			toSend['filter[client]'] = filters.client;
		}
		if (filters.hasOwnProperty('user')) {
			toSend['filter[user]'] = filters.user;
		}

		const response = await axios.get(API_URL + '/document/list', {params: toSend});

		let data = response.data.data;
		if (Array.isArray(data.list)) {
			setDocuments(data.list);
			setDocumentsCount(data.count);
		}
	}

	useEffect(() => {
		fetchDocuments();
	}, [search, currentPage, perPage, orderDirection, orderColumn, filters]);

	function onPageChange(page) {
		setCurrentPage(page);
	}

	function handleSortChange(column, direction) {
		setOrderDirection(direction);
		setOrderColumn(column);
	}

	function handleCloseModalFilters() {
		setShowModalFilters(false);
	}

	function handleCloseModalAdd() {
		setShowModalAdd(false);
	}

	function handleCloseModalEdit() {
		setShowModalEdit(false);
	}

	function handleOpenModalEdit(id) {
		setDocumentEditId(id);
		setShowModalEdit(true);
	}

	function handleRefresh() {
		fetchDocuments();
	}

	function onFiltersChange(newFilters) {
		setFilters(newFilters);
	}

	return (
		<>
			<div className="headerWithButtons">
				<div className="left">
					<h1>Dokumenty</h1>
					<button className="left24 withChevron" onClick={() => setShowModalAdd(true)}>
						Dodaj&nbsp;<i className="fas fa-plus"/>
					</button>
					<button className="left24 grey withChevron" onClick={() => setShowModalFilters(true)}>
						Filtry&nbsp;<i className="fas"><span className="icon filter"/></i>
					</button>
				</div>

				<div className="right">
					<input type="search" className="pill" placeholder="Szukaj" value={search} onChange={event => setSearch(event.target.value) }/>
				</div>
			</div>

			<div className="filters">
				{filters.type && filters.type.map((item) => {
					return (
						<span key={"filterType" + item.id} className="filter">
							<i className="fas fa-times" onClick={() => {
								let newFilters = objectClone(filters);
								if (newFilters.type.indexOf(item) > -1) {
									newFilters.type.splice(newFilters.type.indexOf(item), 1);
									setFilters(newFilters);
								}
							}}/>
							{item.name}
						</span>
					);
				})}

				{filters.client ? (
					<span className="filter">
						<i className="fas fa-times" onClick={() => {
							let newFilters = objectClone(filters);
							delete newFilters.client;
							setFilters(newFilters);
						}}/>
						{getNameFromId(props.clients, filters.client)}
					</span>
				) : ''}

				{filters.user ? (
					<span className="filter">
						<i className="fas fa-times" onClick={() => {
							let newFilters = objectClone(filters);
							delete newFilters.user;
							setFilters(newFilters);
						}}/>
						{getNameFromId(props.people, filters.user)}
					</span>
				) : ''}
			</div>

			<div className="boxTable">
				<div className="boxHeader">
					<div className="boxCell"><Sortable onChange={handleSortChange} index="filename" value={(orderColumn === 'filename') ? orderDirection : null}>Nazwa pliku</Sortable></div>
					<div className="boxCell"><Sortable onChange={handleSortChange} index="type" value={(orderColumn === 'type') ? orderDirection : null}>Rodzaj dokumentu</Sortable></div>
					<div className="boxCell"><Sortable onChange={handleSortChange} index="client" value={(orderColumn === 'client') ? orderDirection : null}>Nazwa klienta</Sortable></div>
					<div className="boxCell"><Sortable onChange={handleSortChange} index="modifiedDate" value={(orderColumn === 'modifiedDate') ? orderDirection : null}>Data aktualizacji</Sortable></div>
					<div className="boxCell"><Sortable onChange={handleSortChange} index="modifiedBy" value={(orderColumn === 'modifiedBy') ? orderDirection : null}>Przez kogo</Sortable></div>
					<div className="boxCell"/>
				</div>

				{documents && documents.map(function(item, index) {
					return [
						<DocumentRow key={'document' + index} value={item} handleOpenModalEdit={handleOpenModalEdit} refresh={handleRefresh}/>,
						<div key={'spacer' + index} className="boxRow spacer"/>
					];
				})}
			</div>

			{(documents == null) ? <div className="textCenter"><Spinner/></div> : ''}

			<div className="textCenter">
				<Pagination
					onChange={onPageChange}
					pageSize={perPage}
					defaultCurrent={1}
					current={currentPage}
					total={documentsCount}
					//showTotal={(total) => `Total ${total} items`}
					prevIcon={<i className="fas fa-chevron-left"/>}
					nextIcon={<i className="fas fa-chevron-right"/>}
					locale={PaginationLocale}
				/>
			</div>

			{/*<Dump value={documents}/>*/}
			{/*<Dump label="column" value={orderColumn}/>*/}
			{/*<Dump label="direction" value={orderDirection}/>*/}
			{/*<Dump label="filters" value={filters}/>*/}

			<ReactModal
				isOpen={showModalFilters}
				contentLabel=""
				onRequestClose={handleCloseModalFilters}
				shouldCloseOnOverlayClick={true}
				className="rcModal withActions"
				overlayClassName="rcOverlay"
			>
				<span onClick={handleCloseModalFilters} className="rcModalClose"><i className="fas fa-times"/> Zamknij</span>

				<FilterDocument filters={filters} onChange={onFiltersChange} handleClose={handleCloseModalFilters}/>
			</ReactModal>

			<ReactModal
				isOpen={showModalAdd}
				contentLabel=""
				onRequestClose={handleCloseModalAdd}
				shouldCloseOnOverlayClick={true}
				className="rcModal withActions bigger"
				overlayClassName="rcOverlay"
			>
				<span onClick={handleCloseModalAdd} className="rcModalClose"><i className="fas fa-times"/> Zamknij</span>

				<DocumentAdd handleRefresh={handleRefresh} handleClose={handleCloseModalAdd}/>
			</ReactModal>

			<ReactModal
				isOpen={showModalEdit}
				contentLabel=""
				onRequestClose={handleCloseModalEdit}
				shouldCloseOnOverlayClick={true}
				className="rcModal withActions bigger"
				overlayClassName="rcOverlay"
			>
				<span onClick={handleCloseModalEdit} className="rcModalClose"><i className="fas fa-times"/> Zamknij</span>

				<DocumentEdit handleRefresh={handleRefresh} handleClose={handleCloseModalEdit} documentId={documentEditId}/>
			</ReactModal>
		</>
	);
}

export default connect(mapStateToProps)(DocumentList);
