import React, {useContext} from "react"
import PopupMenu from "../component/popupMenu";
import {parseISO} from 'date-fns'
import {confirmDelete, formatDate} from "../utils";
import axios from "axios";
import PillName from "../component/pillName";
import {AccessContext} from "../access";

export default function KnowledgeRow(props) {
	const access = useContext(AccessContext);
	let item = props.value;

	function handleDelete() {
		axios.delete(API_URL + '/knowledgeBase/' + item.id)
			.then(function(response) {
				if (typeof props.refresh === 'function') {
					props.refresh();
				}
			});
	}

	function isEditable(item) {
		return access.admin || access.manager;
	}

	return (
		<div className="boxRow">
			<div className="boxCell textBold">
				<a href="#" onClick={(event) => { event.preventDefault(); props.handleOpenModalView(item.id); }}>
					{item.name}
				</a>
			</div>
			<div className="boxCell">{(item.type) ? <span className="pill eggplant">{item.type.name}</span> : ''}</div>
			<div className="boxCell"><PillName value={item.department}/></div>
			<div className="boxCell">{formatDate(parseISO(item.modified))}</div>
			<div className="boxCell">{item.userModified ? item.userModified.name : ''}</div>

			<div className="boxCell textRight padRight8">
				<PopupMenu>
					<li onClick={() => props.handleOpenModalView(item.id)}>Zobacz</li>
					{!isEditable(item) ? '' : <li onClick={() => props.handleOpenModalEdit(item.id)}>Edytuj</li>}
					{!isEditable(item) ? '' : <li onClick={() => confirmDelete(handleDelete)} className="red">Usuń</li>}
				</PopupMenu>
			</div>
		</div>
	);
}