import React from "react";
import {nl2br} from "../utils";

export function arrayPeopleToNameString(people = [], separator = ', ', lastSeparator = false) {
	let toReturn = '';

	if (people) {
		people.map(function(person) {
			toReturn = toReturn + person.name + separator;
		});
	}

	if (lastSeparator === true) {
		return toReturn;
	}

	return toReturn.slice(0, -2);
}

export function renderPeopleWithOverflow(people = []) {
	let show = people.slice(0, 2);
	let overflow = people.slice(2);
	let toReturn = arrayPeopleToNameString(show);

	if (overflow.length > 0) {
		overflow = arrayPeopleToNameString(overflow, '\n', false);
		return <>{toReturn}, <span data-tip={nl2br(overflow)}>&hellip;</span></>;
	}

	return toReturn;
}