import axios from "axios";

export const FETCH_NOTIFICATION_COUNT_BEGIN = 'FETCH_NOTIFICATION_COUNT_BEGIN';
export const FETCH_NOTIFICATION_COUNT_SUCCESS = 'FETCH_NOTIFICATION_COUNT_SUCCESS';
export const FETCH_NOTIFICATION_COUNT_FAILURE = 'FETCH_NOTIFICATION_COUNT_FAILURE';

export function actionFetchNotificationCountBegin() {
	return {
		type: FETCH_NOTIFICATION_COUNT_BEGIN
	};
}

export function actionFetchNotificationCountSuccess(count) {
	return {
		type: FETCH_NOTIFICATION_COUNT_SUCCESS,
		count: count
	};
}

export function actionFetchNotificationCountFailure(error) {
	console.log('Error ', error);
	return {
		type: FETCH_NOTIFICATION_COUNT_FAILURE,
		error: error
	};
}

export function actionFetchNotificationCount() {
	return dispatch => {
		dispatch(actionFetchNotificationCountBegin());

		return axios.get(API_URL + '/notification/list', {params: {"filter[status]": 0}})
			.then(function(response) {
				dispatch(actionFetchNotificationCountSuccess(response.data.data.list.count));
			}).catch(function(error) {
				dispatch(actionFetchNotificationCountFailure(error));
			});
	};
}