import React from "react"
import {addDays, getOverlappingDaysInIntervals, isPast, isBefore, isSameDay, isSameMonth, endOfDay, startOfDay, parseISO, eachDayOfInterval, areIntervalsOverlapping} from 'date-fns';
import CalendarMonthlyCell from "./calendarMonthlyCell";

export default function CalendarMonthlyWeek(props) {

	const prefixCss = 'calendarMonthly';
	const startDate = props.startOfWeek;
	const endDate = props.endOfWeek;

	let currentMonth = props.currentMonth;
	if (!(currentMonth instanceof Date)) {
		currentMonth = new Date();
	}

	let data = (typeof props.value !== 'undefined') ? Array.from(new Set(props.value)) : [];

	data.sort((a, b) => {
	 	if (isBefore(parseISO(a.dateFrom), parseISO(b.dateTo))) {
	 		return -1;
	 	}
		if (isBefore(parseISO(b.dateFrom), parseISO(a.dateTo))) {
	 		return 1;
	 	}
	 	return 0;
	});

	//console.log(data);

	let data2 = [];

	data.map(function(item) {
		item.user = null;

		if (typeof item.type !== 'undefined') {
			let result = null;
			data2.forEach(function(items, index) {
				//console.log(isConflictingWithEntries(items, item) === false);

				if (isConflictingWithEntries(items, item) === false) {
					// console.log('no conflicts', index, result);
					// console.log(items);
					// console.log(item);
					if (result > index || result === null) {
						result = index;
					}
				} else {
					result = index + 1;
				}
			});

			if (result === null) {
				result = 0;
			}

			item.offset = result;

			if (Array.isArray(data2[result])) {
				data2[result].push(item);
			} else {
				data2[result] = [item];
			}
		}
	});

	data = [].concat.apply([], data2);

	let rows = 0;
	data.forEach(item => {
		if (item.offset + 1 > rows) {
			rows = item.offset + 1;
		}
	});

	function isConflictingWithEntry(item, newItem) {
		let interval1 = { start: startOfDay(parseISO(item.dateFrom)), end: endOfDay(parseISO(item.dateTo)) };
		let interval2 = { start: startOfDay(parseISO(newItem.dateFrom)), end: endOfDay(parseISO(newItem.dateTo)) };

		//console.log(areIntervalsOverlapping(interval1, interval2));

		return areIntervalsOverlapping(interval1, interval2);
	}

	function isConflictingWithEntries(items = [], newItem) {
		let toReturn = false;

		items.forEach(function(item) {
			if (isConflictingWithEntry(item, newItem) === true) {
				toReturn = true;
			}
		});

		return toReturn;
	}

	function renderCells() {
		let rowCells = [];

		let day = startDate;

		while (day <= endDate) {
			let displayDayContents = isSameMonth(day, startDate);
			if (isPast(endOfDay(day))) {
				displayDayContents = false;
			}

			let disabled = !displayDayContents;

			rowCells.push(
				<CalendarMonthlyCell
					value={null}
					day={day}
					key={day}
					selected={isSameDay(day, props.selectedDate)}
					disabled={disabled}
					displayDayContents={displayDayContents}
					onDateClick={props.onDateChange}
					prefixCss={prefixCss}
				/>
			);

			day = addDays(day, 1);
		}

		return rowCells;
	}

	function getEntrySize(start, end) {
		return getOverlappingDaysInIntervals(
			{start: startDate, end: endDate},
			{start: startOfDay(parseISO(start)), end: endOfDay(parseISO(end))}
		);
	}

	function getEntryOffset(itemDateTo) {
		itemDateTo = parseISO(itemDateTo);
		if (isBefore(startDate, itemDateTo)) {
			//console.log({start: startDate, end: itemDateTo});
			return eachDayOfInterval({start: startDate, end: itemDateTo}).length - 1;
		}

		return 0;
	}

	let height = (rows * 20) + 57;
	if (height < 77) {
		height = 77;
	}

	return (
		<>
		<div className={props.prefixCss + "-row"} style={{height: height}}>
			{data.map(function(item) {
				return (
					<div
						className={"calendarMonthlyEntry size" + getEntrySize(item.dateFrom, item.dateTo) + ' offset' + getEntryOffset(item.dateFrom)}
						key={'calEntry' + item.id }
						style={{backgroundColor: (item.type && item.type.color) ? item.type.color : '#666', top: (item.offset * 20) + 4}}
					>
						{item.type && item.type.name}
					</div>
				);
			})}
			{renderCells()}
		</div>
		</>
	);
}
