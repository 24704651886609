import React from 'react';
import PopupMenu from "./popupMenu";
import {confirmDelete, getFilenameFromPath, getFileUrl} from "../utils";

export default function FileView(props) {

	function renderDelete() {
		if (typeof props.handleDelete === 'function') {
			return (
				<PopupMenu>
					<li onClick={() => confirmDelete(props.handleDelete)} className="red">Usuń</li>
				</PopupMenu>
			);
		}

		return '';
	}

	let filename = getFilenameFromPath(props.path);
	let fileUrl = getFileUrl(props.fileId, filename);

	return (
		<div className="boxItem small">
			<a href={fileUrl} target="_blank">{filename}</a>
			{renderDelete()}
			{/*{<Dump value={props} />}*/}
		</div>
	);
}
