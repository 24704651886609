import React, {useEffect} from 'react';
import Select from "./select";
import {Option} from "rc-select";
import {connect, useDispatch} from "react-redux";
import {actionFetchIntervals} from "../state/actionsIntervals";

const mapStateToProps = function(state) {
	return {
		intervals: state.intervals.list,
		loading: state.intervals.loading,
	};
};

function SelectInterval(props) {
	const dispatcher = useDispatch();

	useEffect(() => {
		if (props.loading === false && (props.intervals == null || props.intervals.length === 0)) {
			dispatcher(actionFetchIntervals());
		}
	}, []);

	let {positions, dispatch, ...otherProps} = props;
	let data = props.intervals;

	return (
		<Select
			loading={props.loading}
			placeholder="Wybierz stanowisko"
			{...otherProps}
		>
			{data && data.map(function(item) {
				return (<Option value={parseInt(item.id)} key={'interval' + item.id}>{item.name}</Option>);
			})}
		</Select>
	);
}

export default connect(mapStateToProps)(SelectInterval);