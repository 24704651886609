import React from 'react';
import RcSelect from "rc-select";

export default function Select(props) {

	function handleChange(value = null) {
		//console.log(props.name, value);
		if (typeof props.onChange === 'function') {
			props.onChange(props.name, value);
		}
	}

	function handleBlur() {
		if (typeof props.onBlur === 'function') {
			props.onBlur(props.name);
		}
	}

	function getId() {
		if (props.id) {
			return props.id;
		}

		if (typeof props.id == 'undefined' && props.hasOwnProperty('name')) {
			return props.name;
		}
	}

	return (
		<RcSelect
			id={getId()}
			notFoundContent=""
			dropdownAlign={{offset: [0, 0]}}
			showSearch={false}
			filterOption={(input, option) => {
				function getStringFromChildren(item) {
					if (typeof item == 'string') {
						return item;
					}

					if (typeof item == 'object' && item.hasOwnProperty('props') && item.props.hasOwnProperty('children')) {
						if (Array.isArray(item.props.children)) {
							return item.props.children.filter(function(item) {
								return typeof item == 'string';
							}).join(' ');
						}
					}

					return '';
				}

				let haystack = getStringFromChildren(option.children);

				//search by option name
				return haystack.toLowerCase().indexOf(input.toLowerCase()) >= 0;
			}}
			{...props}
			onChange={handleChange}
			onBlur={handleBlur}
		>
			{props.children}
		</RcSelect>
	);
}
