import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Form, Formik} from "formik";
import {useParams, useHistory} from "react-router-dom";
import BackButton from "../component/backButton";
import {findIndexOfObjectWithPropertyValueInArray, isEmptyObject, objectClone} from "../utils";
import Spinner from "../component/spinner";
import {formikErrorHelper} from "../form/formUtils";
import moment from "moment";
import PackageForm, {validatePackage} from "./packageForm";
import {getClientAddressById} from "./receptionUtils";

export default function PackageEdit(props) {
	let {id} = useParams();
	const history = useHistory();

	const [pack, setPack] = useState({});
	const [uploadedFiles, setUploadedFiles] = useState([]);

	useEffect(() => {
		async function fetchGuest() {
			const response = await axios(API_URL + '/reception/' + id);
			//console.log(response);

			if (response.data.data.reception) {
				let foo = objectClone(response.data.data.reception);
				if (foo.hasOwnProperty('user') && foo.user.hasOwnProperty('id')) {
					foo.user = foo.user.id;
				}
				if (foo.hasOwnProperty('client') && foo.client.hasOwnProperty('id')) {
					foo.client = foo.client.id;
				}
				if (foo.hasOwnProperty('type') && foo.type.hasOwnProperty('id')) {
					foo.type = foo.type.id;
				}
				if (foo.hasOwnProperty('company') && foo.company.hasOwnProperty('id')) {
					foo.company = foo.company.id;
				}

				if (foo.client != null) {
					getClientAddressById(foo.client).then((result) => {
						foo.clientCity = result.city;
						foo.clientAddress = result.street;
						foo.clientPostal = result.postalCode;
						setPack(foo);
					});
				} else {
					setPack(foo);
				}
			}
		}

		fetchGuest();
	}, [id]);

	function handleSubmit(values, bag) {
		let toSave = {};
		Object.keys(values).map(function(item) {
			if (pack[item] !== values[item]) {
				toSave[item] = values[item];
			}
		});

		if (toSave.hasOwnProperty('client') && toSave.client != null) {
			toSave.client = parseInt(toSave.client);
			toSave.clientName = "";
			toSave.clientPostal = "";
			toSave.clientAddress = "";
			toSave.clientCity = "";
		}

		if (toSave.hasOwnProperty('clientName') && toSave.clientName != "") {
			if (toSave.hasOwnProperty('client')) {
				toSave.client = null;
			}
		}

		if (toSave.hasOwnProperty('user')) {
			toSave['user'] = parseInt(toSave['user']);
		}
		if (toSave.hasOwnProperty('type')) {
			toSave['type'] = parseInt(toSave['type']);
		}
		if (toSave.hasOwnProperty('company')) {
			toSave['company'] = parseInt(toSave['company']);
		}

		toSave.category = "parcel";

		let promises = [];
		promises.concat(addFiles(uploadedFiles, id));

		Promise.all(promises).then(() => {
			axios.patch(API_URL + '/reception/' + id, toSave)
				.then(function(response) {
					bag.setSubmitting(false);
					history.goBack();
				}).catch(function(error) {
					formikErrorHelper(error, bag);
				});
		});
	}

	function handleFileAdd(uploadedFile) {
		setUploadedFiles([...uploadedFiles, uploadedFile]);
	}

	function handleFileDelete(fileId, valueFiles, setFieldValue) {
		let index = findIndexOfObjectWithPropertyValueInArray(uploadedFiles, fileId);

		if (index !== null && index !== -1) {
			let newUploadedFiles = [...uploadedFiles];
			newUploadedFiles.splice(index, 1);
			setUploadedFiles(newUploadedFiles);
		} else {
			let index = findIndexOfObjectWithPropertyValueInArray(valueFiles, fileId);
			if (index !== null && index !== -1) {
				axios.delete(API_URL + '/reception/file/' + id + '/' + fileId)
					.then(function() {
						valueFiles.splice(index, 1);
						setFieldValue('files', valueFiles);
					});
			}
		}
	}

	function addFiles(uploadedFiles, id) {
		let promisesArray = [];

		if (uploadedFiles && Array.isArray(uploadedFiles) && uploadedFiles.length > 0) {
			uploadedFiles.map((item) => {
				promisesArray.push(
					axios.post(API_URL + '/reception/file/' + id + '/' + item.id)
				);
			});
		}

		return promisesArray;
	}

	return (isEmptyObject(pack) ? <div className="textCenter"><Spinner/></div> : renderFormik());

	function renderFormik() {
		let initial = objectClone(pack);
		delete initial.id;
		initial.operationDate = moment(initial.operationDate);

		if (initial.hasOwnProperty('client') === false) {
			initial.client = null;
		}

		if (initial.hasOwnProperty('clientName') === false) {
			initial.clientName = "";
		}
		if (initial.hasOwnProperty('clientCity') === false) {
			initial.clientCity = "";
		}
		if (initial.hasOwnProperty('clientPostal') === false) {
			initial.clientPostal = "";
		}
		if (initial.hasOwnProperty('clientAddress') === false) {
			initial.clientAddress = "";
		}

		return (
			<Formik
				initialValues={initial}
				validate={validatePackage}
				onSubmit={handleSubmit}
			>
				{(propsFormik) => {
					const {values, touched, errors, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched, status} = propsFormik;

					return (
						<Form>
							<div className="headerWithButtons">
								<h1>Edytuj klienta</h1>
								<div className="right">
									<BackButton>Anuluj</BackButton>
									<button type="submit" disabled={isSubmitting} className="left32 withChevron">
										Zapisz&nbsp;<i className="fas fa-chevron-right"/>
									</button>
								</div>
							</div>

							<div className="box">
								<PackageForm
									{...propsFormik}
									handleFileAdd={handleFileAdd}
									handleFileDelete={handleFileDelete}
									uploadedFiles={uploadedFiles}
								/>
							</div>

							{status && <div className="errorMessage">{status}</div>}

							{/*<Dump value={uploadedFiles} />*/}
							{/*<Dump value={values} />*/}
						</Form>
					)
				}}
			</Formik>
		);
	}
}
