import {confirmDelete, formatDate, getFilenameFromPath, getFileUrl, nl2br} from "../utils";

export default function ClientAgreementView(props) {

  const item = props.agreement;

  let filename = getFilenameFromPath(item.file.path);
	let fileUrl = getFileUrl(item.file.id, filename);
	filename = <>{filename}</>;

  return (
    <>
      <div className="rcModelContents">
        <h3 className="top0 bottom24">Zgoda klienta</h3>

        <div className="formItem">
          <label htmlFor="services">Typ zgody</label>
          <input className="fullWidth" type="text" value={item.type.name} readOnly="readonly" />
        </div>

        <div className="formItem">
          <label htmlFor="description">Treść zgody</label>
          <textarea className="fullWidth" readOnly="readonly" rows="4" value={item.type.description} />
        </div>

        <div className="formItem">
          <label htmlFor="services">Plik zgody</label>
          {/* <input className="fullWidth" type="text" value={item.type.name} readOnly="readonly" /> */}
          <div className="boxItem small" style={{borderRadius: '10px'}}>
            <a href={fileUrl} target="_blank">{filename}</a>
          </div>
        </div>

        <div className="rcModalActions">
          <button type="button" onClick={props.handleClose}>Zamknij</button>
        </div>
      </div>
    </>
  )
}