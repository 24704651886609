import React from "react";
import { HexColorPicker } from "react-colorful";
import "react-colorful/dist/index.css";

export default function ColorPicker(props) {
	return (
		<HexColorPicker
			{...props}
		/>
	);
}