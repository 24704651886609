import React from 'react';
import axios from 'axios';
import {ErrorMessage, Form, Formik} from "formik";
import Field from "../form/field";
import {objectClone} from "../utils";
import DocumentForm, {validateDocument} from "./documentForm";
import {formikErrorHelper, uploadFileHelper} from "../form/formUtils";
import {Helmet} from 'react-helmet-async';

export default function DocumentAdd(props) {

	function handleSubmit(values, bag) {
		let toSend = objectClone(values);

		uploadFileHelper(toSend.file, function(uploadedFileId) {
			toSend.file = uploadedFileId;

			axios.post(API_URL + '/document/create', toSend)
				.then(function(response) {
					bag.setSubmitting(false);
					//console.log(response);

					if (typeof props.handleRefresh === 'function') {
						props.handleRefresh();
					}
					if (typeof props.handleClose === 'function') {
						props.handleClose();
					}
				}).catch(function(error) {
					formikErrorHelper(error, bag);
				});
		});
	}

	return (
		<Formik
			initialValues={{
				description: "",
				type: null,
				file: null,
				client: null,
			}}
			validate={validateDocument}
			onSubmit={handleSubmit}
		>
			{(propsFormik) => {
				const {values, touched, errors, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched} = propsFormik;

				return (
					<>
						<Helmet>
							<title>{"Dodaj nowy dokument - " + APP_NAME}</title>
						</Helmet>
						<Form>
							<div className="rcModelContents">
								<h3 className="top0 bottom24">Dodaj nowy dokument</h3>

								<div className="row with four columns">
									<DocumentForm {...propsFormik}/>
								</div>

								<div className="formItem">
									<label htmlFor="description">Opis dokumentu</label>
									<Field className="fullWidth" component="textarea" name="description" rows="4"/>
									<ErrorMessage component="div" className="errorMessage" name="description"/>
								</div>

								<div className="formItem">
									<label>Załączony plik</label>
									<input type="text" value={values.file ? values.file.name : ""} readOnly className="right24"/>
									<input id="file" name="file" type="file" onChange={function(event) {
										setFieldValue("file", event.currentTarget.files[0]);
									}}/>
									<label htmlFor="file">Dodaj plik</label>
									<ErrorMessage component="div" className="errorMessage" name="file"/>
								</div>

								{status && <div className="errorMessage">{status}</div>}

								{/*<Dump label="formik" value={propsFormik}/>*/}

								<div className="rcModalActions">
									<button type="submit" className="left32" disabled={isSubmitting}>Zapisz</button>
								</div>
							</div>
						</Form>
					</>
				)
			}}
		</Formik>
	);
}
