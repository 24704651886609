import React from "react"
import {format, isSaturday, isSunday} from 'date-fns';

export default function CalendarDailyCell(props) {

	const prefixCss = props.prefixCss;
	const dateFormat = "d";

	let isWeekend = false;
	if (isSaturday(props.day) || isSunday(props.day)) {
		isWeekend = true;
	}

	return (
		<div
			className={`${prefixCss}-cell ${props.disabled ? (prefixCss + "-disabled") : ''} ${isWeekend ? 'weekend' : ''}`}
			key={format(props.day, 'yyyyMMdd')}
			onClick={function() { if (typeof props.onDateClick === 'function') { props.onDateClick(props.day); }}}
		>
			<span className={`${prefixCss}-number${(props.selected ? " selected" : "")}`}>{format(props.day, dateFormat)}</span>

			{props.value && props.value.length > 0 ? <div className={`${prefixCss}-marker`}/> : null}
		</div>
	);
}
