import React, {useEffect} from 'react';
import Select from "./select";
import {Option} from "rc-select";
import {useDispatch, useSelector} from "react-redux";
import {actionFetchTags} from "../state/actionsTags";
import {findObjectWithPropertyValueInArray} from "../utils";
import Spinner from "../component/spinner";

export default function SelectTag(props) {
	const dispatcher = useDispatch();

	const tags = useSelector(state => state.tags.list);
	const tagsLoading = useSelector(state => state.tags.loading);

	useEffect(() => {
		if (tagsLoading === false && (tags == null || tags.length === 0)) {
			dispatcher(actionFetchTags());
		}
	}, []);

	let {dispatch, ...otherProps} = props;

	function getTagById(id) {
		let found = findObjectWithPropertyValueInArray(tags, id);
		if (found && found.hasOwnProperty('name')) {
			return found.name;
		}
		return null;
	}

	function getTagByName(name) {
		let found = findObjectWithPropertyValueInArray(tags, name, 'name');
		if (found && found.hasOwnProperty('id')) {
			return found.id;
		}

		return name;
	}

	function getProperTags(values = []) {
		let toReturn = [];

		if (Array.isArray(values)) {
			values.map(function(item) {
				toReturn.push(Number.isInteger(parseInt(item)) ? getTagById(item) : item);
			});
		}

		return toReturn;
	}

	function handleChange(name, value = null) {
		if (typeof props.onChange === 'function') {
			let toReturn = [];

			if (Array.isArray(value)) {
				value.map(function(item) {
					toReturn.push(Number.isInteger(parseInt(item)) ? item : getTagByName(item));
				});
			}

			props.onChange(props.name, toReturn);
		}
	}

	function renderSelect() {
		return (
			<Select
				mode="tags"
				showSearch={true}
				filterOption={(input, option) => {
					//search by option name
					let value = option.props.hasOwnProperty('children') ? option.props.children : option.value;
					return value.toLowerCase().indexOf(input.toLowerCase()) > -1;
				}}
				{...otherProps}
				value={getProperTags(props.value)}
				onChange={handleChange}
			>
				{tags && tags.map(function(tag) {
					return <Option key={"tag" + tag.id} value={tag.name}>{tag.name}</Option>
				})}
			</Select>
		);
	}

	return (!tagsLoading) ? renderSelect() : <Spinner/>;
}
