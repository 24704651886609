import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Form, Formik} from "formik";
import {findIndexOfObjectWithPropertyValueInArray, isEmptyObject, objectClone} from "../utils";
import IssueForm, {validateIssue} from "./issueForm";
import Spinner from "../component/spinner";
import {formikErrorHelper} from "../form/formUtils";
import moment from "moment";

export default function IssueEdit(props) {

	const [issue, setIssue] = useState({});
	const [uploadedFiles, setUploadedFiles] = useState([]);

	useEffect(() => {
		async function fetchIssue() {
			const response = await axios(API_URL + '/issue/' + props.issueId);
			//console.log(response.data.data);
			if (response.data.data.user) {
				let foo = objectClone(response.data.data.user);

				if (foo.hasOwnProperty('users')) {
					let users = [];

					foo.users.map((item) => {
						if (item.hasOwnProperty('id')) {
							users.push(item.id);
						}
					});

					foo.users = users;
				}
				if (foo.hasOwnProperty('type')) {
					foo.type = foo.type.id
				}

				setIssue(foo);
			}
		}

		fetchIssue();
	}, [props.issueId]);

	function handleFileAdd(uploadedFile) {
		setUploadedFiles([...uploadedFiles, uploadedFile]);
	}

	function handleFileDelete(fileId, valueFiles, setFieldValue) {
		let index = findIndexOfObjectWithPropertyValueInArray(uploadedFiles, fileId);

		if (index !== null && index !== -1) {
			let newUploadedFiles = [...uploadedFiles];
			newUploadedFiles.splice(index, 1);
			setUploadedFiles(newUploadedFiles);
		} else {
			let index = findIndexOfObjectWithPropertyValueInArray(valueFiles, fileId);
			if (index !== null && index !== -1) {
				axios.delete(API_URL + '/issue/file/' + issue.id + '/' + fileId)
					.then(function() {
						valueFiles.splice(index, 1);
						setFieldValue('files', valueFiles);
					});
			}
		}
	}

	function addFiles(uploadedFiles, issueId) {
		let promisesArray = [];

		if (uploadedFiles && Array.isArray(uploadedFiles) && uploadedFiles.length > 0) {
			uploadedFiles.map((item) => {
				promisesArray.push(
					axios.post(API_URL + '/issue/file/' + issueId + '/' + item.id)
				);
			});
		}

		return promisesArray;
	}

	function handleSubmit(values, bag) {
		let toSend = {};
		Object.keys(values).map(function(item) {
			if (issue[item] !== values[item]) {
				toSend[item] = values[item];
			}
		});

		delete toSend.userModified;
		delete toSend.files;

		if (toSend.hasOwnProperty('client')) {
			toSend['client'] = parseInt(toSend['client']);
		}
		if (toSend.hasOwnProperty('user')) {
			toSend['user'] = parseInt(toSend['user']);
		}

		let promises = [];
		promises.concat(addFiles(uploadedFiles, props.issueId));

		Promise.all(promises).then(() => {
			axios.patch(API_URL + '/issue/' + props.issueId, toSend)
				.then(function(response) {
					bag.setSubmitting(false);
					//console.log(response);
					if (typeof props.handleRefresh === 'function') {
						props.handleRefresh();
					}
					if (typeof props.handleClose === 'function') {
						props.handleClose();
					}
				}).catch(function(error) {
					formikErrorHelper(error, bag);
				});
		});
	}

	return (isEmptyObject(issue) ? <div className="textCenter top96"><Spinner/></div> : renderFormik());

	function renderFormik() {
		let initial = objectClone(issue);
		delete initial.id;
		delete initial.created;
		delete initial.modified;
		delete initial.userModified;

		if (!initial.hasOwnProperty('users')) {
			initial.users = null;
		}

		if (initial.hasOwnProperty('client') && initial.client.hasOwnProperty('id')) {
			initial.client = initial.client.id;
		} else {
			initial.client = null;
		}

		if (initial.hasOwnProperty('userModified') && initial.userModified.hasOwnProperty('name')) {
			initial.userModified = initial.userModified.name;
		}

		initial.dateRealization = initial.dateRealization? moment(initial.dateRealization) : null;

		return (
			<Formik
				initialValues={initial}
				validate={validateIssue}
				onSubmit={handleSubmit}
			>
				{(propsFormik) => {
					const {values, touched, errors, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched} = propsFormik;

					return (
						<Form>
							<div className="rcModelContents">
								<h3 className="top0 bottom24">Edycja zgłoszenia</h3>

								<IssueForm
									{...propsFormik}
									issue={issue}
									showFieldsModified={true}
									handleFileAdd={handleFileAdd}
									handleFileDelete={handleFileDelete}
									uploadedFiles={uploadedFiles}
								/>

								<div className="rcModalActions">
									<button type="submit" className="left32" disabled={isSubmitting}>Zapisz</button>
								</div>
							</div>
						</Form>
					)
				}}
			</Formik>
		);
	}
}
