import React from 'react';
import classNames from 'classnames';
import {getFilenameFromPath, getFileUrl} from "../utils";

export default function Avatar(props) {
	let size = props.size ? props.size : 'medium';
	let cssClass = classNames('avatar', size, props.cssClass);
	let avatar = <div className={cssClass + ' empty'}/>;

	if (props.picture && props.picture.hasOwnProperty('id') && props.picture.hasOwnProperty('path')) {
		let filename = getFilenameFromPath(props.picture.path);
		let fileUrl = getFileUrl(props.picture.id, filename);
		avatar = <div className={cssClass}><img src={fileUrl} alt=""/></div>;
	}

	return avatar;
}
