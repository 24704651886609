import React from 'react';
import {Field as FormikField} from "formik";

export default function Field(props) {

	function getId() {
		if (props.id) {
			return props.id;
		}

		if (typeof props.id == 'undefined' && props.hasOwnProperty('name')) {
			return props.name;
		}
	}

	return (
		<FormikField
			id={getId()}
			{...props}
		/>
	);
}
